import React from 'react';

import CloseIcon from '@mui/icons-material/MenuOpenSharp';
import NotificationIcon from '@mui/icons-material/NotificationsOutlined';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import OrdersIcon from '@mui/icons-material/ShoppingCart';
import TestsIcon from '@mui/icons-material/Biotech';
import ShippingsIcon from '@mui/icons-material/LocalShipping';
import KitsIcon from '@mui/icons-material/MedicalServices';
import CustomersIcon from '@mui/icons-material/People';
import PatientsIcon from '@mui/icons-material/AssignmentInd';
import ProfileIcon from '@mui/icons-material/SettingsApplications';
import AdminIcon from '@mui/icons-material/ViewComfy';
import ExitIcon from '@mui/icons-material/ExitToAppSharp';
import BackIcon from '@mui/icons-material/ArrowBackIosSharp';
import ArrowDownIcon from '@mui/icons-material/Menu';
import { Img } from 'react-image';
import AddIcon from '@mui/icons-material/Add';
import CopyIcon from '@mui/icons-material/FileCopy';
import DropdownIcon from '@mui/icons-material/ArrowDropDown';
import PrintIcon from '@mui/icons-material/Print';
import TodoIcon from '@mui/icons-material/CheckCircleOutline';
import TodoCompletedIcon from '@mui/icons-material/CheckCircleRounded';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import DeliveryIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ReturnIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import InfoIcon from '@mui/icons-material/Info';
import HelpIcon from '@mui/icons-material/HelpCenterOutlined';

const LogoIcon = <Img src='/icon.png' />;

export {
  CloseIcon,
  NotificationIcon,
  DashboardIcon,
  OrdersIcon,
  TestsIcon,
  ShippingsIcon,
  KitsIcon,
  CustomersIcon,
  PatientsIcon,
  ProfileIcon,
  AdminIcon,
  ExitIcon,
  BackIcon,
  ArrowDownIcon,
  LogoIcon,
  AddIcon,
  CopyIcon,
  DropdownIcon,
  PrintIcon,
  TodoCompletedIcon,
  TodoIcon,
  DownloadIcon,
  EditIcon,
  DeliveryIcon,
  ReturnIcon,
  InfoIcon,
  HelpIcon,
};
