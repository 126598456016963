import React, { useState, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from 'notistack';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { AdminSvc } from '@/Services';

import {
  Formik,
  Form,
  useField,
  Field,
  ErrorMessage,
  validateYupSchema,
} from 'formik';
import * as Yup from 'yup';
import {
  RightDrawer,
  DrawerForm,
  FormButton,
  FormTextField,
  InputField,
  DrawerCardContent,
  DrawerCardActions,
  FormMenuItem,
} from '@/Theme';

const Edit = ({ openDrawer, setOpenDrawer, test, success }) => {
  console.log('### Selected item for edit: ', test);
  const [selectedItem, setSelectedItem] = useState(test);
  const { enqueueSnackbar } = useSnackbar();

  const handleSuccess = (savedTestType) => {
    setOpenDrawer(false);
    success(savedTestType);
  };
  const handleCancel = () => {
    setOpenDrawer(false);
  };
  const initValues = () => {
    return {
      id: selectedItem.id,
      name: selectedItem.name || '',
      // description: selectedItem.description || '',
      // displayName: selectedItem.displayName || '',
      // displayDescription: selectedItem.displayDescription || '',
      externalId: selectedItem.externalId || '',
      // category: selectedItem.category || '',
      duration: selectedItem.duration || 0,
      // resultReady: selectedItem.resultReady || 0,
      // resultType: selectedItem.resultType || '',
      // referenceRange: selectedItem.referenceRange || '',
      // referenceRangeFrom: selectedItem.referenceRangeFrom || 0,
      // referenceRangeTo: selectedItem.referenceRangeTo || 0,
      active: selectedItem.active,
    };
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    // description: Yup.string(),
    // displayName: Yup.string(),
    // displayDescription: Yup.string(),
    // duration: Yup.number(),
    // resultReady: Yup.number(),
    // resultRangeFrom: Yup.number(),
    // resultRangeTo: Yup.number(),
    externalId: Yup.string().required('Lab Test ID is required'),
    active: Yup.boolean(),
  });
  const form = () => {
    return (
      <Formik
        initialValues={initValues()}
        onSubmit={async (values, { setSubmitting }) => {
          validationSchema
            .validate(values)
            .then(async (valid) => {
              if (valid) {
                let isActive = values.active === null ? false : values.active;
                let savedTest = await AdminSvc.saveTest({
                  id: selectedItem.id,
                  name: values.name,
                  // duration: values.duration,
                  active: isActive,
                  // resultReady: values.resultReady,
                  // resultRangeFrom: values.resultRangeFrom,
                  // resultRangeTo: values.resultRangeTo,
                  externalId: values.externalId,
                });

                if (savedTest) {
                  enqueueSnackbar('Test saved.', { variant: 'success' });
                  setSelectedItem(savedTest);
                  handleSuccess(savedTest);
                }
              }
            })
            .catch((err) => {
              console.log(err.errors);
              enqueueSnackbar(err.errors, { variant: 'error' });
            });
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
          } = props;
          const handleChecked = (value) => {
            setFieldValue('active', value);
          };
          return (
            <DrawerForm onSubmit={handleSubmit} autoComplete='nope'>
              <DrawerCardContent>
                <InputField field='name' label='Name' required props={props} />
                {/* <InputField
                  field='description'
                  label='Description'
                  multiline
                  rows={2}
                  props={props}
                /> */}
                {/* <InputField
                  field='displayName'
                  label='Patient Test Name'
                  props={props}
                />
                <InputField
                  field='displayDescription'
                  label='Patient Test Description'
                  multiline
                  rows={2}
                  props={props}
                /> */}
                {/* <InputField
                  field='duration'
                  label='Duration (minutes)'
                  props={props}
                />
                <InputField
                  field='resultReady'
                  label='Result Ready Time (minutes)'
                  props={props}
                />
                <InputField
                  field='resultType'
                  label='Result Type (NM, ...)'
                  props={props}
                />
                <InputField
                  field='referenceRange'
                  label='Reference Range'
                  props={props}
                />
                <InputField
                  field='referenceRangeFrom'
                  label='Reference Range From'
                  props={props}
                />
                <InputField
                  field='referenceRangeFrom'
                  label='Reference Range To'
                  props={props}
                /> */}
                <InputField
                  field='externalId'
                  label='Lab Test ID'
                  props={props}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name='active'
                      value={values.active}
                      checked={values.active}
                      onChange={() => handleChecked(!values.active)}
                    />
                  }
                  label='Active'
                />
              </DrawerCardContent>
              <DrawerCardActions>
                <FormButton primary type='submit'>
                  Save
                </FormButton>
                <FormButton onClick={handleCancel}>Cancel</FormButton>
              </DrawerCardActions>
            </DrawerForm>
          );
        }}
      </Formik>
    );
  };
  return (
    <div>
      <RightDrawer
        title={selectedItem.id ? 'Edit Test' : 'Add New Test'}
        handleClose={handleCancel}
        open={openDrawer}
      >
        {form()}
      </RightDrawer>
    </div>
  );
};
export default Edit;
