import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
  RightDrawer,
  DrawerForm,
  FormButton,
  InputField,
  DrawerCardContent,
  DrawerCardActions,
  DrawerCard,
  DataList,
  DataListItem,
} from '@/Theme';

const NotificationDetail = ({ openDrawer, setOpenDrawer, selectedNotification, success }) => {

  const navigate = useNavigate();
  const handleClose = () => {
    setOpenDrawer(false);
  };
  const handleCopy = (content) => {
    if (content) {
      navigator.clipboard.writeText(JSON.stringify(content));
    }
  }
  const content = () => {
    return (
      <>
        <DrawerCardContent>
          <DataList>
            <DataListItem label="Type" value={selectedNotification.type} />
            <DataListItem label="Sent On" value={
              `${DateTime.fromISO(selectedNotification.sentOn).toLocaleString(DateTime.DATETIME_SHORT)}`} />
            <DataListItem label="To" value={selectedNotification.to} />
            <DataListItem label="Subject" value={selectedNotification.subject} />
            <DataListItem label="Message" value={selectedNotification.message} />
            <DataListItem label="Entity Type" value={selectedNotification.entityType} />
            <DataListItem label="Entity ID" value={selectedNotification.entityId} />
            <DataListItem label="Status" value={selectedNotification.status} />
            <DataListItem label="Sent?" value={selectedNotification.sent ? "Yes" : "No"} />
            <DataListItem label="Viewed On" value={
              `${selectedNotification.viewedOn && DateTime.fromISO(selectedNotification.viewedOn).toLocaleString(DateTime.DATETIME_SHORT)}`} />
            <DataListItem label="Archived On" value={
              `${selectedNotification.archivedOn && DateTime.fromISO(selectedNotification.archivedOn).toLocaleString(DateTime.DATETIME_SHORT)}`} />
            <DataListItem label="Detail" contentprops={{ secondaryTypographyProps: { variant: "string" } }} onClick={() => { handleCopy(selectedNotification.body) }} value={
              <pre style={{ maxWidth: "300px", maxHeight: "100px", overflow: "auto" }}>
                {JSON.stringify(selectedNotification.detail, null, 2)}
              </pre>
            } />
            <DataListItem label="Log" contentprops={{ secondaryTypographyProps: { variant: "string" } }} onClick={() => { handleCopy(selectedNotification.body) }} value={
              <pre style={{ maxWidth: "300px", maxHeight: "100px", overflow: "auto" }}>
                {JSON.stringify(selectedNotification.log, null, 2)}
              </pre>
            } />
          </DataList>
        </DrawerCardContent>
        <DrawerCardActions>
          <FormButton onClick={handleClose}>Cancel</FormButton>
        </DrawerCardActions>
      </>
    );
  };
  return (
    <div>
      <RightDrawer
        title='Notification Detail'
        handleClose={handleClose}
        open={openDrawer}
      >
        {content()}
      </RightDrawer>
    </div>
  );
};
export default NotificationDetail;
