/** PACKAGE IMPORTS */
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { DataTableStyles, EmptyData, LoadingData } from '@/Theme';
//  import { Avatar, IconButton } from '@mui/material';
//import EditIcon from '@mui/icons-material/Edit';
import { DateTime } from 'luxon';
//let size = 50;
const List = ({
  listData,
  loading,
  handleEdit,
  count,
  size,
  setSize,
  page,
  setPage,
  setSort,
  setDirection,
}) => {
  // users = [];
  // let size = 50;
  const navigate = useNavigate();
  const handleRowClick = (customer) => {
    //alert('handle row click patient')
    let link = `/patient/${customer.id}`;
    //alert(EditIcon);
    navigate(link);
  };




  const columns = useMemo(
    () => [
      {
        name: 'First Name',
        selector: row => row.firstName,
        sortable: true,
      },
      {
        name: 'Last Name',
        selector: row => row.lastName,
        sortable: true,
      },
      {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
        grow: 1,
      },
      {
        name: 'Phone',
        selector: row => row.phone,
        sortable: true,
        grow: 1,
      },
      {
        name: 'DOB',
        selector: row => row.dob,
        sortable: true,
        grow: 1,
      },
      {
        name: 'Gender',
        selector: row => row.gender,
        sortable: true,
        grow: 1,
      },
      {
        name: 'Organization',
        selector: row => row.organizationId,
        sortable: true,
        grow: 1,
        cell: (row) => (row.organization && row.organization.name)
      },
      {
        name: 'Last Update',
        sortable: true,
        sortField: "updated",
        cell: (row) => {
          return `${DateTime.fromISO(row.updated).toLocaleString(
            DateTime.DATETIME_SHORT
          )}`;
        },
      }
    ],
    []
  );
  const handlePageChange = (p) => {
    console.log('### handlePageChange', page, '--', p);
    setPage(p);
  };
  const handlePerRowsChange = (perPage, p) => {
    //let perPage = 50;
    console.log('### handleRowsChange', perPage, p);
    setSize(perPage);
    setPage(p);
  };
  const handleSort = (sort, direction) => {
    console.log('### handleSort', sort, direction);
    setSort(sort.selector);
    setDirection(direction);

  };

  return (
    <>
      {loading && listData.length === 0 && <LoadingData />}
      {listData.length > 0 && (
        <DataTable
          className='pagination-custom'
          columns={columns}
          data={listData}
          noHeader
          onRowClicked={handleRowClick}
          highlightOnHover
          pointerOnHover
          customStyles={DataTableStyles}
          paginationDefaultPage={page}
          persistTableHead
          paginationPerPage={size}
          pagination
          paginationServer
          paginationTotalRows={count}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          defaultSortField='name'
          defaultSortAsc={false}
          onSort={handleSort}
          sortServer
          paginationRowsPerPageOptions={[5, 10, 15, 25, 50, 100, 200, 500]}

        />
      )}
      {!loading && !listData.length && <EmptyData />}
    </>
  );
};
export default List;
