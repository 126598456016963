/** PACKAGE IMPORTS */
import React, { useMemo } from 'react';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { DataTableStyles, EmptyData, LoadingData } from '@/Theme'
import { AccountAvatar } from './Style';

const UsersTable = ({
  users,
  loading,
  handleViewRequest,
  count,
  size,
  setSize,
  page,
  setPage,
  setSort,
  setDirection
}) => {
  // users = [];
  const columns = useMemo(
    () => [
      // {
      //   name: '',
      //   selector: row => row.first_name,
      //   sortable: false,
      //   cell: row=>{
      //     return <AccountAvatar 
      //     alt={row.first_name} 
      //     src={row.avatar || row.first_name}
      //     aria-label="Account"
      //     aria-controls="menu-appbar"
      //     aria-haspopup="true"
      //     color="inherit" />
      //   }
      // },
      {
        name: 'Name',
        selector: row => row.first_name,
        sortable: true,
        grow: 2,
        cell: row=>{
          return (row.first_name || '') + " " + (row.last_name || '')
        }
      },
      {
        name: 'Role',
        selector: row => row.role,
        sortable: true,
        grow: 2
      },
      {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
        grow: 2
      },
      {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
      },
      {
        name: 'Updated On',
        selector: row => row.updated,
        sortable: true,
        cell: row => {
          return row.created && moment(row.created).format('lll');
        }
      },
      {
        name: 'Joined On',
        selector: row => row.created,
        sortable: true,
        cell: row => {
          return row.created && moment(row.created).format('lll');
        }
      }
    ],
    []
  );
  const handlePageChange = p => {
    console.log('### handlePageChange', page, '--', p);
    setPage(p);
  };
  const handlePerRowsChange = (perPage, p) => {
    console.log('### handleRowsChange', perPage, p);
    setSize(perPage);
    setPage(p);
  };
  const handleSort = (sort, direction) => {
    console.log('### handleSort', sort, direction);
    setSort(sort.selector);
    setDirection(direction);
  };
  return (
    <>
      {loading && <LoadingData/>}
      {!loading && users.length > 0 && (
        <DataTable
          className='pagination-custom'
          columns={columns}
          data={users}
          noHeader
          onRowClicked={handleViewRequest}
          highlightOnHover
          customStyles={DataTableStyles}
          paginationDefaultPage={page}
          persistTableHead
          paginationPerPage={size}
          pagination
          paginationServer
          paginationTotalRows={count}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          defaultSortField='name'
          defaultSortAsc={false}
          onSort={handleSort}
          sortServer
        />
      )}
      {!loading && !users.length && (<EmptyData/>)}
    </>
  );
};
export default UsersTable;
