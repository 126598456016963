/** PACKAGE IMPORTS */
import React, { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { DataTableStyles, EmptyData, LoadingData } from '@/Theme';
import { Tooltip, IconButton } from '@mui/material';
import { ViewLabelButton, TrackingContainer, CopyContainer } from './Style';
import { DateTime } from 'luxon';
import { CopyIcon, PrintIcon, DownloadIcon } from "@/Theme/Icons"

const List = ({
  listData,
  setListData,
  loading,
  handleCopy,
  count,
  size,
  setSize,
  page,
  setPage,
  setSort,
  setDirection,
}) => {
  const columns = useMemo(
    () => [
      {
        name: 'Tracking Number',
        sort: 'trackingNumber',
        selector: row => row.trackingNumber,
        sortable: true,
        grow: 2,
        cell: (row) => {
          return (
            <>
              {(row.trackingNumber && (
                <a href={row.trackingUrl} target="_blank" rel="noreferrer">
                  {row.trackingNumber}
                </a>
              )) || ("N/A")
              }
            </>
          );
        },
      },
      {
        name: 'Name',
        sort: 'deliveryAddress.name',
        selector: row => row.deliveryAddress.name,
        sortable: true,
        grow: 1,
        cell: (row) => {
          return (
            <>
              {row.deliveryAddress && row.deliveryAddress.name}
            </>
          );
        },
      },
      {
        name: 'Organization',
        sort: 'organization',
        selector: row => row.organization && row.organization.name,
        sortable: false,
        grow: 1,
      },
      {
        name: 'Carrier',
        sort: 'carrier',
        selector: row => row.carrier,
        sortable: true,
        grow: 1,
      },
      {
        name: 'Rate',
        sort: 'rate',
        selector: row => row.rate,
        sortable: true,
        grow: 1,
      },
      {
        name: 'Service',
        sort: 'service',
        selector: row => row.service,
        sortable: true,
        grow: 1,
        cell: (row) => {
          return row.service === 'PRIORITY_OVERNIGHT'
            ? 'Priority Overnight'
            : row.service;
        },
      },
      {
        name: 'Shipment Status',
        sort: 'status',
        selector: row => row.status,
        sortable: true,
        grow: 1,
      },
      {
        name: 'Last Update',
        sort: 'updated',
        sortable: true,
        grow: 1,
        selector: row => row.updated,
        cell: (row) => {
          return `${DateTime.fromISO(row.updated).toLocaleString(
            DateTime.DATETIME_SHORT
          )}`;
        },
      },
      // {
      //   name: 'Shipping Label',
      //   sortable: false,
      //   grow: 1,
      //   cell: (row) => {
      //     return (
      //       <>
      //         <Tooltip title="Print Label">
      //           <IconButton onClick={() => handleViewLabel(row)}>
      //             <PrintIcon />
      //           </IconButton>
      //         </Tooltip>
      //         <Tooltip title="Download Label">
      //           <IconButton onClick={() => handleDownload(row)}>
      //             <DownloadIcon />
      //           </IconButton>
      //         </Tooltip>
      //       </>
      //     );
      //   },
      // },
    ],
    []
  );
  const handlePageChange = (p) => {
    console.log('### handlePageChange', page, '--', p);
    setPage(p);
  };
  const handlePerRowsChange = (perPage, p) => {
    console.log('### handleRowsChange', perPage, p);
    setSize(perPage);
    setPage(p);
  };
  const handleSort = (row, direction) => {
    console.log('### handleSort', row.sort, direction);
    setSort(row.sort);
    setDirection(direction);
  };
  const handleViewLabel = (row) => {
    const { labelUrl } = row;
    window.open(labelUrl, '_blank');
  };
  const handleDownload = (row) => {
    let link = document.createElement('a');
    link.download = row.trackingNumber;
    link.href = row.labelUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link = undefined;
  };
  return (
    <>
      {loading && listData.length === 0 && <LoadingData />}
      {listData.length > 0 && (
        <DataTable
          className='pagination-custom'
          columns={columns}
          data={listData}
          noHeader
          highlightOnHover
          customStyles={DataTableStyles}
          paginationDefaultPage={page}
          persistTableHead
          paginationPerPage={size}
          pagination
          paginationServer
          paginationTotalRows={count}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          defaultSortField='name'
          defaultSortAsc={false}
          onSort={handleSort}
          sortServer
          paginationRowsPerPageOptions={[5, 10, 15, 25, 50, 100, 200, 500]}
        />
      )}
      {!loading && !listData.length && <EmptyData entity='shippings' />}
    </>
  );
};
export default List;
