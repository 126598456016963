import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import PanelIcon from '@mui/icons-material/Bookmarks';
import {
  SecondaryPage,
  DataTableStyles,
  DataList,
  DataListItem,
  ContentCardContent,
  ContentCardHeader,
  ContentCardShadowed,
  FormButton,
} from '@/Theme';
import { Grid, Avatar, IconButton } from '@mui/material';
/** Local imports */
import Edit from './Edit';
import Panels from './Panels';
import { AdminSvc } from '@/Services';

const View = () => {
  const { id } = useParams();
  const [selectedItem, setSelectedItem] = useState({});
  const [panels, setPanels] = useState({});
  const [action, setAction] = useState(null);
  const [loading, setLoading] = useState(false);
  //for editing
  const [openDrawer, setOpenDrawer] = useState(false);

  const fetch = async () => {
    setLoading(true);
    let response = await AdminSvc.getKit(id);
    console.log('##### response', response);
    if (response && response.data) {
      setSelectedItem(response.data.kit);
      setPanels(response.data.panels);
      console.log('#### tests', response.data.panels);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetch();
  }, [id]);

  useEffect(() => {
    fetch();
  }, [id]);
  const handleEdit = () => {
    setAction('edit');
    setOpenDrawer(true);
  };
  const handleAdd = () => {
    setAction('test');
    setOpenDrawer(true);
  };
  const handleRowClick = (test) => {
    setAction('test');
    setOpenDrawer(true);
  };
  const refresh = () => {
    fetch();
  };
  const contentDrawer = () => {
    let drawerContent = '';
    if (openDrawer && action) {
      if (action === 'edit') {
        drawerContent = (
          <Edit
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            testType={selectedItem}
            success={refresh}
          />
        );
      } else if (action === 'test') {
        drawerContent = (
          <Panels
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            panel={selectedItem}
            success={refresh}
          />
        );
      }
    }
    return drawerContent;
  };

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
        grow: 3,
        cell: (row) => {
          return (
            <>
              <PanelIcon fontSize='large' /> {row.panel.name}
            </>
          );
        },
      },
      {
        name: 'Organization',
        selector: row => row.organization.name,
        sortable: false,
        grow: 2,
        cell: (row) => {
          return <>{row.panel.organization ? row.organization.name : 'N/A'}</>;
        },
      },
      {
        name: 'Lab Panel ID',
        selector: row => row.panel.externalId,
        sortable: true,
        grow: 1,
      },
      {
        name: 'Active',
        selector: row => row.panel.active,
        sortable: true,
        width: '75px',
        cell: (row) => {
          return row.panel.active ? 'Yes' : 'No';
        },
      },
    ],
    []
  );

  return (
    <SecondaryPage
      icon={<PanelIcon fontSize='large' />}
      title={selectedItem.name}
      back='/admin/kit'
    >
      <ContentCardShadowed>
        <ContentCardHeader
          title='Lab Kit Spec'
          action={
            <FormButton primary compact onClick={handleEdit}>
              Edit
            </FormButton>
          }
        />
        <ContentCardContent>
          <Grid container>
            <Grid item xs={6}>
              <DataList>
                <DataListItem
                  label='ID'
                  value={
                    selectedItem.id
                  }
                />
                <DataListItem
                  label='SKU'
                  value={
                    selectedItem.code
                  }
                />
                <DataListItem label='Name' value={selectedItem.name} />
                <DataListItem
                  label='Description'
                  value={selectedItem.description}
                />
                <DataListItem
                  label='Display Name'
                  value={selectedItem.displayName}
                />
                <DataListItem
                  label='Display Description'
                  value={selectedItem.displayDescription}
                />
              </DataList>
            </Grid>
            <Grid item xs={6}>
              <DataList>
                <DataListItem
                  label='Organization'
                  value={
                    selectedItem.organization && selectedItem.organization.name
                  }
                />
                <DataListItem
                  label='Parcel Id'
                  value={selectedItem.epParcelId}
                />
                <DataListItem label='Weight (oz)' value={selectedItem.weight} />
                <DataListItem
                  label='Active'
                  value={selectedItem.active ? 'Yes' : 'No'}
                />
                <DataListItem
                  label='External Id'
                  value={selectedItem.externalId}
                />
              </DataList>
            </Grid>
          </Grid>
        </ContentCardContent>
      </ContentCardShadowed>
      <ContentCardShadowed group>
        <ContentCardHeader
          title='Panels'
          action={
            <FormButton primary compact onClick={handleAdd}>
              Add Panels
            </FormButton>
          }
        />
        <ContentCardContent>
          <DataTable
            className='pagination-custom'
            columns={columns}
            data={panels}
            noHeader
            onRowClicked={handleRowClick}
            highlightOnHover
            customStyles={DataTableStyles}
            persistTableHead
            defaultSortField='name'
            defaultSortAsc={false}
          />
        </ContentCardContent>
      </ContentCardShadowed>
      {contentDrawer()}
    </SecondaryPage>
  );
};
export default View;
