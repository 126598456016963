import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import List from './List';
import View from './View';
import { useSnackbar } from 'notistack';
import { MenuItem } from '@mui/material';
import {
  FilterBox,
  PageHeader,
  PageAction,
  PrimaryButton,
  FormButton,
  SelectFilterField,
  SecondaryPage,
} from '@/Theme';
import { AdminSvc } from '@/Services';

const Home = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [filter, setFilter] = useState({ search: "", status: ["New", "Failed"] });
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState(['New', 'Failed']);
  const [type, setType] = useState([]);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('created');
  const [direction, setDirection] = useState('desc');
  //For editing
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const filters = {
    where: {},
    size: size,
    page: page,
    sort: sort,
    order: direction,
  };
  const fetch = async () => {
    setLoading(true);
    filters.where = filter;
    let response = await AdminSvc.searchTasks(filters);
    if (response && response.data && response.data.objects) {
      const objects = response.data.objects;
      setListData(objects);
      setCount(response.data.count);
    }
    setLoading(false);
  };
  useEffect(() => {
    console.log('### fetch test types', search, size, page, sort, direction);
    fetch();
  }, [filter, size, page, sort, direction]);

  const onSearchChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };
  const onSearchPressed = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let updatedFilter = {
        ...filter,
        ...{ search: search },
        ...{ status: status },
        ...{ type: type },
      }
      setFilter(updatedFilter);
    }
  };
  const handleApplyFilter = () => {
    let updatedFilter = {
      ...filter,
      ...{ search: search },
      ...{ status: status },
      ...{ type: type },
    }
    setFilter(updatedFilter);
  }
  const onStatusSelect = (event) => {
    const { target: { value } } = event;
    setStatus(typeof value === 'string' ? value.split(',') : value,);
  }
  const onTypeSelect = (event) => {
    const { target: { value } } = event;
    setType(typeof value === 'string' ? value.split(',') : value,);
  }
  const handleEditSuccess = (test) => {
    let link = `/admin/test/${test.id}`;
    navigate(link);
  };
  const handleView = (task) => {
    setSelectedItem(task);
    setOpenDrawer(true);
  };
  const contentDrawer = () => {
    let drawerContent = '';
    if (openDrawer) {
      drawerContent = (
        <View
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          task={selectedItem}
        />
      );
    }
    return drawerContent;
  };
  const handlePushToLab = async () => {
    let response = await AdminSvc.pushToLab();
    if (response) {
      console.log("###### push to lab", response.data);
      enqueueSnackbar('Push to lab completed.', { variant: 'success' });
      fetch();
    }
  }
  const handlePullFromLab = async () => {
    let response = await AdminSvc.pullFromLab();
    if (response) {
      console.log("###### pull from lab", response.data);
      enqueueSnackbar('Pull results from lab completed.', { variant: 'success' });
      fetch();
    }
  }
  const handlePushResults = async () => {
    let response = await AdminSvc.pushResults();
    if (response) {
      console.log("###### push result complete", response.data);
      enqueueSnackbar('Push result completed.', { variant: 'success' });
      fetch();
    }
  }
  const handlePullOrders = async () => {
    let response = await AdminSvc.pullOrders();
    if (response) {
      console.log("###### pull order complete", response.data);
      enqueueSnackbar('Pull orders completed.', { variant: 'success' });
      fetch();
    }
  }
  const handleUpdateKitStatus = async () => {
    let response = await AdminSvc.updateKitStatus();
    if (response) {
      console.log("###### Update kit status completed", response.data);
      enqueueSnackbar('Update kit status completed.', { variant: 'success' });
      fetch();
    }
  }
  return (
    <SecondaryPage title='Lab Order Tasks' back='/admin/dashboard' actions={
      <>
        <PrimaryButton primary="true" compact="true" size='small' onClick={handlePushResults}>
          Push Results
        </PrimaryButton>
        <PrimaryButton  primary="true" compact="true" size='small' onClick={handlePullOrders}>
          Pull Orders
        </PrimaryButton>
        <PrimaryButton  primary="true" compact="true" size='small' onClick={handleUpdateKitStatus}>
          Update Kit Status
        </PrimaryButton>
        <PrimaryButton  primary="true" compact="true" size='small' onClick={handlePushToLab}>
          Push to Lab
        </PrimaryButton>
        <PrimaryButton  primary="true" compact="true" size='small' onClick={handlePullFromLab}>
          Pull From Lab
        </PrimaryButton>
      </>
    }>
      <PageHeader>
        <PageAction>
          <FilterBox
            onChange={onSearchChange} onPress={onSearchPressed}
            extend={
              <>
                <SelectFilterField
                  labelId="filter-status-label"
                  id="filter-status-id"
                  value={status}
                  multiple
                  onChange={onStatusSelect}
                >
                  <MenuItem value="New">New</MenuItem>
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Completed">Completed</MenuItem>
                  <MenuItem value="Failed">Failed</MenuItem>
                  <MenuItem value="Ignore">Ignored</MenuItem>
                </SelectFilterField>
                <SelectFilterField
                  labelId="filter-type-label"
                  id="filter-type-id"
                  value={type}
                  multiple
                  onChange={onTypeSelect}
                >
                  <MenuItem value="Push To Lab">Push To Lab</MenuItem>
                  <MenuItem value="Kit Received">Kit Received</MenuItem>
                  <MenuItem value="Result Update">Result Update</MenuItem>
                </SelectFilterField>
                <FormButton compact primary onClick={handleApplyFilter}>Filter</FormButton>
              </>
            }
          />
        </PageAction>
      </PageHeader>
      <List
        listData={listData}
        setOpenDrawer={setOpenDrawer}
        loading={loading}
        handleView={handleView}
        count={count}
        size={size}
        setSize={setSize}
        page={page}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
        direction={direction}
        setDirection={setDirection}
        refreshData={fetch}
      />
      {contentDrawer()}
    </SecondaryPage>
  );
};
export default Home;
