import BaseSvc from './base-service';
import { useSelector, shallowEqual } from "react-redux";
import { SET_USER, SET_ROLE, SET_ACL } from '@/Redux/action/types';
import store from '@/Redux/store';

class UserService {
	constructor() {
		this.authConfig = {};
		if (!UserService.instance) {
			this.authConfig = {};
			UserService.instance = this;
		}
		return UserService.instance;
	}

	async retriveMe() {
		let response = await BaseSvc.securePost("/me");
		console.log("#### UserSvc#me", response)
		if (response && response.data) {
			store.dispatch({
				type: SET_USER,
				user: response.data
			})
		}
	}

	async retriveProfile() {
		let response = await BaseSvc.securePost("/me");
		console.log("#### UserSvc#me", response)
		return response;
	}

	async search(filter) {
		console.log("#### UserSvc#search")
		let response = await BaseSvc.secureGet("/admin/user", filter);
		return response;
	}

	async userEnums() {
		let response = await BaseSvc.secureGet("/auth/enums");
		return response.data;
	}

	async initSystem() {
		let response = await BaseSvc.secureGet("/auth/enums");
		if (response && response.data) {
			store.dispatch({
				type: SET_ROLE,
				role: response.data.ROLE
			})
			store.dispatch({
				type: SET_ACL,
				acl: response.data.ACL
			})
		}
	}

	async roles() {
		let response = await BaseSvc.secureGet("/auth/enums");
		return response.data.ROLES;
	}

	async saveUser(data) {
		console.log("#### UserSvc#saveUser", data)
		let response = await BaseSvc.securePost("/admin/user", data)
		return response.data;
	}

	role(){
		let role = null;
		try {
			role = useSelector((state) => state.role, shallowEqual);
		} catch (e) {
			console.error("### ERROR WHILE TRYING TO GET ROLE FROM REDUX", e);
		}
		return role;
	}

	acl(){
		let acl = null;
		try {
			acl = useSelector((state) => state.acl, shallowEqual);
		} catch (e) {
			console.error("### ERROR WHILE TRYING TO GET ACL FROM REDUX", e);
		}
		return acl;
	}

	me() {
		let user = null;
		try {
			user = useSelector((state) => state.user, shallowEqual);
		} catch (e) {
			console.error("### ERROR WHILE TRYING TO GET USER FROM REDUX", e);
		}
		return user;
	}

}

const instance = new UserService();
Object.freeze(instance);
export default instance;
