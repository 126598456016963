import React, { useState, useEffect } from 'react';

import {
  RightDrawer,
  DrawerCardContent,
  DataList,
  DataListItem,
} from '@/Theme';
import { DateTime } from 'luxon';

const View = ({ openDrawer, setOpenDrawer, task }) => {
  console.log('### Selected item for view: ', task);
  const handleCancel = () => {
    setOpenDrawer(false);
  };
  return (
    <div>
      <RightDrawer
        title="Task"
        handleClose={handleCancel}
        open={openDrawer}
      >
        <DrawerCardContent>
          <DataList>
            <DataListItem label='ID' value={task.id}/>
            <DataListItem label='Task Status' value={task.status}/>
            <DataListItem label='Task Type' value={task.type}/>
            <DataListItem label='Ran on' value={DateTime.fromISO(task.updated).toLocaleString(DateTime.DATETIME_SHORT)}/>
            <DataListItem label='Elasped(ms)' value={task.elasped}/>
            <DataListItem label='Order Code' value={task.labOrder.labOrderCode}/>
            <DataListItem label='Registration Code' value={task.labOrder.kitNumber}/>
            <DataListItem label='Order Status' value={task.labOrder.status}/>
            <DataListItem label='Log' value={task.log}/>
          </DataList>
        </DrawerCardContent>
      </RightDrawer>
    </div>
  );
};
export default View;
