/** PACKAGE IMPORTS */
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import {
  DataTableStyles, EmptyData, LoadingData,
  DisplayList
} from '@/Theme';
import { DateTime } from 'luxon';
import { ListItem, ListItemText, IconButton, Tooltip } from '@mui/material';
import { PrintIcon, TodoIcon, TodoCompletedIcon, InfoIcon, DownloadIcon, DeliveryIcon, ReturnIcon } from '@/Theme/Icons'
import { OrderService } from '@/Services';

const List = ({
  listData,
  loading,
  count,
  size,
  setSize,
  page,
  setPage,
  setSort,
  setDirection,
  handleUpdateStatus,
}) => {
  const navigate = useNavigate();
  const handleRowClick = (order) => {
    let link = `/kit/order/${order.id}`;
    navigate(link, { replace: true });
  };

  const columns = useMemo(
    () => [
      {
        name: 'Customer',
        sort: 'customer',
        sortable: true,
        grow: 3,
        cell: (row) => {
          return (
            <>
              {row.customer &&
                (<DisplayList key={row.id + "-customer"} sx={{ width: "100%" }} labelWidth={8} contentWidth={4} padding='0'>
                  <ListItem key={row.id + row.customer.id + "-profile"}  onClick={() => handleRowClick(row)}>
                    <ListItemText
                      secondary={row.customer.firstName + ' ' + row.customer.lastName}
                    />
                  </ListItem>
                  <ListItem key={row.id + row.customer.id + "-address"}   onClick={() => handleRowClick(row)}>
                    <ListItemText
                      primary={
                        row.shippingAddress.street1 + ' ' +
                        (row.shippingAddress.street2 ? row.shippingAddress.street2 : "") + ", " +
                        row.shippingAddress.state + " " + row.shippingAddress.postalCode
                      }
                    />
                  </ListItem>
                </DisplayList>
                )}
            </>
          );
        },
      },
      {
        name: 'Organization',
        sort: 'organization',
        sortable: true,
        grow: 3,
        cell: (row) => {
          return row.organization.name;
        },
      },
      {
        name: 'Line Items',
        sort: 'lineItems',
        sortable: true,
        grow: 3,
        cell: (row) => {
          return (
            <>
              {row.lineItems &&
                (<DisplayList key={row.id + "-lineItems"} sx={{ width: "100%" }} labelWidth={9} contentWidth={3} padding='0'>
                  {row.lineItems.map((lineItem) => {
                    return (
                      <div key={row.id + lineItem.id + "-detail"}>
                        <ListItem key={row.id + lineItem.id + "-info"}   onClick={() => handleRowClick(row)}>
                          <ListItemText
                            primary={
                              lineItem.kit.name
                            }
                            secondary={
                              "x " + lineItem.quantity
                            }
                          />
                        </ListItem>
                        <ListItem key={row.id + lineItem.id + "-kit"}   onClick={() => handleRowClick(row)}>
                          <ListItemText
                            primary={
                              row.organization.code + "-" + lineItem.kit.code + "-xxxxx"
                            }
                            secondary=" "
                          />
                        </ListItem>
                      </div>
                    )
                  })}
                </DisplayList>
                )}
            </>
          );
        },
      },
      {
        name: 'Status',
        sort: 'status',
        selector: row => row.status,
        sortable: true,
      },
      {
        name: 'Last Update',
        sort: 'updated',
        sortable: true,
        selector: row => row.updated,
        cell: (row) => {
          return `${DateTime.fromISO(row.updated).toLocaleString(
            DateTime.DATETIME_SHORT
          )}`;
        },
      },
      // {
      //   name: '',
      //   width: "40px",
      //   bottom: true,
      //   cell: (row) => {
      //     return (<>
      //       <Tooltip title="Print Labels">
      //         <IconButton onClick={() => OrderService.handlePrintLabel(row)}>
      //           <PrintIcon />
      //         </IconButton>
      //       </Tooltip>
      //     </>)
      //   },
      // },
      {
        name: '',
        bottom: true,
        width: "50px",
        cell: (row) => {
          return (<>
            <Tooltip title="Mark as Completed">
              <IconButton onClick={() => handleUpdateStatus(row)}>
                {row.status === 'Completed' ? <TodoCompletedIcon /> : <TodoIcon />}
              </IconButton>
            </Tooltip>
          </>)
        },
      },
    ],
    []
  );
  const handlePageChange = (p) => {
    console.log('### handlePageChange', page, '--', p);
    setPage(p);
  };
  const handlePerRowsChange = (perPage, p) => {
    console.log('### handleRowsChange', perPage, p);
    setSize(perPage);
    setPage(p);
  };
  const handleSort = (column, direction) => {
    console.log('### handleSort', column.sort, direction);
    setSort(column.sort);
    setDirection(direction);
  };
  return (
    <>
      {loading && listData.length === 0 && <LoadingData />}
      {listData.length > 0 && <DataTable
        className='pagination-custom'
        columns={columns}
        data={listData}
        noHeader
        pointerOnHover
        highlightOnHover
        onRowClicked={handleRowClick}
        customStyles={DataTableStyles}
        paginationDefaultPage={page}
        persistTableHead
        paginationPerPage={size}
        paginationRowsPerPageOptions={[10, 15, 25, 50, 100, 200, 500]}
        pagination
        paginationServer
        paginationTotalRows={count}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        defaultSortField='created'
        defaultSortAsc={false}
        onSort={handleSort}
        sortServer
      />}
      {!loading && !listData.length && <EmptyData entity='orders' />}
    </>
  );
};
export default List;
