import configs from '@/config';
import axios from 'axios';

const API_URL = configs.api.REST_URI;
const DEBUG = configs.debug || true;

class BaseService {
  constructor() {
    this.authConfig = {};
    this.token = '';
    if (!BaseService.instance) {
      this.authConfig = {};
      BaseService.instance = this;
    }
    return BaseService.instance;
  }

  config(token) {
    DEBUG && console.log('### BaseSvc#config', token);
    this.token = token;
  }

  async get(path, params, headers = {}) {
    DEBUG && console.log('### Making get call to: ', path, params, headers);
    let response = await axios.get(`${API_URL}${path}`, params, { headers });
    DEBUG && console.log(' ### Completed get call to ', path, ' with rsponse ', response);
    return response;
  }

  async secureGet(path, params, headers = {},) {
    console.log('### Making secure get call to: ', path, params, headers);
    headers = { ...headers, ...this.getAuthHeader() };
    let response = {};
    try {
      response = await axios.get(`${API_URL}${path}`, { params, headers });
      DEBUG &&
        console.log('### Completed secure get call ', path, ' with rsponse ', response);
    } catch (error) {
      response = this.processError(error);
    }
    return response;
  }

  async post(path, data, headers = {}) {
    let response = await axios.post(`${API_URL}${path}`, data, { headers });
    return response;
  }

  async securePost(path, data, headers = {}) {
    DEBUG &&
      console.log('### Making secure post call to: ', path, data, headers);
    headers = { ...headers, ...this.getAuthHeader() };
    let response = {};
    try {
      response = await axios.post(`${API_URL}${path}`, data, { headers });
      DEBUG &&
        console.log('### Completed secure post call to ', path, ' with rsponse ', response);
    } catch (error) {
      response = this.processError(error);
    }
    return response;
  }

  async securePut(path, data, headers = {}) {
    DEBUG &&
      console.log('### Making secure put call to: ', path, data, headers);
    headers = { ...headers, ...this.getAuthHeader() };
    let response = {};
    try {
      response = await axios.put(`${API_URL}${path}`, data, { headers });
      DEBUG &&
        console.log('### Completed secure put call to ', path, ' with rsponse ', response);
    } catch (error) {
      response = this.processError(error);
    }
    return response;
  }

  async secureDelete(path, headers = {}) {
    headers = { ...headers, ...this.getAuthHeader() };
    DEBUG &&
      console.log('### Making secure delete call to: ', path, headers);
    let response = {};
    try {
      response = await axios.delete(`${API_URL}${path}`, { headers });
      DEBUG &&
        console.log('### Completed secure put call to ', path, ' with rsponse ', response);
    } catch (error) {
      response = this.processError(error);
    }
    return response;
  }

  getAuthHeader() {
    let authHeader = { Authorization: 'Bearer ' + this.token };
    return authHeader;
  }

  processError(error) {
    console.log("#### error occurred while making a secure get request", error);
    let response = { data: {} };
    if (error && error.response && error.response.status === 401) {
      if (window.location.pathname !== '/login') {
        window.location = "/login";
      }
    }
    response = error.response?.data;
    console.log("#### display error", response)
    return response;
  }
}

const instance = new BaseService();
export default instance;
