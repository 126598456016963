import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CardHeader from '@mui/material/CardHeader';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from 'notistack';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { AdminSvc } from '@/Services';

import {
  Formik,
  Form,
  useField,
  Field,
  ErrorMessage,
  validateYupSchema,
} from 'formik';
import * as Yup from 'yup';
import {
  RightDrawer,
  FormButton,
  FormTextField,
  InputField,
  DrawerCardContent,
  DrawerCardActions,
  FormMenuItem,
} from '@/Theme';

const Edit = ({
  openDrawer,
  setOpenDrawer,
  selectedItem,
  refreshList,
  inputVariables,
}) => {
  console.log('### Selected item for edit: ', selectedItem);
  const { enqueueSnackbar } = useSnackbar();

  // const sessionRole = Session.getRole();
  const navigate = useNavigate();
  const handleClose = () => {
    setOpenDrawer(false);
    refreshList();
  };
  const initValues = () => {
    return {
      id: selectedItem.id,
      name: selectedItem.name || '',
      width: selectedItem.width,
      height: selectedItem.height,
      length: selectedItem.length,
      weight: selectedItem.weight,
      isPanel: selectedItem.isPanel,
      active: selectedItem.active,
    };
  };
  const form = () => {
    return (
      <Formik
        initialValues={initValues()}
        validationSchema={Yup.object().shape({
          name: Yup.string('Enter test type name').required('Name is required'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          console.log('##### save', values);
          let savedTestType = await AdminSvc.saveTest({
            id: selectedItem.id,
            name: values.name,
            width: values.width,
            duration: values.duration,
            height: values.height,
            length: values.length,
            weight: values.weight,
            active: values.active,
            isPanel: values.isPanel,
          });
          if (savedTestType) {
            enqueueSnackbar('Test type saved.', { variant: 'success' });
            handleClose();
          }
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
          } = props;
          const handleChecked = (value) => {
            setFieldValue('active', value);
          };
          const handlePanel = (value) => {
            setFieldValue('isPanel', value);
          };
          return (
            <Form onSubmit={handleSubmit} autoComplete='nope'>
              <DrawerCardContent>
                <InputField
                  field='firstName'
                  label='First Name'
                  props={props}
                />
                <InputField field='lastName' label='Last Name' props={props} />
              </DrawerCardContent>
              <DrawerCardActions>
                <FormButton primary type='submit'>
                  Submit
                </FormButton>
                <FormButton onClick={handleClose}>Cancel</FormButton>
              </DrawerCardActions>
            </Form>
          );
        }}
      </Formik>
    );
  };
  return (
    <div>
      <RightDrawer
        title={selectedItem.id ? 'Edit Patient' : 'Add New Patient'}
        handleClose={handleClose}
        open={openDrawer}
      >
        {form()}
      </RightDrawer>
    </div>
  );
};
export default Edit;
