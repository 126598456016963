import React from 'react';
import PanelIcon from '@mui/icons-material/Bookmarks';

export default [
  {
    name: 'Name',
    sort: 'name',
    selector: row => row.name,
    sortable: true,
    grow: 3,
    cell: (row) => {
      return (
        <>
          <PanelIcon fontSize='large' /> {row.name}
        </>
      );
    },
  },
  {
    name: 'Organization',
    sort: 'organization.name',
    selector: row => row.organization.name,
    sortable: true,
    grow: 2,
    cell: (row)=>{
      return (
        <>
          {row.organization? row.organization.name: "N/A"}
        </>
      )
    }
  },
  {
    name: 'Code',
    sort: 'code',
    selector: row => row.code,
    sortable: true,
    grow: 1,
  },
  {
    name: 'Wix',
    sort: 'wixId',
    selector: row => row.wixId,
    sortable: true,
    grow: 1,
  },
  // {
  //   name: 'Parcel ID',
  //   selector: 'epParcelId',
  //   sortable: true,
  //   grow: 2,
  // },
  {
    name: 'Weight (oz)',
    sort: 'weight',
    selector: row => row.weight,
    sortable: true,
    cell: (row) => {
      return row.weight;
    },
  },
  {
    name: 'Active',
    sort: 'active',
    selector: row => row.active,
    sortable: true,
    width: '100px',
    cell: (row) => {
      return row.active ? 'Yes' : 'No';
    },
  },
];