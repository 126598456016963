import React from 'react';
import { Toolbar } from '@mui/material';
import { Navigation, PageToolbar } from './Navigation';
import { MainContent } from './Style';
import styled from 'styled-components';

const PrimaryPage = ({ mainContentProps, children, ...props }) => {
  return (
    <div className='' style={{ height: '100%' }}>
      <Navigation {...props} />
      <Toolbar />
      <MainContent
        className='flex flex-col'
        maxWidth={false}
        {...mainContentProps}
        {...props}
      >
        {children}
      </MainContent>
    </div>
  );
};
/**
 * Primary use for secondary page that navigate off from the primary page.  It has back control.
 * @param {*} param0
 * @returns
 */
const SecondaryPage = ({ mainContentProps, children, ...props }) => {
  return (
    <div className='' style={{ height: '100%' }}>
      <PageToolbar {...props} />
      <Toolbar variant='dense' />
      <MainContent maxWidth={false} {...mainContentProps}>
        {children}
      </MainContent>
    </div>
  );
};
/**
 * Primary use for solo page that has no navigation.  eg. Login
 * @param {*} param0
 * @returns
 */
const DomainInfoSpan = styled.span`
 
 color: #2c2d77;
 font-size: 20px;
 padding-bottom: 20px;
`;

const DoaminInfo = () => {
  const domain = window.location.host;

  let doamin1ToCheck = 'localhost';
  let doamin2ToCheck = 'staging';
  let doaminInformation = '';

  if (domain.includes(doamin1ToCheck)) {
    doaminInformation = 'Localhost';
  } else if (domain.includes(doamin2ToCheck)) {
    doaminInformation = ' STAGING';
  } else {
    doaminInformation = '';
  }
  return (
    <DomainInfoSpan>

      <span className='domainInfo'>{doaminInformation}</span>
    </DomainInfoSpan>
  );
};


const FullPage = ({ mainContentProps, children, ...props }) => {
  return (
    <div className={props.className} style={{ height: '100%' }}>
      <MainContent maxWidth={false} {...mainContentProps} {...props}>
        <DoaminInfo />
        {children}
      </MainContent>
    </div>
  );
};

export { PrimaryPage, SecondaryPage, FullPage };
