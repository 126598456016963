import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminSvc } from '@/Services';
import { useSnackbar } from 'notistack';
import {
  Formik,
  Form,
  useField,
  Field,
  ErrorMessage,
  validateYupSchema
} from 'formik';
import * as Yup from 'yup';
import {
  RightDrawer,
  FormButton,
  InputField,
  DrawerCardContent,
  DrawerCardActions,
} from '@/Theme';
import useWindowDimension from '@/Utils/window';

const UserDrawer = ({
  openDrawer,
  setOpenDrawer,
  patient,
  success,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpenDrawer(false);
  };
  const initValues = () => {
    return {
      id: patient.id,
      firstName: patient.firstName || '',
      middleName: patient.middleName || '',
      lastName: patient.lastName || '',
      email: patient.email || '',
      dob: patient.dob || '',
      phone: patient.phone || '',
      gender: patient.gender || ''
    };
  };
  const form = () => {
    return (
      <Formik
        initialValues={initValues()}
        validationSchema={Yup.object().shape({
          email: Yup.string('Enter email').email('Email input be an email').required('Email is required'),
          firstName: Yup.string("Enter first name").required("First name is required"),
          lastName: Yup.string("Enter last name").required("Last name is required"),
          dob: Yup.string("Enter DOB").required("DOB is required")
        })}
        onSubmit={(values, { setSubmitting }) => {
          console.log("##### save patient", values);
          let saved = AdminSvc.savePatient({
            id: patient.id,
            firstName: values.firstName,
            lastName: values.lastName,
            middleName: values.middleName,
            email: values.email,
            dob: values.dob || "",
            phone: values.phone || "",
            gender: values.gender || ""
          });
          if (saved) {
            enqueueSnackbar("Patient saved.", { variant: "success" })
            success();
            handleClose();
          }
        }}

      >
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue
          } = props;
          return (
            <Form onSubmit={handleSubmit} autoComplete='nope' style={{ overflow: "auto" }}>
              <DrawerCardContent>
                <InputField
                  value={values.email}
                  field='email'
                  label='Email'
                  required
                  props={props}
                />
                <InputField
                  value={values.firstName}
                  field='firstName'
                  label='First name'
                  props={props}
                />
                <InputField
                  value={values.lastName}
                  field='lastName'
                  label='Last name'
                  props={props}
                />
                <InputField
                  value={values.middleName}
                  field='middleName'
                  label='Middle name'
                  props={props}
                />
                <InputField
                  value={values.phone}
                  field='phone'
                  label='Phone'
                  props={props}
                />
                <InputField
                  value={values.dob}
                  field='dob'
                  label='DOB'
                  props={props}
                />
                <InputField
                  value={values.gender}
                  field='gender'
                  label='Gender'
                  props={props}
                />
              </DrawerCardContent>
              <DrawerCardActions>
                <FormButton primary type='submit' >
                  Submit
                </FormButton>
                <FormButton onClick={handleClose}>Cancel</FormButton>
              </DrawerCardActions>
            </Form>
          );
        }}
      </Formik>
    );
  };
  return (
    <div>
      <RightDrawer
        title='Edit Patient Info'
        open={openDrawer}
        handleClose={handleClose}
      >
        {form()}
      </RightDrawer>
    </div>
  );
};
export default UserDrawer;
