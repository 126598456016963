import { SET_ACL } from '../action/types';

const initialState = {
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACL:
      return action.acl;
    default:
      return state;
  }
};
