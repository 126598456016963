import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';

import {
	DataTableStyles,
} from '@/Theme';

const APIAccesses = ({apiAccesses}) => {
	console.log("###### apiAccesses", apiAccesses)
	const handleRowClick = (row) => {
		navigator.clipboard.writeText(row.key);
	}
	const columns = useMemo(
		() => [
			{
				name: 'Type',
				selector: row => row.type,
				sortable: true,
			},
			{ 
				name: 'Key',
				width: '200px',
				selector: row => row.key,
				sortable: true,
				title: "Click to copy API Key",
			},
			{
				name: 'System',
				selector: row => row.system,
				sortable: true,
			},
			{
				name: 'Description',
				selector: row => row.description,
				sortable: true,
			},
			{
				name: 'White-listed IPs',
				selector: row => row.ips,
				sortable: true,
			},
			{
				name: 'Production',
				selector: row => row.active,
				sortable: true,
				width: '125px',
				cell: row => {
					return row.active ? "Yes" : "No"
				}
			},
			{
				name: 'Active',
				selector: row => row.active,
				sortable: true,
				width: '100px',
				cell: row => {
					return row.active ? "Yes" : "No"
				}
			}
		],
		[]
	);
	return (
		
					<DataTable
						className='pagination-custom'
						columns={columns}
						data={apiAccesses}
						noHeader
						onRowClicked={handleRowClick}
						highlightOnHover
						customStyles={DataTableStyles}
						persistTableHead
						defaultSortField='name'
						defaultSortAsc={false}
					/>
	);
};
export default APIAccesses;
