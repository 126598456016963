import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Drawer,
  Avatar,
} from '@mui/material';
import useWindowDimension from '@/Utils/window';
import { Form } from 'formik';

const EmptyPage = styled.div`
  && {
    color: #383838;
    text-align: center;
    background: #efefef;
    padding: 1rem;
    border-radius: 1rem;
  }
`;
/**
 * Main content header section.  h3 as content title.
 */
const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 15px;
  @media (max-width: 768px) {
    padding: 5px 16px;
  }
`;

const PageAction = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
`;
/**
 * Card display without shadow.  Ideally for information block display.
 */
const ContentCard = styled(({ group, border, ...otherProps }) => (
  <Card {...otherProps} />
))`
  && {
    box-shadow: none;
    ${(props) => props.group && `margin: 3rem 0 0 0;`}

    border: ${(props) => (props.border ? '1px solid #D4D4D4' : 'none')};
    .MuiCardHeader-title {
      font-size: 1.6rem;
      font-weight: 600;
    }
    .MuiCardHeader-subheader {
      font-size: 1.2rem;
    }
    display: flex;
    flex-direction: column;
    min-height: 0;
  }
`;
const ContentCardHeader = styled(CardHeader)`
  && {
    padding-bottom: 8px;
    .MuiCardHeader-action {
      margin-top: 0;
    }
  }
`;
const ContentCardContent = styled(CardContent)`
  && {
    // padding-top: 8px;
  }
`;

/**
 * Card display with shadow.  Ideally for information block display.
 */
const ContentCardShadowed = styled(ContentCard)`
  && {
    box-shadow: 0 3px 18px rgba(3, 48, 88, 0.28);
  }
`;
const DrawerCard = styled(Card)`
  && {
    box-shadow: none;
    border-radius: 0;
    .MuiCardHeader-title {
      font-size: 1.8rem;
      font-weight: 500;
    }
    display: flex;
    flex-direction: column;
    min-height: 0;
  }
`;

const DrawerForm = styled(Form)`
  && {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: auto;
  }
`;

const DrawerCardContent = styled(CardContent)`
  && {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: auto;
    width: 100%;
  }
`;
const DrawerCardActions = styled(CardActions)`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    padding: 0.8rem 1.6rem;
    border-top: 1px solid #eee;
    width: 100%;
  }
`;
const DrawerContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;
const DrawerCloseIcon = styled(CloseIcon)`
  && {
    color: #9e9e9e;
    padding: 0;
    font-size: 2.6rem;
  }
`;
const DrawerCloseButton = styled(({ width, handleClose, ...otherProps }) => (
  <IconButton
    size='small'
    aria-label='close'
    {...otherProps}
    onClick={() => handleClose()}
  >
    <DrawerCloseIcon />
  </IconButton>
))``;
const DrawerSecondaryCard = styled(Card)`
  && {
    box-shadow: none;
    border-radius: 0;
    padding: 24px 0;
    .MuiCardHeader-title {
      font-size: 1.4rem;
      font-weight: 500;
    }
    background: #eeeeee;
    transition: width 800ms;
  }
`;

const DrawerWithWidth = styled(({ width, ...otherProps }) => (
  <Drawer {...otherProps} />
))`
  && {
    .MuiDrawer-paper {
      width: ${(props) => `${props.width}px` || 'auto'};
    }
    ${(props) =>
      props.full &&
      `
		 .MuiDrawer-paper {
				width: 100% !important;
			}
	 	`}
    @media(max-width: 700px) {
      .MuiDrawer-paper {
        width: 100% !important;
      }
    }
  }
`;

const RightDrawer = styled(
  ({ title, actions, handleClose, children, ...otherProps }) => {
    const { height } = useWindowDimension();
    return (
      <Drawer
        variant='temporary'
        anchor='right'
        onClose={handleClose}
        {...otherProps}
      >
        <DrawerCard style={{ height: `${height}px`, overflowY: 'auto' }}>
          <CardHeader
            title={title}
            action={actions || <DrawerCloseButton handleClose={handleClose} />}
          />
          {children}
        </DrawerCard>
      </Drawer>
    );
  }
)`
  && {
    .MuiDrawer-paper {
      width: ${(props) => (props.width ? `${props.width}px` : '400px')};
    }
    ${(props) =>
      props.full &&
      `
		 .MuiDrawer-paper {
				width: 100% !important;
			}
	 	`}
    @media(max-width: 700px) {
      .MuiDrawer-paper {
        width: 100% !important;
      }
    }
  }
`;

const Loader = styled.div`
  border: 12px solid #4169e1;
  border-radius: 50%;
  border-top: 12px solid #d4d4d4;
  width: 45px;
  height: 45px;
  margin-bottom: 15px;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const DisplayList = styled(({ key, labelWidth, contentWidth, padding, ...otherProps }) => (
  <List {...otherProps}/>
))`
  && {
    padding: ${(props) => props.padding || '16px 24px'};
    .MuiListItem-root {
      padding: 0;
    }
    .MuiListItemAvatar-root {
      padding: 20px 0;
    }
    .MuiListItemText-multiline {
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-basis: 0;
      flex-grow: 1;
      padding: 0 8px;
    }
    .MuiListItemText-multiline:hover {
      background: #eeeeee;
      cursor: default;
    }
    .MuiListItemText-primary {
      font-size: 14px;
      font-size: 1.4rem;
      color: #616161;
      flex-basis: 0;
      flex-grow: ${(props) => props.labelWidth || 3};
    }
    .MuiListItemText-secondary {
      font-weight: 600;
      font-size: 1.4px;
      font-size: 1.4rem;
      color: #212121;
      flex-basis: 0;
      flex-grow: ${(props) => props.contentWidth || 9};
    }
  }
`;
const DataList = styled(List)`
  && {
    margin: 0;
    padding: 0;
    .MuiListItem-root {
      padding: 0;
    }
    .MuiListItemText-multiline {
      margin: 0;
      margin-bottom: 1rem;
    }
    .MuiListItemText-multiline:hover {
      background: #eee;
    }
    .MuiListItemText-primary {
      font-size: 12px;
      font-size: 1.2rem;
      color: #757575;
    }
    .MuiListItemText-secondary {
      font-weight: 600;
      font-size: 14px;
      font-size: 1.4rem;
      color: #212121;
      padding-left: 0.5rem;
    }
  }
`;
const DataListItem = styled(({ label, value, contentprops,  ...otherProps}) => {
  value = value || '';
  return (
    <ListItem {...otherProps}>
      <ListItemText primary={label} secondary={value} {...contentprops}/>
    </ListItem>
  );
})`
	&&{
		.MuiListItemText-root{
			padding:0 .8rem;
		}
		.MuiListItemText-primary, .MuiListItemText-secondary{
			cursor: default;
		}

		.MuiListItemText-secondary:empty:after{
			content: ".";
			visibility:hidden;
		}
`;

const ProfileAvatar = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px solid #c4c4c4;
  margin: 10px 0 0 10px;
  justify-self: left;

  img {
    border-radius: 50%;
    width: 119px;
    height: 119px;
    object-fit: cover;
    border: 1px solid lighten(#000000, 20%);
  }

  .avatar-default {
    border-radius: 50%;
    height: 100%;
    font-size: 3.8rem;
    font-weight: 600;
  }
`;

const LogoAvatar = styled(Avatar)`
	  &&{
			&.MuiAvatar-colorDefault{
				color: #FFFFFF;
    		background-color: #424242;
    	}
    	.MuiAvatar-img{
    		object-fit: contain;
    	}
		}
}`;

const NavListItem = styled(ListItem)`
  && {
    padding: 3px;
    &:hover {
      background: none;
    }
    .MuiSvgIcon-root {
      font-size: 3rem;
    }
    .MuiListItemText-primary {
      font-size: 1.6rem;
    }
    .MuiListItemText-secondary {
      font-size: 1.2rem;
    }
    .MuiListItemIcon-root,
    .MuiListItemText-primary,
    .MuiListItemText-secondary {
      color: #2a2277;
    }
    &:hover .MuiListItemIcon-root,
    &:hover .MuiListItemText-primary,
    &:hover .MuiListItemText-secondary {
      color: #ef599d;
    }
  }
`;

export {
  Loader,
  EmptyPage,
  PageHeader,
  PageAction,
  ContentCard,
  ContentCardHeader,
  ContentCardContent,
  ContentCardShadowed,
  RightDrawer,
  DrawerWithWidth,
  DrawerForm,
  DrawerCard,
  DrawerCardContent,
  DrawerCardActions,
  DrawerSecondaryCard,
  DrawerContent,
  DrawerCloseButton,
  DisplayList,
  DataList,
  DataListItem,
  ProfileAvatar,
  NavListItem,
  LogoAvatar,
};
