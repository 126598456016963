import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {
	DataTableStyles,
} from '@/Theme';
import { AdminSvc } from '@/Services';

const Webhooks = ({webhooks, refreshData}) => {
	console.log("###### Webhooks", webhooks)
	const handleRowClick = (row) => {
		navigator.clipboard.writeText(row.key);
	}
	const columns = useMemo(
		() => [
			{
				name: 'Event',
				selector: row => row.event,
				sortable: true,
			},
			{
				name: 'Destination URL',
				selector: row => row.url,
				sortable: true,
			},
			{
				name: 'Method',
				selector: row => row.method,
				sortable: true,
			},
			{
				name: 'Headers',
				selector: row => row.headers,
				sortable: true,
			},
			{
				name: 'Production',
				selector: row => row.production,
				sortable: true,
				width: '125px',
				cell: row => {
					return row.production ? "Yes" : "No"
				}
			},
			{
				name: 'Active',
				selector: row => row.active,
				sortable: true,
				width: '100px',
				cell: row => {
					return row.active ? "Yes" : "No"
				}
			},
			{
				name: '',
				width: '75px',
				cell: (row) => {
					return (
						<>
						<IconButton onClick={() => deleteWebhook(row)}>
              <DeleteIcon />
            </IconButton>
						</>
					);
				},
			},
		],
		[]
	);
	const deleteWebhook = async (webhook) => {
		let response = await AdminSvc.deleteWebhook(webhook);
    if (response){
      refreshData();
    }
	};
	return (
		
					<DataTable
						className='pagination-custom'
						columns={columns}
						data={webhooks}
						noHeader
						onRowClicked={handleRowClick}
						highlightOnHover
						customStyles={DataTableStyles}
						persistTableHead
						defaultSortField='event'
						defaultSortAsc={false}
					/>
	);
};
export default Webhooks;
