import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { DateTime } from 'luxon';
import { LabService } from '@/Services';
import {
	DataTableStyles,
} from '@/Theme';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const ResultList = ({ results, handleResult, handleDeleteResult }) => {
	// console.log("###### results", results)
	const handleRowClick = (row) => {
		handleResult(row);
	}
	const deleteResult = async (result) => {
		LabService.deleteResult(result);
	};
	const columns = useMemo(
		() => [
			{
				name: 'Status',
				selector: row => row.status,
				sortable: true,
				width: '100px',
			},
			{
				name: 'Panel Number',
				selector: row => row.panelNumber,
				sortable: true,
				width: '85px',
			},
			{
				name: 'Test Number',
				selector: row => row.testNumber,
				sortable: true,
				width: '85px',
			},
			{
				name: 'Test Name',
				selector: row => row.testName,
				sortable: true,
			},
			// {
			// 	name: 'Result File',
			// 	selector: row => row.resultFiles,
			// 	sortable: true,
			// 	width: '125px',
			// },
			{
				name: 'Result',
				selector: row => row.result,
				sortable: true,
				width: '100px',
			},
			{
				name: 'Type',
				width: '100px',
				selector: row => row.resultType,
				sortable: true,
			},
			{
				name: 'Unit',
				width: '100px',
				selector: row => row.unitsOfMeasurement,
				sortable: true,
			},
			{
				name: 'Reference',
				selector: row => row.referenceRange,
				width: '125px',
				sortable: true,
			},
			{
				name: 'Abnormal Flag',
				width: '75px',
				selector: row => row.abnormalFlag,
				sortable: true,
			},
			{
				name: 'Rejection Code',
				selector: row => row.rejectionCode,
				sortable: true,
				width: '75px',
			},
			{
				name: 'Result Date',
				selector: row => row.result_date,
				width: '100px',
				sortable: true,
			},
			{
				name: 'Last Update',
				sortable: true,
				width: '100px',
				cell: (row) => {
					return `${DateTime.fromISO(row.updated).toLocaleString(
						DateTime.DATETIME_SHORT
					)}`;
				},
			},
			{
				name: '',
				width: '75px',
				cell: (row) => {
					return (
						<>
							<IconButton onClick={() => deleteResult(row)}>
								<DeleteIcon />
							</IconButton>
						</>
					);
				},
			},
		],
		[]
	);
	return (

		<DataTable
			className='pagination-custom'
			columns={columns}
			data={results}
			noHeader
			onRowClicked={handleRowClick}
			highlightOnHover
			customStyles={DataTableStyles}
			persistTableHead
			defaultSortField='testName'
			defaultSortAsc={false}
		/>
	);
};
export default ResultList;
