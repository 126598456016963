import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { KitsIcon } from '@/Theme/Icons'
import {
  SecondaryPage,
  DataList,
  DataListItem,
  ContentCardContent,
  ContentCardHeader,
  ContentCardShadowed,
  PrimaryButton
} from '@/Theme';
import { Grid, Avatar, IconButton } from '@mui/material';
/** Local imports */
import Edit from './Edit';
import { KitService } from '@/Services';
import { DateTime } from 'luxon';
import { PrintIcon } from '@/Theme/Icons';

const View = () => {
  const { id } = useParams();
  const [selectedItem, setSelectedItem] = useState({
    address: {},
    labKit: {},
  });
  const [loading, setLoading] = useState(false);
  //for editing

  const fetch = async () => {
    setLoading(true);
    let response = await KitService.getKit(id);
    if (response && response.data) {
      setSelectedItem(response.data);
      console.log('##### response.data', response.data);
      console.log('##### response.data.order', response.data.order);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetch();
  }, [id]);

  const title = () => {
    return (
      <>
        <KitsIcon />
        <span style={{ marginLeft: '1rem' }}>
          Kit: {selectedItem.kitNumber}
        </span>
      </>
    );
  };
  const printLable = async() => {
    await KitService.printLables([selectedItem]);
    fetch();
  }
  return (
    <SecondaryPage title={title()} back='/kit'
      actions={
        <PrimaryButton
          onClick={printLable}
          size='small'
          startIcon={<PrintIcon />}
        >
          Print
        </PrimaryButton>
      }
    >
      <ContentCardShadowed>
        <ContentCardHeader title='Kit' />
        <ContentCardContent>
          <Grid container>
            <Grid item xs={6}>
              <DataList>
                <DataListItem
                  label='Registration Code'
                  value={selectedItem.kitNumber}
                />
                <DataListItem label='Used' value={selectedItem.used ? "Yes" : "No"} />
                <DataListItem label='Printed' value={selectedItem.printed ? "Yes" : "No"} />
                <DataListItem
                  label='Last Print'
                  value={selectedItem.lastPrint}
                />
                <DataListItem label='External ID' value={selectedItem.externalId} />
              </DataList>
            </Grid>
            <Grid item xs={6}>
              <DataList>
                <DataListItem
                  label='Collection date'
                  value={selectedItem.collectionDate}
                />
                <DataListItem
                  label='Collection time'
                  value={selectedItem.collectionTime}
                />
                <DataListItem
                  label='Transmitted to lab'
                  value={selectedItem.transimittedToLab ? 'Yes' : 'No'}
                />
                <DataListItem label='Status' value={selectedItem.status} />
                <DataListItem
                  label='Created on'
                  value={DateTime.fromISO(selectedItem.created).toLocaleString(
                    DateTime.DATETIME_SHORT
                  )}
                />
                <DataListItem
                  label='Last update'
                  value={DateTime.fromISO(selectedItem.updated).toLocaleString(
                    DateTime.DATETIME_SHORT
                  )}
                />
              </DataList>
            </Grid>
          </Grid>
        </ContentCardContent>
      </ContentCardShadowed>
      <ContentCardShadowed group>
        <ContentCardHeader title='Lab Kit' />
        <ContentCardContent>
          <Grid container>
            <Grid item xs={6}>
              <DataList>
                <DataListItem
                  label='Name'
                  value={selectedItem.labKit.name}
                />
                <DataListItem
                  label='Description'
                  value={selectedItem.labKit.description}
                />
                <DataListItem
                  label='Display name'
                  value={selectedItem.labKit.displayName}
                />
                <DataListItem
                  label='Display Description'
                  value={selectedItem.labKit.displayDescription}
                />
              </DataList>
            </Grid>
            <Grid item xs={6}>
              <DataList>
                <DataListItem
                  label='Weight (oz)'
                  value={selectedItem.labKit.weight}
                />
              </DataList>
            </Grid>
          </Grid>
        </ContentCardContent>
      </ContentCardShadowed>
    </SecondaryPage>
  );
};
export default View;
