import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import List from './List';
import Edit from './Edit';
import View from './View';
import {
  FilterBox,
  PageHeader,
  PageAction,
  PrimaryButton,
  SecondaryPage,
  PrimaryPage,
} from '@/Theme';
import { ShippingService } from '@/Services';
import * as XLSX from 'xlsx';
import DatePicker from "react-datepicker";
import _ from 'underscore';
import "react-datepicker/dist/react-datepicker.css";

const XLSX_ZAHL_PAYLOAD = require("xlsx/dist/xlsx.zahl");

const Home = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [filter, setFilter] = useState({ search: "" });
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(50);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('');
  const [direction, setDirection] = useState('desc');
  //For editing
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawer, setDrawer] = useState('');
  const [selectedItem, setSelectedItem] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const filters = {
    where: {},
    size: size,
    page: page,
    sort: sort,
    order: direction,
  }
  const fetch = async () => {
    if (!listData.length) {
      setLoading(true);
    }
    filters.where = filter;
    let response = await ShippingService.search(filters);
    console.log({ response });
    if (response && response.data && response.data.objects) {
      setListData(response.data.objects);
      setCount(response.data.count);
      setLoading(false);
    }
  }
  useEffect(() => {
    console.log('### fetch shipping', search, size, page, sort, direction);
    filter.where = { freeText: search };
    fetch();
  }, [filter, size, page, sort, direction]);

  const onSearchChange = (e) => {
    e.preventDefault();
    console.log('### in onSearchChange', e.target.value);
    setSearch(e.target.value);
  }
  const onSearchPressed = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let updatedFilter = { ...filter, ...{ search: e.target.value } }
      setFilter(updatedFilter);
    }
  }
  const onStartChange = (date) => {
    let updatedFilter = { ...filter, ...{ startDate: date } }
    setFilter(updatedFilter);
    setStartDate(date);
  }
  const onEndChange = (date) => {
    let updatedFilter = { ...filter, ...{ endDate: date } }
    setFilter(updatedFilter);
    setEndDate(date);
  }
  const handleCopy = (row) => {
    navigator.clipboard.writeText(row.trackingNumber);
  }
  const exportCSV = async () => {
    let data = [['Tracking','Name','Organization','Carrier','Rate','Service','Status']]

    let csvFilters = {
      where: filter,
      size: 99999,
      page: 1,
      sort: sort,
      order: direction
    };
    let response = await ShippingService.search(csvFilters);
    _.each(response.data.objects, shipping => {
      let row = []
      row.push(shipping.trackingNumber)
      row.push(shipping.deliveryAddress ? shipping.deliveryAddress.name : '')
      row.push(shipping.organization ? shipping.organization.name : '')
      row.push(shipping.carrier)
      row.push(shipping.rate)
      row.push(shipping.service)
      row.push(shipping.status)
      data.push(row);
    });

    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "shipments.xlsx", {numbers: XLSX_ZAHL_PAYLOAD, compression: true});
  }
  return (
    <PrimaryPage title='Shipping'>
      <PageHeader>
        <PageAction>
          <FilterBox onChange={onSearchChange} onPress={onSearchPressed} />
          <DatePicker placeholderText='Start Date' selected={startDate} onChange={onStartChange} />
          <DatePicker placeholderText='End Date' selected={endDate} onChange={onEndChange} />
        </PageAction>
        <PrimaryButton onClick={exportCSV} size='small'>
          Export to excel
        </PrimaryButton>
      </PageHeader>
      <List
        listData={listData}
        setListData={setListData}
        setOpenDrawer={setOpenDrawer}
        handleCopy={handleCopy}
        loading={loading}
        count={count}
        size={size}
        setSize={setSize}
        page={page}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
        direction={direction}
        setDirection={setDirection}
      />
    </PrimaryPage>
  );
};
export default Home;
