import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import List from './List';
import Edit from './Edit';
import View from './View';
import {
  FilterBox,
  PageHeader,
  PageAction,
  PrimaryButton,
  SecondaryPage,
  FormButton,
} from '@/Theme';
import { AdminSvc } from '@/Services';

const Home = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [filter, setFilter] = useState({ search: ""});
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(50);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('name');
  const [direction, setDirection] = useState('asc');
  //For editing
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawer, setDrawer] = useState('');
  const [selectedItem, setSelectedItem] = useState({});

  const filters = {
    where: {},
    size: size,
    page: page,
    sort: sort,
    order: direction,
  };
  const fetch = async () => {
    setLoading(true);
    filters.where = filter;
    let response = await AdminSvc.searchTests(filters);
    if (response && response.data && response.data.objects) {
      const objects = response.data.objects;
      setListData(objects);
      setCount(response.data.count);
    }
    setLoading(false);
  };
  useEffect(() => {
    console.log('### fetch test types', search, size, page, sort, direction);
    fetch();
  }, [filter, size, page, sort, direction]);

  const onSearchChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };
  const onSearchPressed = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let updatedFilter = { ...filter, ...{ search: e.target.value } }
      setFilter(updatedFilter);
    }
  };
  const handleApplyFilter = () => {
    let updatedFilter = {
      ...filter,
      ...{ search: search },
    }
    setFilter(updatedFilter);
  }
  const handleEditSuccess = () => {
    fetch();
  };
  const handleEdit = (test) => {
    setSelectedItem(test);
    setDrawer('new');
    setOpenDrawer(true);
  };
  const handleNew = () => {
    setSelectedItem({});
    setDrawer('new');
    setOpenDrawer(true);
  };
  const contentDrawer = () => {
    let drawerContent = '';
    if (openDrawer) {
      drawerContent = (
        <Edit
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          test={selectedItem}
          success={handleEditSuccess}
          inputVariables={filters}
        />
      );
    }
    return drawerContent;
  };
  return (
    <SecondaryPage title='Tests' back='/admin/dashboard'>
      <PageHeader>
        <PageAction>
          <FilterBox onChange={onSearchChange} onPress={onSearchPressed}
            extend={
              <>
                <FormButton compact primary onClick={handleApplyFilter}>Filter</FormButton>
              </>
            }
          />
        </PageAction>
        <PrimaryButton onClick={handleNew}>New Test</PrimaryButton>
      </PageHeader>
      <List
        listData={listData}
        setOpenDrawer={setOpenDrawer}
        loading={loading}
        handleEdit={handleEdit}
        count={count}
        size={size}
        setSize={setSize}
        page={page}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
        direction={direction}
        setDirection={setDirection}
      />
      {contentDrawer()}
    </SecondaryPage>
  );
};
export default Home;
