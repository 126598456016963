import React, { useState, useEffect, Component } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MenuItem,
  CardContent,
  Grid,
  StepContent,
  Stepper,
  StepButton,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { OrderService, AdminSvc } from '@/Services';
import AddIcon from '@mui/icons-material/Add';
import CopyIcon from '@mui/icons-material/FileCopy';
import {
  Formik,
  Form,
} from 'formik';
import * as Yup from 'yup';
import {
  FormButton,
  InputField,
  SecondaryPage,
  ContentCard,
  ContentCardHeader,
  DataList,
  DataListItem,
  ContentCardContent,
  FixedGrid,
  FancyStep
} from '@/Theme';

const Edit = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [labKits, setLabKits] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [orgSelected, setOrgSelected] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState({});

  const fetchLabKits = async (organizationId) => {
    let response = await AdminSvc.listKits(organizationId);
    if (response && response.data) {
      setLabKits(response.data);
    }
    return response.data;
  };

  const fetchOrganizations = async () => {
    let response = await AdminSvc.searchOrganizations();
    if (response && response.data) {
      setOrganizations(response.data);
    }
  };

  const handleClose = () => {
    let link = `/kit/order`;
    navigate(link);
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const initValues = {
    organizationId: '',
    customer: {
      email: '',
      firstName: '',
      lastName: '',
      middleName: '',
      externalId: '',
      address: {
        name: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
    },
    shipping: {
      name: '',
      street1: '',
      street2: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
    billing: {
      name: '',
      street1: '',
      street2: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
    labKits: [],
  };
  const validationSchema = () => {
    return Yup.object().shape({
      customer: Yup.object().shape({
        email: Yup.string().required('Email is required'),
      }),
    });
  };
  const processSubmit = async (values, { setSubmitting }) => {
    console.log('##### save', values);
    let savedOrder = await OrderService.newOrder(values);
    console.log('#### saved Order', savedOrder);
    if (savedOrder.error) {
      enqueueSnackbar(savedOrder.message || 'Error while creating new order.', {
        variant: 'error',
      });
    } else {
      enqueueSnackbar('Order placed.', { variant: 'success' });
      handleClose();
    }
  };
  const form = () => {
    return (
      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) =>
          processSubmit(values, setSubmitting)
        }
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setTouched,
            setFieldTouched,
            setFieldValue,
            setValues,
          } = props;

          const addPanel = () => {
            if (labKits && labKits.length > 0) {
              let labKit = labKits[0];
              let tests = [...values.labKits];
              tests.push({ id: labKits.id, quantity: 1 });
              setFieldValue('labKits', tests);
            }
          };
          const handleOrgSelected = async (event) => {
            setOrgSelected(true);
            let organizationId = event.target.value;
            let labKitPanels = await fetchLabKits(organizationId);
            setFieldValue('organizationId', organizationId);
            if (labKitPanels && labKitPanels.length > 0) {
              let labKit = labKitPanels[0];
              setFieldValue('labKits', [{ id: labKit.id, quantity: 1 }]);
            }
            setActiveStep(1);
            let org = organizations.find((o) => o.id === organizationId);
            setSelectedOrg(org);
          };
          const copyAddress = (to) => {
            values.customer.address.name =
              values.customer.firstName + ' ' + values.customer.lastName;
            setFieldValue(to, values.customer.address);
            setFieldTouched(to, true, false);
          };
          return (
            <Form onSubmit={handleSubmit} autoComplete='nope'>
              <SecondaryPage
                title='New Kit Order'
                back='/kit/order'
                actions={
                  <>
                    <FormButton primary type='submit'>
                      Save
                    </FormButton>
                    <FormButton onClick={handleClose}>Cancel</FormButton>
                  </>
                }
              >
                <Grid container spacing={2} className='flex'>
                  <FixedGrid item sm={12} className=''>
                    <ContentCard border>
                      <ContentCardContent>
                        <Stepper
                          nonLinear
                          orientation='vertical'
                          activeStep={activeStep}
                        >
                          <FancyStep key='organizationStep' expanded>
                            <StepButton>
                              <Typography variant='h5'>Organization</Typography>
                            </StepButton>
                            <StepContent>
                              <ContentCard>
                                <CardContent>
                                  <InputField
                                    sx={{ width: '300px' }}
                                    className='flex-9'
                                    field='organizationId'
                                    value={values.organizationId}
                                    label='Select an organization for the order'
                                    props={props}
                                    select
                                    onChange={(e) => {
                                      handleChange(e);
                                      handleOrgSelected(e);
                                    }}
                                  >
                                    {organizations.map((option) => (
                                      <MenuItem
                                        key={option.id}
                                        value={option.id}
                                      >
                                        {option.name}
                                      </MenuItem>
                                    ))}
                                  </InputField>
                                </CardContent>
                              </ContentCard>
                            </StepContent>
                          </FancyStep>
                          <FancyStep
                            key='testStep'
                            disabled={!orgSelected}
                            expanded={orgSelected}
                          >
                            <div className='flex flex-row-sc justify-between'>
                              <StepButton
                                onClick={handleStep(1)}
                                className='flex '
                              >
                                <Typography variant='h5'>
                                  Test Panels
                                </Typography>
                              </StepButton>
                              <FormButton
                                sx={{
                                  display: orgSelected ? 'inherit' : 'none',
                                }}
                                compact
                                variant='outlined'
                                startIcon={<AddIcon size='small' />}
                                onClick={() => addPanel()}
                              >
                                Add More Test
                              </FormButton>
                            </div>
                            <StepContent>
                              <ContentCard>
                                <CardContent>
                                  {values.labKits.map((test, index) => (
                                    <div
                                      className='flex flex-row-cc justify-between '
                                      key={`labKits.${'00' + index}.text`}
                                    >
                                      <InputField
                                        className='flex-9'
                                        field={`labKits[${index}].id`}
                                        value={values.labKits[index].id}
                                        label='Test panel'
                                        props={props}
                                        select
                                      >
                                        {labKits.map((option) => (
                                          <MenuItem
                                            key={option.id}
                                            value={option.id}
                                          >
                                            {option.name}
                                          </MenuItem>
                                        ))}
                                      </InputField>
                                      <span className='flex-1' />
                                      <InputField
                                        className='flex-2'
                                        field={`labKits[${index}].quantity`}
                                        value={values.labKits[index].quantity}
                                        label='Quantity'
                                        props={props}
                                      />
                                    </div>
                                  ))}
                                </CardContent>
                              </ContentCard>
                            </StepContent>
                          </FancyStep>
                          <FancyStep
                            key='customerStep'
                            disabled={!orgSelected}
                            expanded={orgSelected}
                          >
                            <div className='flex flex-row-sc justify-between'>
                              <StepButton
                                onClick={handleStep(2)}
                                className='flex '
                              >
                                <Typography variant='h5'>
                                  Customer Information
                                </Typography>
                              </StepButton>
                            </div>
                            <StepContent>
                              <ContentCard>
                                <CardContent>
                                  <InputField
                                    type = 'email'
                                    value={values.customer.email}
                                    field='customer.email'
                                    label='Email'
                                    required
                                    props={props}
                                  />
                                  <InputField
                                    value={values.customer.firstName}
                                    field='customer.firstName'
                                    label='First name'
                                    required
                                    props={props}
                                  />
                                  <InputField
                                    value={values.customer.lastName}
                                    field='customer.lastName'
                                    label='Last name'
                                    required
                                    props={props}
                                  />
                                  <InputField
                                    value={values.customer.middleName}
                                    field='customer.middleName'
                                    label='Middle name'
                                    props={props}
                                  />
                                  <InputField
                                    value={values.customer.home}
                                    field='customer.home'
                                    label='Primay Phone'
                                    props={props}
                                  />
                                  <InputField
                                    value={values.customer.mobile}
                                    field='customer.mobile'
                                    label='Mobile Phone'
                                    required
                                    props={props}
                                  />
                                  <InputField
                                    value={values.customer.address.street1}
                                    field='customer.address.street1'
                                    label='Street address line 1'
                                    required
                                    props={props}
                                  />
                                  <InputField
                                    value={values.customer.address.street2}
                                    field='customer.address.street2'
                                    label='Street address line 2'
                                    props={props}
                                  />
                                  <InputField
                                    value={values.customer.address.city}
                                    field='customer.address.city'
                                    label='City'
                                    required
                                    props={props}
                                  />
                                  <InputField
                                    value={values.customer.address.state}
                                    field='customer.address.state'
                                    label='State/Province '
                                    required
                                    props={props}
                                  />
                                  <InputField
                                    value={values.customer.address.postalCode}
                                    field='customer.address.postalCode'
                                    label='Postal code '
                                    required
                                    props={props}
                                  />
                                  <InputField
                                    value={values.customer.address.country}
                                    field='customer.address.country'
                                    label='Country'
                                    required
                                    props={props}
                                  />
                                  <InputField
                                    field='customer.externalId'
                                    label='External ID'
                                    props={props}
                                  />
                                  <InputField
                                    field='customer.metadata'
                                    label='Metadata'
                                    props={props}
                                  />
                                </CardContent>
                              </ContentCard>
                            </StepContent>
                          </FancyStep>
                          <FancyStep
                            key='shippingInfo'
                            disabled={!orgSelected}
                            expanded={orgSelected}
                          >
                            <div className='flex flex-row-sc justify-between'>
                              <StepButton
                                onClick={handleStep(3)}
                                className='flex '
                              >
                                <Typography variant='h5'>
                                  Shipping Information
                                </Typography>
                              </StepButton>

                              <FormButton
                                sx={{
                                  display: orgSelected ? 'inherit' : 'none',
                                }}
                                compact
                                variant='outlined'
                                startIcon={<CopyIcon size='small' />}
                                onClick={() => copyAddress('shipping')}
                              >
                                Use Customer Address
                              </FormButton>
                            </div>
                            <StepContent>
                              <ContentCard>
                                <CardContent>
                                  <InputField
                                    field='shipping.name'
                                    value={values.shipping.name}
                                    label='Name on address'
                                    required
                                    props={props}
                                  />
                                  <InputField
                                    field='shipping.phone'
                                    value={values.shipping.phone}
                                    label='Phone'
                                    props={props}
                                  />
                                  <InputField
                                    field='shipping.street1'
                                    value={values.shipping.street1}
                                    label='Street address1'
                                    required
                                    props={props}
                                  />
                                  <InputField
                                    field='shipping.street2'
                                    value={values.shipping.street2}
                                    label='Street address2'
                                    props={props}
                                  />
                                  <InputField
                                    field='shipping.city'
                                    value={values.shipping.city}
                                    label='City'
                                    required
                                    props={props}
                                  />
                                  <InputField
                                    field='shipping.state'
                                    value={values.shipping.state}
                                    label='State/Province '
                                    required
                                    props={props}
                                  />
                                  <InputField
                                    field='shipping.postalCode'
                                    value={values.shipping.postalCode}
                                    label='Postal code '
                                    required
                                    props={props}
                                  />
                                  <InputField
                                    field='shipping.country'
                                    value={values.shipping.country}
                                    label='Country'
                                    required
                                    props={props}
                                  />
                                </CardContent>
                              </ContentCard>
                            </StepContent>
                          </FancyStep>
                          <FancyStep
                            key='billingInfo'
                            disabled={!orgSelected}
                            expanded={orgSelected}
                          >
                            <div className='flex flex-row-sc justify-between'>
                              <StepButton
                                onClick={handleStep(4)}
                                className='flex '
                              >
                                <Typography variant='h5'>
                                  Billing Information
                                </Typography>
                              </StepButton>

                              <FormButton
                                sx={{
                                  display: orgSelected ? 'inherit' : 'none',
                                }}
                                compact
                                variant='outlined'
                                startIcon={<CopyIcon size='small' />}
                                onClick={() => copyAddress('billing')}
                              >
                                Use Customer Address
                              </FormButton>
                            </div>
                            <StepContent>
                              <ContentCard>
                                <CardContent>
                                  <InputField
                                    field='billing.name'
                                    value={values.billing.name}
                                    label='Name on address'
                                    required
                                    props={props}
                                  />
                                  <InputField
                                    field='billing.street1'
                                    value={values.billing.street1}
                                    label='Street address1'
                                    required
                                    props={props}
                                  />
                                  <InputField
                                    field='billing.street2'
                                    label='Street address2'
                                    value={values.billing.street2}
                                    props={props}
                                  />
                                  <InputField
                                    field='billing.city'
                                    label='City'
                                    value={values.billing.city}
                                    required
                                    props={props}
                                  />
                                  <InputField
                                    field='billing.state'
                                    label='State/Province '
                                    value={values.billing.state}
                                    required
                                    props={props}
                                  />
                                  <InputField
                                    field='billing.postalCode'
                                    label='Postal code '
                                    value={values.billing.postalCode}
                                    required
                                    props={props}
                                  />
                                  <InputField
                                    field='billing.country'
                                    label='Country'
                                    value={values.billing.country}
                                    required
                                    props={props}
                                  />
                                </CardContent>
                              </ContentCard>
                            </StepContent>
                          </FancyStep>
                        </Stepper>
                      </ContentCardContent>
                    </ContentCard>
                  </FixedGrid>
                </Grid>
              </SecondaryPage>
            </Form>
          );
        }}
      </Formik>
    );
  };

  return form();
};
export default Edit;
