import React, { useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import { Route, useNavigate } from 'react-router-dom';
import { AUTH_SESSION_STORE } from './types';

const mapStateToProps = state => {
  return { session: state[AUTH_SESSION_STORE] }
}

/**
 * Redux enabled private route. If user is not logged in session,
 * return Fragment
 */
const PrivateRoute = (props) => {
  const navigate = useNavigate();
  const isLoggedIn = props.session.isLoggedIn;
  let Component = props.comp;
  let redirect = props.redirect || "/login";
  useEffect(() => {
    if (!isLoggedIn) {
      navigate(redirect);
    }

  }, []);

  return isLoggedIn ? <Component {...props} /> : <></>;
}

export default connect(mapStateToProps)(PrivateRoute)
