/** PACKAGE IMPORTS */
import React, { useEffect } from 'react';

import {
  PrimaryPage,
  ContentCardContent,
  ContentCardShadowed,
  ContentCardHeader,
} from '@/Theme';
import { Typography, Grid } from '@mui/material';

import { Redux as WebAuth } from '@ihdlab/web-auth';

const Home = () => {
  return (
    <PrimaryPage title='Help Center'>
      <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
        <Grid item xs={12}>
          <ContentCardShadowed>
            <ContentCardHeader title='User Manuals' />
            <ContentCardContent>
              <Typography variant='p' sx={{ fontSize: '1.4rem' }}>
                <p>
                  <a
                    href='https://docs.google.com/document/d/1PsAbz85L9azyaF1lMMdLYrXSkN-yJs8s44ItnF_P75Y/edit?usp=sharing'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Order Management System (OMS) User Manual
                  </a>
                </p>
                <br />
                <p>
                  <a
                    href='https://docs.google.com/document/d/13-QH98gpcsJyFPN0lAV5dwhMRT3C44LE8T5iOIQRi6Q/edit?usp=sharing'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Patient Portal User Manual
                  </a>
                </p>
              </Typography>
            </ContentCardContent>
          </ContentCardShadowed>
        </Grid>
      </Grid>
    </PrimaryPage>
  );
};
export default Home;
