import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import TaskIcon from '@mui/icons-material/ViewInAr';
import { useSnackbar } from 'notistack';
import {
  SecondaryPage,
  DataList,
  DataListItem,
  ContentCardContent,
  ContentCardHeader,
  ContentCardShadowed,
  FormButton,
  PrimaryButton
} from '@/Theme';
import { Grid, IconButton } from '@mui/material';
/** Local imports */
import { LabService } from '@/Services';
import { AdminSvc } from '@/Services';
import { DateTime } from 'luxon';
import Result from './Result';
import Patient from './Patient';
import ResultList from './ResultList';
import { DropdownIcon, DownloadIcon, TestsIcon } from '@/Theme/Icons';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const View = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [labOrder, setLabOrder] = useState({});
  const [organization, setOrganization] = useState({});
  const [labKit, setLabKit] = useState({});
  const [kit, setKit] = useState({});
  const [patient, setPatient] = useState({});
  const [results, setResults] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [action, setAction] = useState(null);
  const [selectedResult, setSelectedResult] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const taskMenuOpen = Boolean(anchorEl);
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);
  const statusMenuOpen = Boolean(statusAnchorEl);

  const fetch = async () => {
    setLoading(true);
    let response = await LabService.get(id);
    console.log('##### response', response);
    if (response && response.data) {
      setLabOrder(response.data);
      setOrganization(response.data.organization);
      setLabKit(response.data.labKit);
      setKit(response.data.kit);
      setResults(response.data.results);
      setPatient(response.data.patient);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetch();
  }, [id]);
  const refresh = () => {
    fetch();
  };

  const handleResult = (result) => {
    setSelectedResult(result);
    setAction('result');
    setOpenDrawer(true);
  };
  const handelPatient = () => {
    setAction('patient');
    setOpenDrawer(true);
  };
  const markKitReceived = async () => {
    let response = await LabService.markKitReceived(id)
    enqueueSnackbar('Updated kit received status.', { variant: 'success' });
    fetch();
  }
  const markLabOrderRejected = async () => {
    let response = await LabService.markLabOrderRejected(id)
    enqueueSnackbar('Updated rejected status.', { variant: 'success' });
    fetch();
  }
  const markLabOrderComplete = async () => {
    let response = await LabService.markLabOrderComplete(id)
    enqueueSnackbar('Updated completed status.', { variant: 'success' });
    fetch();
  }
  const handleTaskMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseTaskMenu = () => {
    setAnchorEl(null);
  };
  const handleStatusMenu = (event) => {
    setStatusAnchorEl(event.currentTarget);
  };
  const handleCloseStatusMenu = () => {
    setStatusAnchorEl(null);
  };
  const handlePushToLabTask = async () => {
    let response = await AdminSvc.createTask(id, "Push To Lab")
    enqueueSnackbar('Push to lab task created.', { variant: 'success' });
  }
  const handleKitReceivedTask = async () => {
    let response = await AdminSvc.createTask(id, "Kit Received")
    enqueueSnackbar('Kit received task created.', { variant: 'success' });
  }
  const handleResultUpdateTask = async () => {
    let response = await AdminSvc.createTask(id, "Result Update")
    enqueueSnackbar('Result update task created.', { variant: 'success' });
  }
  const contentDrawer = () => {
    let drawerContent = '';
    if (openDrawer && action) {
      if (action === 'result') {
        drawerContent = (
          <Result
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            labOrder={labOrder}
            result={selectedResult}
            success={refresh}
          />
        );
      } else if (action === 'patient') {
        drawerContent = (
          <Patient
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            patient={patient}
            success={refresh}
          />
        );
      }
    }
    return drawerContent;
  };
  const title = () => {
    return (
      <>
        <TestsIcon fontSize='large' />
        <span style={{ marginLeft: '1rem' }}>
          Lab Order for {patient.firstName} {patient.lastName}
        </span>
      </>
    );
  };
  return (
    <SecondaryPage title={title()} back='/lab' actions={
      <>
        <PrimaryButton
          onClick={handleTaskMenu}
          size='small'
          endIcon={<DropdownIcon />}
        >
          Create Task
        </PrimaryButton>
        <Menu
          id='menu-appbar'
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={taskMenuOpen}
          onClose={handleCloseTaskMenu}
        >
          <MenuItem
            sx={{ fontSize: '1.2rem', padding: '8px 3px' }}
            onClick={handleResultUpdateTask}
          >
            <TaskIcon sx={{ margin: '0 8px' }} />
            Push result updates
          </MenuItem>
          <MenuItem
            sx={{ fontSize: '1.2rem', padding: '8px 3px' }}
            onClick={handlePushToLabTask}
          >
            <TaskIcon sx={{ margin: '0 8px' }} />
            Push order to lab
          </MenuItem>
          <MenuItem
            sx={{ fontSize: '1.2rem', padding: '8px 3px' }}
            onClick={handleKitReceivedTask}
          >
            <TaskIcon sx={{ margin: '0 8px' }} />
            Send kit received update
          </MenuItem>
        </Menu>
      </>
    }>
      <ContentCardShadowed>
        <ContentCardHeader
          title='Lab Order'
          action={
            <>

              <FormButton primary compact
                onClick={handleStatusMenu}
                size='small'
                endIcon={<DropdownIcon />}
              >
                Update
              </FormButton>
              <Menu
                id='menu-appbar'
                anchorEl={statusAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={statusMenuOpen}
                onClose={handleCloseStatusMenu}
              >
                <MenuItem
                  sx={{ fontSize: '1.2rem', padding: '8px' }}
                  onClick={markKitReceived}
                >
                  Mark Kit Received
                </MenuItem>

                <MenuItem
                  sx={{ fontSize: '1.2rem', padding: '8px' }}
                  onClick={markLabOrderRejected}
                >
                  Mark Rejected
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: '1.2rem', padding: '8px' }}
                  onClick={markLabOrderComplete}
                >
                  Mark Completed
                </MenuItem>
              </Menu>
            </>
          }
        />
        <ContentCardContent>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <DataList>
                <DataListItem label='Order Name' value={labKit.name} />
                <DataListItem label='Order Code' value={labOrder.labOrderCode} />
                <DataListItem
                  label='Registration Code'
                  value={kit ? kit.kitNumber : labOrder.kitNumber}
                />
                <DataListItem label='External ID' value={labOrder.externalId} />
              </DataList>
            </Grid>
            <Grid item xs={12} sm={6}>
              <DataList>
                <DataListItem
                  label='Registerd On'
                  value={DateTime.fromISO(labOrder.created).toLocaleString(
                    DateTime.DATETIME_SHORT
                  )}
                />
                <DataListItem
                  label='Last Updated On'
                  value={DateTime.fromISO(labOrder.updated).toLocaleString(
                    DateTime.DATETIME_SHORT
                  )}
                />
                <DataListItem
                  label='Kit Received On'
                  value={labOrder.kitReceivedOn && DateTime.fromISO(labOrder.kitReceivedOn).toLocaleString(
                    DateTime.DATETIME_SHORT
                  )}
                />
                <DataListItem label='Status' value={labOrder.status === 'Rejected' && results.length > 0 && results[0].rejectionCode.length > 0 ? `Rejected - ${results[0].rejectionCode} on ${DateTime.fromISO(results[0].updated).toLocaleString(DateTime.DATETIME_SHORT)}` : labOrder.status} />
              </DataList>
            </Grid>
          </Grid>
        </ContentCardContent>
      </ContentCardShadowed>
      <ContentCardShadowed group>
        <ContentCardHeader title='Patient'
          action={
            <>
              <FormButton primary compact onClick={handelPatient}>
                Edit
              </FormButton>
            </>
          }
        />
        <ContentCardContent>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <DataList>
                <DataListItem
                  label='Name'
                  value={`${patient.firstName} ${patient.middleName || ''} ${patient.lastName}`}
                />
                <DataListItem label='DOB' value={patient.dob} />
                <DataListItem label='Gender' value={patient.gender} />
              </DataList>
            </Grid>
            <Grid item xs={12} sm={6}>
              <DataList>
                <DataListItem label='Email' value={patient.email} />
                <DataListItem label='Phone' value={patient.phone} />
                <DataListItem label='Organization' value={organization.name} />
              </DataList>
            </Grid>
          </Grid>
        </ContentCardContent>
      </ContentCardShadowed>
      <ContentCardShadowed group>
        <ContentCardHeader
          title='Result(s)'
          action={
            <>
              <FormButton primary compact onClick={handleResult}>
                New Result
              </FormButton>
            </>
          }
        />
        <ContentCardContent>
          <ResultList results={results} handleResult={handleResult} />
        </ContentCardContent>
      </ContentCardShadowed>
      <ContentCardShadowed group>
        <ContentCardHeader title='Documents' />
        <ContentCardContent>
          {labOrder.reportFile || ''}
          {labOrder.reportFile &&
            <IconButton onClick={() => LabService.handleDownloadReportFile(id)}>
              <DownloadIcon />
            </IconButton>
          }
        </ContentCardContent>
      </ContentCardShadowed>
      {contentDrawer()}
    </SecondaryPage>
  );
};
export default View;
