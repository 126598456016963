import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import List from './List';
import Edit from './Edit';
import View from './View';
import {
  FilterBox,
  PageHeader,
  PageAction,
  PrimaryButton,
  SecondaryPage,
  PrimaryPage,
} from '@/Theme';
import { KitService } from '@/Services';
import { DropdownIcon, PrintIcon } from '@/Theme/Icons';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


const Home = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const printMenuOpen = Boolean(anchorEl);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(50);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('created');
  const [direction, setDirection] = useState('desc');

  const filter = {
    where: {},
    size: size,
    page: page,
    sort: sort,
    order: direction,
  };
  const fetch = async () => {
    setLoading(true);
    let response = await KitService.search(filter);
    if (response && response.data) {
      setListData(response.data);
      console.log('response.data', response.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    console.log('### fetch test types', search, size, page, sort, direction);
    filter.where = { freeText: search };
    fetch();
  }, [search, size, page, sort, direction]);

  const handleUnprinted = async () => {
    let query = { where: { unprinted: true }, size: 0 };
    let response = await await KitService.search(query);
    let kits = response.data;
    await printLables(kits);
  };

  const handleUnused = async () => {
    let query = { where: { unused: true }, size: 0 };
    let response = await await KitService.search(query);
    let kits = response.data;
    await printLables(kits);
  };

  const printLables = async (kits) => {
    setAnchorEl(null);
    await KitService.printLables(kits);
    fetch();
  };

  const onSearchChange = (e) => {
    e.preventDefault();
  };
  const onSearchPressed = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearch(e.target.value);
    }
  };
  const handleBatch = () => {
    navigate('/kit/batch');
  };
  const handlePrintMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePrintMenu = () => {
    setAnchorEl(null);
  };

  return (
    <PrimaryPage title='Kits'>
      <PageHeader>
        <PageAction>
          <FilterBox onChange={onSearchChange} onPress={onSearchPressed} />
        </PageAction>
        <PrimaryButton
          onClick={handlePrintMenu}
          size='small'
          endIcon={<DropdownIcon />}
        >
          Print
        </PrimaryButton>
        <Menu
          id='menu-appbar'
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={printMenuOpen}
          onClose={handleClosePrintMenu}
        >
          <MenuItem
            sx={{ fontSize: '1.2rem', padding: '8px 3px' }}
            onClick={handleUnprinted}
          >
            <PrintIcon sx={{ margin: '0 8px' }} />
            Print Labels for New Kits
          </MenuItem>
          <MenuItem
            sx={{ fontSize: '1.2rem', padding: '8px 3px' }}
            onClick={handleUnused}
          >
            <PrintIcon sx={{ margin: '0 8px' }} />
            Print Labels for Unused Kits
          </MenuItem>
        </Menu>
        <PrimaryButton onClick={handleBatch} size='small'>
          New Kits
        </PrimaryButton>
      </PageHeader>
      <List
        listData={listData}
        loading={loading}
        count={count}
        size={size}
        setSize={setSize}
        page={page}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
        direction={direction}
        setDirection={setDirection}
      />
    </PrimaryPage>
  );
};
export default Home;
