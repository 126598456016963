import {default as LocalAuth} from './local/auth-local';
import {default as LocalPrivateRoute} from './local/private';
import {default as ReduxAuth} from './redux/auth-redux';
import {default as ReduxPrivateRoute} from './redux/private';
import {default as ReduxReducer} from './redux/reducer';
import {AUTH_SESSION_STORE} from './redux/types';


const Local = {
  Auth: LocalAuth,
  Private: LocalPrivateRoute
};
const Redux = {
  Auth: ReduxAuth,
  Private: ReduxPrivateRoute,
  Reducer: {[AUTH_SESSION_STORE]: ReduxReducer},
}
//Default is using localstorage.  
export * from './local'

export {Local, Redux}
