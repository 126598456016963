import { SET_ROLE } from '../action/types';

const initialState = {
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ROLE:
      return action.role;
    default:
      return state;
  }
};
