import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import { SnackbarProvider } from 'notistack';

/**
 * Notifier
 */
const useStyles = makeStyles({
  success: { fontSize: '1.2rem' },
  error: { fontSize: '1.2rem' },
  warning: { fontSize: '1.2rem' },
  info: { fontSize: '1.2rem' },
});
const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};
const NotifyProvider = ({ children }) => {
  const classes = useStyles();
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
      ref={notistackRef}
      action={(key) => (
        <IconButton
          aria-label='close'
          color='inherit'
          onClick={onClickDismiss(key)}
        >
          <CloseIcon />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  );
};
export { NotifyProvider };
