const TOKEN = "___auth_token";
class LocalToken {
  get(key=TOKEN){
    key = this._getKey(key);
    let token = window.localStorage.getItem(key);
    token = token && JSON.parse(token);
    return token;
  }
  save(token, key=TOKEN){
    key = this._getKey(key);
    token = JSON.stringify(token);
    window.localStorage.setItem(key, token);
  }
  remove(token, key=TOKEN){
    key = this._getKey(key);
    window.localStorage.removeItem(key);
  }
  _getKey(key){
    return "local.auth." + key;
  }
}

export default new LocalToken();