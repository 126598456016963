import React from 'react';
import { Redux as WebAuth } from '@ihdlab/web-auth';
import Dashboard from '@/Components/Partner/Dashboard';
import Integrations from '@/Components/Partner/Integration';
import { Route, Routes } from 'react-router-dom';

const PrivateRoute = WebAuth.Private;
const Partner = () => {
  return (
    <Routes>
      <PrivateRoute exact path='/partner/dashboard' comp={Dashboard} />
      <PrivateRoute exact path='/partner/integration' comp={Integrations} />
    </Routes>
  );
};

export default Partner;

