import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import PanelIcon from '@mui/icons-material/Bookmarks';
import TestIcon from '@mui/icons-material/Bookmark';

import {
  SecondaryPage,
  DataTableStyles,
  EmptyData,
  LoadingData,
  DataList,
  DataListItem,
  ContentCardContent,
  ContentCardHeader,
  ContentCardShadowed,
  FormButton,
} from '@/Theme';
import { Grid, Avatar, IconButton } from '@mui/material';
/** Local imports */
import Edit from './Edit';
import { AdminSvc } from '@/Services';

const View = () => {
  const { id } = useParams();
  const [selectedItem, setSelectedItem] = useState({});
  const [subTest, setSubTest] = useState({});
  const [action, setAction] = useState(null);
  const [loading, setLoading] = useState(false);
  //for editing
  const [openDrawer, setOpenDrawer] = useState(false);

  const fetch = async () => {
    setLoading(true);
    let response = await AdminSvc.getTest(id);
    console.log('##### response', response);
    if (response && response.data) {
      setSelectedItem(response.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetch();
  }, [id]);

  useEffect(() => {
    fetch();
  }, [id]);
  const handleEdit = () => {
    setAction('edit');
    setOpenDrawer(true);
  };
  const handleAdd = () => {
    setAction('test');
    setSubTest({});
    setOpenDrawer(true);
  };
  const handleRowClick = (test) => {
    setAction('test');
    setSubTest(test);
    setOpenDrawer(true);
  };
  const refresh = () => {
    fetch();
  };
  const contentDrawer = () => {
    let drawerContent = '';
    if (openDrawer && action) {
      if (action === 'edit') {
        drawerContent = (
          <Edit
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            testType={selectedItem}
            success={refresh}
          />
        );
      } else if (action === 'test') {
        drawerContent = (
          <Edit
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            testType={subTest}
            panelId={selectedItem.id}
            success={refresh}
          />
        );
      }
    }
    return drawerContent;
  };

  const columns = useMemo(
    () => [
      {
        name: '',
        selector: row => row.name,
        sortable: true,
        width: '60px',
        cell: (row) => {
          return (
            <Avatar
              variant='rounded'
              style={{ backgroundColor: row.color, fontSize: '2rem' }}
            >
              <TestIcon fontSize='large' />
            </Avatar>
          );
        },
      },
      {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
        grow: 2,
      },
      {
        name: 'Friendly Name',
        selector: row => row.displayName,
        sortable: true,
        grow: 2,
      },
      {
        name: 'External ID',
        selector: row => row.externalId,
        sortable: true,
        grow: 2,
      },
      {
        name: 'Duration (minutes)',
        selector: row => row.duration,
        sortable: true,
      },
      {
        name: 'Weight (oz)',
        selector: row => row.weight,
        sortable: true,
        cell: (row) => {
          return row.weight;
        },
      },
      {
        name: 'WxHxL (inches)',
        sortable: true,
        grow: 2,
        cell: (row) => {
          return row.width + ' / ' + row.height + ' / ' + row.length;
        },
      },
      {
        name: 'Active',
        selector: row => row.active,
        sortable: true,
        width: '75px',
        cell: (row) => {
          return row.active ? 'Yes' : 'No';
        },
      },
    ],
    []
  );
  const title = () => {
    return (
      <>
        {selectedItem.isPanel ? (
          <PanelIcon fontSize='large' />
        ) : (
          <TestIcon fontSize='large' />
        )}
        <span style={{ marginLeft: '1rem' }}>{selectedItem.name}</span>
      </>
    );
  };
  return (
    <SecondaryPage title={title()} back='/admin/test'>
      <ContentCardShadowed>
        <ContentCardHeader
          title='General Info'
          action={
            <FormButton primary compact onClick={handleEdit}>
              Edit
            </FormButton>
          }
        />
        <ContentCardContent>
          <Grid container>
            <Grid item xs={6}>
              <DataList>
                <DataListItem label='Name' value={selectedItem.name} />
                <DataListItem
                  label='Description'
                  value={selectedItem.description}
                />
                {/* <DataListItem
                  label='Patient Test Name'
                  value={selectedItem.displayName}
                />
                <DataListItem
                  label='Patient Test Description'
                  value={selectedItem.displayDescription}
                /> */}
                <DataListItem
                  label='Active'
                  value={selectedItem.active ? 'Yes' : 'No'}
                />
                <DataListItem
                  label='External Id'
                  value={selectedItem.externalId}
                />
                <DataListItem
                  label='Parcel Id'
                  value={selectedItem.epParcelId}
                />
              </DataList>
            </Grid>
            <Grid item xs={6}>
              <DataList>
                <DataListItem label='Category' value={selectedItem.category} />
                <DataListItem
                  label='Panel'
                  value={
                    (selectedItem.isPanel ? 'Yes' : 'No') +
                    (selectedItem.panelId
                      ? ' (Panel ID: ' + selectedItem.panelId + ') '
                      : '')
                  }
                />
                <DataListItem
                  label='Duration (minutes)'
                  value={selectedItem.duration}
                />
                <DataListItem
                  label='Result Ready Time (minutes)'
                  value={selectedItem.resultReady}
                />
                <DataListItem
                  label='Result Range From'
                  value={selectedItem.resultRangeFrom}
                />
                <DataListItem
                  label='Result Range To'
                  value={selectedItem.resultRangeTo}
                />
              </DataList>
            </Grid>
          </Grid>
        </ContentCardContent>
        <ContentCardHeader title='Kit Detail' />
        <ContentCardContent>
          <Grid container>
            <Grid item xs={6}>
              <DataList>
                <DataListItem
                  label='Length (inches)'
                  value={selectedItem.length}
                />
                <DataListItem
                  label='Width (inches)'
                  value={selectedItem.width}
                />
              </DataList>
            </Grid>
            <Grid item xs={6}>
              <DataList>
                <DataListItem
                  label='Height (inches)'
                  value={selectedItem.height}
                />
                <DataListItem label='Weight (oz)' value={selectedItem.weight} />
              </DataList>
            </Grid>
          </Grid>
        </ContentCardContent>
      </ContentCardShadowed>
      {selectedItem.isPanel && (
        <ContentCardShadowed group>
          <ContentCardHeader
            title='Tests'
            action={
              <FormButton primary compact onClick={handleAdd}>
                Add new test
              </FormButton>
            }
          />
          <ContentCardContent>
            <DataTable
              className='pagination-custom'
              columns={columns}
              data={selectedItem.testTypes}
              noHeader
              onRowClicked={handleRowClick}
              highlightOnHover
              customStyles={DataTableStyles}
              persistTableHead
              defaultSortField='name'
              defaultSortAsc={false}
            />
          </ContentCardContent>
        </ContentCardShadowed>
      )}
      {contentDrawer()}
    </SecondaryPage>
  );
};
export default View;
