import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import List from './List';
import Edit from './Edit';
import View from './View';
import {
  FilterBox,
  PageHeader,
  PageAction,
  PrimaryButton,
  SecondaryPage,
  PrimaryPage,
} from '@/Theme';
import { CustomerService } from '@/Services';

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [filter, setFilter] = useState({ search: "" });
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(50);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('updated');
  const [direction, setDirection] = useState('desc');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawer, setDrawer] = useState('');
  const [selectedItem, setSelectedItem] = useState({});

  const filters = {
    where: {},
    size: size,
    page: page,
    sort: sort,
    order: direction,
  };
  const fetch = async () => {
    if (!listData.length) {
      setLoading(true);
    }
    filters.where = filter;
    let response = await CustomerService.search(filters);
    if (response && response.data && response.data.objects) {
      const objects = response.data.objects;
      setListData(objects);
      setCount(response.data.count);
    }
    setLoading(false);
  };
  useEffect(() => {
    console.log('### Fetch Customers', search, size, page, sort, direction);
    filter.where = { freeText: search };
    fetch();
  }, [filter, size, page, sort, direction]);

  const onSearchChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };
  const onSearchPressed = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let updatedFilter = { ...filter, ...{ search: e.target.value } };
      setFilter(updatedFilter);
    }
  };
  const handleView = (item) => {
    setSelectedItem(item);
    setDrawer('view');
    setOpenDrawer(true);
  };
  const handleEdit = (item) => {
    //alert('handle edit');
    setSelectedItem(item);
    setDrawer('new');
    setOpenDrawer(true);
  };
  const handleNew = () => {
    setSelectedItem({});
    setDrawer('new');
    setOpenDrawer(true);
  };
  const contentDrawer = () => {
    let drawerContent = '';
    if (openDrawer) {
      if (drawer === 'new') {
        drawerContent = (
          <Edit
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            selectedItem={selectedItem}
            refreshList={fetch}
            inputVariables={filter}
          />
        );
      } else if (drawer === 'view') {
        drawerContent = (
          <View
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            selectedItem={selectedItem}
            refreshList={fetch}
            inputVariables={filter}
          />
        );
      }
    }
    return drawerContent;
  };
  return (
    <PrimaryPage title='Customers'>
      <PageHeader>
        <PageAction>
          <FilterBox value={search} onChange={onSearchChange} onPress={onSearchPressed} />
        </PageAction>
        {/* <PrimaryButton onClick={handleNew}>
					New Customer
				</PrimaryButton> */}
      </PageHeader>
      <List
        listData={listData}
        setOpenDrawer={setOpenDrawer}
        handleView={handleView}
        handleEdit={handleEdit}
        loading={loading}
        count={count}
        size={size}
        setSize={setSize}
        page={page}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
        direction={direction}
        setDirection={setDirection}
      />
      {contentDrawer()}
    </PrimaryPage>
  );
};
export default Home;
