import React from 'react';
import styled from 'styled-components';

import {
  AppBar,
  Avatar,
  BottomNavigationAction,
  BottomNavigation,
  Container,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Grid,
  Step,
} from '@mui/material';

const Nav = styled(AppBar)`
  && {
    background: #ffffff;
  }
`;
const NavButton = styled(IconButton)`
  && {
    // align-items:baseline;
    .MuiSvgIcon-root {
      color: #2a2277;
    }
    #primary-menu-opener {
      font-size: 1.8rem;
      margin-right: 1rem;
    }
    &:hover .MuiSvgIcon-root {
      color: #ef599d;
    }
    &:hover {
      background: none;
    }
  }
`;
const NavIcon = styled(({ icon, primary, ...props }) =>
  React.cloneElement(icon, props)
)`
  && {
    ${(props) => (props.w ? `width:` + props.w + `;` : ``)}
    ${(props) => (props.h ? `height:` + props.h + `;` : ``)}
		font-size: ${(props) => (props.primary ? '4rem' : '2.4rem')};
`;
const MainMenuDrawer = styled(({ width, ...otherProps }) => (
  <Drawer {...otherProps} />
))`
  && {
    .MuiDrawer-paper {
      width: ${(props) => `${props.width}` || 'auto'};
    }
  }
`;
const MainMenuHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 0 0 1rem;
`;
const MainMenuContent = styled.div``;
const MainMenu = styled(BottomNavigation)`
  && {
    justify-content: flex-start;
    .MuiBottomNavigationAction-root {
      flex: 0;
      padding: 1.8rem;
      min-width: 8.8rem;
    }
  }
`;
const MainMenuItem = styled(BottomNavigationAction)`
  && {
    color: #2a2277;
    text-align: center;
    &:hover,
    &:hover svg {
      color: #ef599d;
    }
    .Mui-selected {
      color: #ef599d;
    }
    &.Mui-selected svg {
      color: #ef599d;
    }
    .MuiBottomNavigationAction-label {
      font-size: 1.2rem;
    }
  }
`;
const MainMenuFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem;
  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  div h6 {
    margin-left: 0.5rem;
  }
`;

const AccountAvatar = styled(Avatar)`
  && {
    width: 30px;
    height: 30px;
    text-transform: uppercase;
    background: #2a2277;
    &:hover {
      background: #ef599d;
    }
  }
`;
const AccountMenu = styled(Menu)`
  &&{
    .MuiList-padding{
      padding: 0;
    }
  }
}`;
const AccountMenuItem = styled(MenuItem)`
  && {
    &:hover,
    &:hover svg {
      color: #ef599d;
    }
    .MuiSvgIcon-root {
      font-size: 2rem;
    }
    .MuiListItemIcon-root {
      min-width: 3.4rem;
    }
    .MuiListItemText-root .MuiTypography-body1 {
      font-size: 1.6rem;
    }
  }
`;

const MainContent = styled(Container)`
  && {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 20px;
    padding: 2rem;
  }
`;

const FixedGrid = styled(Grid)`
  &&{
    max-height: calc(100% - 60px);
    max-height: calc(100vh - 60px);
    min-height: calc(100% - 60px);
    min-height: calc(100vh - 60px);
    overflow: auto;
  }
`;
const FancyStep = styled(Step)`
  &&{
    .MuiStepIcon-root{
      font-size: 2.6rem;
    }
    .MuiStepIcon-text{
      font-size:1.1rem;
    }
  }
`;

export {
  Nav,
  NavButton,
  NavIcon,
  MainMenuDrawer,
  MainMenuHeader,
  MainMenuContent,
  MainMenu,
  MainMenuItem,
  MainMenuFooter,
  AccountMenu,
  AccountAvatar,
  AccountMenuItem,
  MainContent,
  FixedGrid,
  FancyStep
};
