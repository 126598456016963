import React, { useEffect, useState } from 'react';
import List from './List';
import { MenuItem } from '@mui/material';
import {
  FilterBox,
  PageHeader,
  PageAction,
  SelectFilterField,
  FormButton,
  SecondaryPage,
} from '@/Theme';
import { AdminSvc } from '@/Services';
import RequestDetail from './RequestDetail';

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [filter, setFilter] = useState({ search: "", status: ["Failed"] });
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState(['Failed']);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(50);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('created');
  const [direction, setDirection] = useState('desc');
  //For editing
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState({});

  const filters = {
    where: {},
    size: size,
    page: page,
    sort: sort,
    order: direction,
  };
  const fetch = async () => {
    setLoading(true);
    filters.where = filter;
    let response = await AdminSvc.searchAPIRequests(filters);
    if (response && response.data && response.data.objects) {
      const objects = response.data.objects;
      setListData(objects);
      setCount(response.data.count);
    }
    setLoading(false);
  };
  useEffect(() => {
    console.log('### fetch test types', search, size, page, sort, direction);
    fetch();
  }, [filter, size, page, sort, direction]);

  const onSearchChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };
  const onSearchPressed = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let updatedFilter = {
        ...filter,
        ...{ search: search },
        ...{ status: status },
      }
      setFilter(updatedFilter);
    }
  };
  const handleApplyFilter = () => {
    let updatedFilter = {
      ...filter,
      ...{ search: search },
      ...{ status: status },
    }
    setFilter(updatedFilter);
  }
  const onStatusSelect = (event) => {
    const { target: { value } } = event;
    setStatus(typeof value === 'string' ? value.split(',') : value,);
  }
  const handleViewRequest = (request) => {
    setSelectedRequest(request);
    setOpenDrawer(true);
  };
  const contentDrawer = () => {
    let drawerContent = '';
    if (openDrawer) {
      drawerContent = (
        <RequestDetail
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          selectedRequest={selectedRequest}
        />
      );
    }
    return drawerContent;
  };
  return (
    <SecondaryPage title='API Requests' back='/admin/dashboard'>
      <PageHeader>
        <PageAction>
          <FilterBox
            onChange={onSearchChange} onPress={onSearchPressed}
            extend={
              <>
                <SelectFilterField
                  labelId="filter-status-label"
                  id="filter-status-id"
                  value={status}
                  multiple
                  onChange={onStatusSelect}
                >
                  <MenuItem value="Failed">Failed</MenuItem>
                  <MenuItem value="Success">Success</MenuItem>
                </SelectFilterField>
                <FormButton compact primary onClick={handleApplyFilter}>Filter</FormButton>
              </>
            }
          />
        </PageAction>
      </PageHeader>
      <List
        listData={listData}
        setOpenDrawer={setOpenDrawer}
        loading={loading}
        handleViewRequest={handleViewRequest}
        count={count}
        size={size}
        setSize={setSize}
        page={page}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
        direction={direction}
        setDirection={setDirection}
        refreshData={fetch}
      />
      {contentDrawer()}
    </SecondaryPage>
  );
};
export default Home;
