import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from 'notistack';
import { AdminSvc } from '@/Services';

import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  RightDrawer,
  DrawerForm,
  FormButton,
  InputField,
  DrawerCardContent,
  DrawerCardActions,
  FormTextField,
  FormMenuItem
} from '@/Theme';

const NewAPIAccess = ({ openDrawer, setOpenDrawer, selectedItem, success }) => {
  console.log('### Selected item for edit: ', selectedItem);
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const handleClose = () => {
    setOpenDrawer(false);
    success();
  };
  const initValues = () => {
    return {
      organizationId: selectedItem.id,
      event: '',
      headers: '',
      method: '',
      url: '',
      production: true,
      active: true,
    };
  };
  const form = () => {
    return (
      <Formik
        initialValues={initValues()}
        onSubmit={async (values, { setSubmitting }) => {
          console.log('##### save', values);
          let savedWebhook = await AdminSvc.saveWebhook(
            selectedItem.id,
            {
              organizationId: selectedItem.id,
              event: values.event,
              headers: values.headers,
              method: values.method,
              url: values.url,
              production: values.production,
              active: true,
            }
          );
          if (savedWebhook && !savedWebhook.error) {
            enqueueSnackbar('New webhook created.', { variant: 'success' });
            handleClose();
          } else {
            enqueueSnackbar('Failed to create webhook.', {
              variant: 'error',
            });
          }
        }}
      >
        {(props) => {
          const { values, handleSubmit, setFieldValue, touched, errors, handleChange} = props;
          const handleChecked = (value) => {
            setFieldValue('production', value);
          };
          return (
            <DrawerForm onSubmit={handleSubmit} autoComplete='nope'>
              <DrawerCardContent>
                <FormTextField
                  select
                  width='200px'
                  required
                  name='event'
                  value={values.event}
                  placeholder="Select an event"
                  label={(touched.event && errors.event) ? errors.event : 'Event'}
                  error={touched.event && Boolean(errors.event)}
                  onChange={handleChange}
                >
                  <FormMenuItem value=''>Select Event</FormMenuItem>
                    <FormMenuItem key="Kit Received" value="Kit Received">
                      Kit Received
                    </FormMenuItem>
                    <FormMenuItem key="Result Update" value="Result Update">
                      Result Update
                    </FormMenuItem>
                </FormTextField>
                <InputField
                  field='headers'
                  label='Headers(JSON)'
                  multiline
                  rows={4}
                  props={props}
                />
                <InputField
                  field='method'
                  label='HTTP Method'
                  props={props}
                  required
                />
                <InputField
                  field='url'
                  label='URL'
                  props={props}
                  required
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name='production'
                      value={values.production}
                      checked={values.production}
                      onChange={() => handleChecked(!values.production)}
                    />
                  }
                  label='Production'
                />
              </DrawerCardContent>
              <DrawerCardActions>
                <FormButton primary type='submit'>
                  Submit
                </FormButton>
                <FormButton onClick={handleClose}>Cancel</FormButton>
              </DrawerCardActions>
            </DrawerForm>
          );
        }}
      </Formik>
    );
  };
  return (
    <div>
      <RightDrawer
        title='Create New API Access Key'
        handleClose={handleClose}
        open={openDrawer}
      >
        {form()}
      </RightDrawer>
    </div>
  );
};
export default NewAPIAccess;
