/** PACKAGE IMPORTS */
import React, { useEffect } from 'react';
import { Route, useNavigate } from 'react-router-dom';

import { PrimaryPage, ContentCardShadowed, NavListItem } from '@/Theme';
import { Redux as WebAuth } from '@ihdlab/web-auth';
import {
  Grid,
  ListItemIcon,
  List,
  ListItemText,
  CardHeader,
  CardContent,
} from '@mui/material';
import UsersIcon from '@mui/icons-material/People';
import KitsIcon from '@mui/icons-material/MedicalServices';
import PanelIcon from '@mui/icons-material/Bookmarks';
import TestIcon from '@mui/icons-material/Bookmark';
import SpecimenIcon from '@mui/icons-material/Opacity';
import PartnersIcon from '@mui/icons-material/Business';
import CodesIcon from '@mui/icons-material/Copyright';
import TasksIcon from '@mui/icons-material/Rule';
import APIIcon from '@mui/icons-material/Webhook';
import NotificationsIcon from '@mui/icons-material/Notifications';
import WixIntegrationIcon from '@mui/icons-material/IntegrationInstructions';
import { UserSvc } from '@/Services';

const Auth = WebAuth.Auth;
const PrivateRoute = WebAuth.Private;

const Dashboard = () => {
  const navigate = useNavigate();
  const token = Auth.getToken();
  const isLoggedIn = Auth.isLoggedIn();
  const user = UserSvc.me();
  return (
    <>
      <PrimaryPage title='Adminsitration'>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs={12} sm={6} md={4}>
            <ContentCardShadowed style={{ height: '100%' }}>
              <CardHeader title="Account Management" />
              <CardContent>
                <List disablePadding>
                  <NavListItem
                    button
                    onClick={() => navigate('/admin/user')}
                  >
                    <ListItemIcon>
                      <UsersIcon />
                    </ListItemIcon>
                    <ListItemText primary='Users' secondary='Manage users' />
                  </NavListItem>
                  <NavListItem
                    button
                    onClick={() => navigate('/admin/organization')}
                  >
                    <ListItemIcon>
                      <PartnersIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary='Organizations'
                      secondary='Manage organizations'
                    />
                  </NavListItem>
                </List>
              </CardContent>
            </ContentCardShadowed>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ContentCardShadowed style={{ height: '100%' }}>
              <CardHeader title="Lab Setup" />
              <CardContent>
                <List disablePadding>
                  <NavListItem
                    button
                    onClick={() => navigate('/admin/kit')}
                  >
                    <ListItemIcon>
                      <KitsIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary='Lab Kits'
                      secondary='Manage lab kits, specs'
                    />
                  </NavListItem>
                  <NavListItem
                    button
                    onClick={() => navigate('/admin/panel')}
                  >
                    <ListItemIcon>
                      <PanelIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary='Panels'
                      secondary='Manage test panels'
                    />
                  </NavListItem>
                  <NavListItem
                    button
                    onClick={() => navigate('/admin/test')}
                  >
                    <ListItemIcon>
                      <TestIcon />
                    </ListItemIcon>
                    <ListItemText primary='Tests' secondary='Manage tests' />
                  </NavListItem>
                  <NavListItem
                    button
                    onClick={() => navigate('/admin/specimen')}
                  >
                    <ListItemIcon>
                      <SpecimenIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary='Specimens'
                      secondary='Manage specimens'
                    />
                  </NavListItem>
                  <NavListItem
                    button
                    onClick={() => navigate('/admin/icdcode')}
                  >
                    <ListItemIcon>
                      <CodesIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary='ICD Codes'
                      secondary='Manage ICD codes'
                    />
                  </NavListItem>
                </List>
              </CardContent>
            </ContentCardShadowed>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ContentCardShadowed style={{ height: '100%' }}>
              <CardHeader title="System Operation" />
              <CardContent>
                <List disablePadding>
                  <NavListItem
                    button
                    onClick={() => navigate('/admin/task')}
                  >
                    <ListItemIcon>
                      <TasksIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary='Lab Order Tasks'
                      secondary='Manage lab order tasks'
                    />
                  </NavListItem>
                  <NavListItem
                    button
                    onClick={() => navigate('/admin/api')}
                  >
                    <ListItemIcon>
                      <APIIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary='API Accesses'
                      secondary='Monitor API accesses'
                    />
                  </NavListItem>
                  <NavListItem
                    button
                    onClick={() => navigate('/admin/notification')}
                  >
                    <ListItemIcon>
                      <NotificationsIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary='Notifications'
                      secondary='Monitor notifications'
                    />
                  </NavListItem>
                  <NavListItem
                    button
                    onClick={() => navigate('/admin/wix')}
                  >
                    <ListItemIcon>
                      <WixIntegrationIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary='Wix'
                      secondary='Manage wix integration'
                    />
                  </NavListItem>
                </List>
              </CardContent>
            </ContentCardShadowed>
          </Grid>
        </Grid>
      </PrimaryPage>
    </>
  );
};
export default Dashboard;
