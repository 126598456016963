/** PACKAGE IMPORTS */
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { DataTableStyles, EmptyData, LoadingData, FormButton } from '@/Theme';
import { Avatar, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { InlineActionButton } from './Style';
import { DateTime } from 'luxon';
import { LabService } from '@/Services';

const List = ({
  listData,
  loading,
  handlePushResults,
  count,
  size,
  setSize,
  page,
  setPage,
  setSort,
  setDirection,
}) => {
  const navigate = useNavigate();
  const handleRowClick = (order) => {
    let link = `/lab/${order.id}`;
    navigate(link);
  };


  const columns = useMemo(
    () => [
      {
        name: 'Registration Code',
        selector: row => row.kitNumber,
        sortable: true,
        sortField: 'kitNumber',
        grow: 1,
      },
      {
        name: 'External ID',
        selector: row => row.externalId,
        sortable: true,
        sortField: 'externalId',
        grow: 1,
      },
      {
        name: 'Code',
        selector: row => row.labOrderCode,
        sortable: true,
        sortField: "labOrderCode",
        grow: 1,
      },
      {
        name: 'Lab Kit',
        selector: row => row.labKit ? row.labKit.name : "",
        sortable: true,
        sortField: "labKit.name",
        grow: 1,
      },
      {
        name: 'Patient',
        sortable: true,
        sortField: "patient.firstName",
        grow: 1,
        cell: (row) => {
          return `${row.patient.firstName} ${row.patient.lastName}`;
        },
      },
      {
        name: 'Status',
        selector: row => row.status,
        sortField: "status",
        sortable: true,
      },
      {
        name: 'Last Update',
        sortable: true,
        sortField: "updated",
        cell: (row) => {
          return `${DateTime.fromISO(row.updated).toLocaleString(
            DateTime.DATETIME_SHORT
          )}`;
        },
      },
    ],
    []
  );
  const handlePageChange = (p) => {
    console.log('### handlePageChange', page, '--', p);
    setPage(p);
  };
  const handlePerRowsChange = (perPage, p) => {
    console.log('### handleRowsChange', perPage, p);
    setSize(perPage);
    setPage(p);
  };
  const handleSort = (sort, direction) => {
    console.log('### handleSort', sort, direction);
    setSort(sort.sortField);
    setDirection(direction);
  };
  return (
    <>
      {loading && listData.length === 0 && <LoadingData />}
      {listData.length > 0 && <DataTable
        className='pagination-custom'
        columns={columns}
        data={listData}
        noHeader
        onRowClicked={handleRowClick}
        highlightOnHover
        pointerOnHover
        customStyles={DataTableStyles}
        paginationDefaultPage={page}
        persistTableHead
        paginationPerPage={size}
        paginationRowsPerPageOptions={[10, 15, 25, 50, 100, 200, 500]}
        pagination
        paginationServer
        paginationTotalRows={count}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        defaultSortField='created'
        defaultSortAsc={false}
        onSort={handleSort}
        sortServer
      />}
      {!loading && !listData.length && <EmptyData entity='orders' />}
    </>
  );
};
export default List;
