import BaseSvc from './base-service';

class PatientService {
  constructor() {
    this.authConfig = {};
    if (!PatientService.instance) {
      this.authConfig = {};
      PatientService.instance = this;
    }
    return PatientService.instance;
  }

  async search(filter) {
    console.log('#### PatientService#search');
    let response = await BaseSvc.secureGet('/patient', filter);
    return response;
  }

  async save(data) {
    console.log('#### PatientService#save', data);
    let response = await BaseSvc.securePost('/patient', data);
    return response.data;
  }

  async getPatient(id) {
    console.log('#### PatientService#getPatient', id);
    let response = await BaseSvc.secureGet('/patient/' + id);
    console.log('#### PatientService#getPatient', response, response.data);
    return response;
  }
}

const instance = new PatientService();
Object.freeze(instance);
export default instance;
