import BaseSvc from './base-service';

/**
 * Lab Order Service
 */
class LabService {
  constructor() {
    this.authConfig = {};
    if (!LabService.instance) {
      this.authConfig = {};
      LabService.instance = this;
    }
    return LabService.instance;
  }

  async search(filter) {
    console.log('#### LabService#search');
    let response = await BaseSvc.secureGet('/lab/order', filter);
    return response;
  }

  async save(data) {
    let response = await BaseSvc.securePost('/lab/order', data);
    return response.data;
  }

  async markKitReceived(id) {
    let response = await BaseSvc.securePost(`/lab/order/${id}/received`);
    return response.data;
  }

  async markLabOrderRejected(id) {
    let response = await BaseSvc.securePost(`/lab/order/${id}/rejected`);
    return response.data;
  }

  async markLabOrderComplete(id) {
    let response = await BaseSvc.securePost(`/lab/order/${id}/completed`);
    return response.data;
  }

  async get(orderId) {
    let response = await BaseSvc.secureGet('/lab/order/' + orderId);
    return response;
  }

  async handleDownloadReportFile(orderId) {
    let response = await BaseSvc.secureGet(`/lab/order/${orderId}/download`);
    if (response && response.data) {
      window.open(response.data, '_blank');
    }
  }

  async getFields() {
    let response = await BaseSvc.secureGet('/lab/fields');
    return response.data;
  }

  async register(data) {
    let response = await BaseSvc.securePost('/lab/order/register', data);
    return response.data;
  }

  async pullResults() {
    let response = await BaseSvc.securePost('/lab/pull-labdaq-results');
    return response.data;
  }

  async pushResults(testOrderId) {
    let response = await BaseSvc.securePost('/lab/results', { testOrderId });
    return response.data;
  }

  async pullPartnerOrder(lookup, partner) {
    console.log('#### OrderService#getOrder', lookup);
    let response = await BaseSvc.secureGet(
      '/lab/partner/order/pull/' + lookup + "?orgCode=partner"
    );
    return response;
  }

  async getLabResultFields(labOrderId) {
    let response = await BaseSvc.secureGet(
      '/lab/order/action/result/' + labOrderId
    );
    return response;
  }

  async saveResult(result) {
    let response = await BaseSvc.securePost(`/lab/order/result`, result);
    return response;
  }

  async deleteResult(result) {
    let response = await BaseSvc.secureDelete(`/lab/order/result/${result.id}`);
    return response;
  }

  async pushLabOrder(labOrderCode) {
    let response = await BaseSvc.post('/lab/processing/push', {
      code: labOrderCode,
    });
    return response;
  }

  async pushResult(orderId) {
    let response = await BaseSvc.secureGet(
      `/lab/partner/order/result/${orderId}`
    );
    return response;
  }
}

const instance = new LabService();
Object.freeze(instance);
export default instance;
