import BaseSvc from './base-service';

class AdminService {
  constructor() {
    this.authConfig = {};
    if (!AdminService.instance) {
      this.authConfig = {};
      AdminService.instance = this;
    }
    return AdminService.instance;
  }

  async searchKits(filter) {
    console.log('#### AdminService#searchKits');
    return BaseSvc.secureGet('/admin/kit', filter);
  }
  
  async listKits(orgId) {
    console.log('#### AdminService#listKits');
    return BaseSvc.secureGet('/admin/kit/list', {orgId: orgId});
  }

  async getKit(id) {
    console.log('#### AdminService#getKit');
    return BaseSvc.secureGet('/admin/kit/' + id);
  }

  async saveKit(data) {
    console.log('#### AdminService#saveKit', data);
    return BaseSvc.securePost('/admin/kit', data);
  }

  async searchPanels(filter) {
    console.log('#### AdminService#searchPanels');
    return BaseSvc.secureGet('/admin/panel', filter);
  }

  async listPanels() {
    console.log('#### AdminService#listPanels');
    return BaseSvc.secureGet('/admin/panel/list');
  }

  async getPanel(id) {
    console.log('#### AdminService#getPanel');
    return BaseSvc.secureGet('/admin/panel/' + id);
  }

  async savePanel(data) {
    console.log('#### AdminService#savePanel', data);
    return BaseSvc.securePost('/admin/panel', data);
  }

  async addPanelsToKit(kitId, panels) {
    console.log('#### AdminService#addPanelsToKit', panels);
    return BaseSvc.securePost('/admin/kit/' + kitId + '/panels', panels);
  }

  async addTestsToPanel(panelId, tests) {
    console.log('#### AdminService#addTestsToPanel', tests);
    return BaseSvc.securePost('/admin/panel/' + panelId + '/tests', tests);
  }

  async removeTestFromPanel(panelId, testId) {
    console.log('#### AdminService#removeTestFromPanel', panelId, testId);
    return BaseSvc.secureDelete(`/admin/panel/${panelId}/test/${testId}`);
  }

  async searchTests(filter) {
    console.log('#### AdminService#searchTests');
    return BaseSvc.secureGet('/admin/test', filter);
  } 

  async listTests() {
    console.log('#### AdminService#listTests');
    return BaseSvc.secureGet('/admin/test/list');
  }

  async getTest(id) {
    console.log('#### AdminService#getTest');
    let response = await BaseSvc.secureGet('/admin/test/' + id);
    return response;
  }

  async saveTest(data) {
    console.log('#### AdminService#saveTest', data);
    let response = await BaseSvc.securePost('/admin/test', data);
    return response.data;
  }

  async searchSpecimens(where, size, page, sort, order) {
    console.log('#### AdminService#searchSpecimens');
    let response = await BaseSvc.secureGet('/admin/specimen');
    return response;
  }

  async saveSpecimen(data) {
    console.log('#### AdminService#saveSpecimen', data);
    let response = await BaseSvc.securePost('/admin/specimen', data);
    return response.data;
  }

  async saveSpecimenType(data) {
    console.log('#### AdminService#saveSpecimenType', data);
    let response = await BaseSvc.securePost('/admin/specimentype', data);
    console.log('##### savedSpecimenType', response);
    return response.data;
  }

  async searchICDCodes(where, size, page, sort, order) {
    console.log('#### AdminService#searchSpecimens');
    let response = await BaseSvc.secureGet('/admin/icdcode');
    return response;
  }
  
  async saveICDCode(data) {
    console.log('#### AdminService#saveICDCode', data);
    let response = await BaseSvc.securePost('/admin/icdcode', data);
    console.log('##### saveICDCode', response);
    return response.data;
  }

  async savePatient(data) {
    console.log('#### AdminService#savePatient', data);
    let response = await BaseSvc.securePost('/admin/patient', data);
    return response.data;
  }

  async searchOrganizations(where, size, page, sort, order) {
    console.log('#### AdminService#searchOrganizations');
    return BaseSvc.secureGet('/admin/organization');
  }

  async saveOrganization(data) {
    console.log('#### AdminService#saveOrganization', data);
    let response = await BaseSvc.securePost('/admin/organization', data);
    return response.data;
  }

  async createNewAPIAccess(organizationId, data) {
    console.log('#### AdminService#createNewAPIAccess', data);
    let response = await BaseSvc.securePost(
      '/admin/organization/' + organizationId + '/api',
      data
    );
    console.log('##### savedAPIAccess', response);
    return response.data;
  }

  async saveWebhook(organizationId, data) {
    console.log('#### AdminService#createNewAPIAccess', data);
    let response = await BaseSvc.securePost(
      '/admin/organization/' + organizationId + '/webhook',
      data
    );
    console.log('##### savedWebhook', response);
    return response.data;
  }
  
  async deleteWebhook(webhook) {
    console.log('#### AdminService#deleteWebhook');
    let response = await BaseSvc.secureDelete(`/admin/organization/${webhook.organizationId}/webhook/${webhook.id}`);
    return response;
  }

  async getOrganization(id) {
    console.log('#### AdminService#getOrganization');
    let response = await BaseSvc.secureGet('/admin/organization/' + id);
    console.log('#### list', response, response.data);
    return response;
  }

  async uploadLogo(name, file) {
    console.log('#### AdminService#uploadLogo');
    let data = new FormData()
    data.append('name', name)
    data.append('file', file)
    let response = await BaseSvc.securePut('/admin/organization/logo', data);
    console.log('#### list', response, response.data);
    return response;
  }

  async getPartner(id) {
    console.log('#### AdminService#getPartner');
    let response = await BaseSvc.secureGet('/admin/partner/' + id);
    console.log('#### list', response, response.data);
    return response;
  }

  async searchAPIRequests(filter) {
    console.log('#### AdminService#searchAPIRequests');
    return BaseSvc.secureGet('/admin/request', filter);
  }
  
  async searchNotifications(filter) {
    console.log('#### AdminService#searchNotifications');
    return BaseSvc.secureGet('/admin/notification', filter);
  }

  /** Wix services */
  async searchWixLogs(filter) {
    console.log('#### AdminService#searchWixLogs');
    return BaseSvc.secureGet('/wix/log', filter);
  }

  async deleteWixLog(log) {
    console.log('#### AdminService#deleteWixLog');
    let response = await BaseSvc.secureDelete(`/wix/log/${log.id}`);
    return response;
  }

  async pullWixOrders(){
    console.log('#### AdminService#pullWixOrders');
    let response = await BaseSvc.get(`/wix/order/pull`);
    return response;
  }

  /** Lab Task services */
  async searchTasks(filter) {
    console.log('#### AdminService#searchWixLogs');
    return BaseSvc.secureGet('/lab/task', filter);
  }

  async createTask(id, type) {
    console.log('#### AdminService#searchTasks');
    let response = await BaseSvc.securePost('/lab/task/' + id + "/new/" + type);
    return response.data;
  }

  async deleteTask(task) {
    console.log('#### AdminService#deleteTask');
    let response = await BaseSvc.secureDelete(`/lab/task/${task.id}`);
    return response;
  }

  async pushResults(){
    let response = await BaseSvc.get(`/lab/partner/order/result`);
    return response;
  }

  async pullOrders(){
    let response = await BaseSvc.get(`/lab/partner/order/pull`);
    return response;
  }

  async pushToLab(){
    let response = await BaseSvc.get(`/lab/processing/push`);
    return response;
  }
  
  async pullFromLab(){
    let response = await BaseSvc.get(`/lab/processing/pull`);
    return response;
  }
  
  async updateKitStatus(){
    let response = await BaseSvc.get(`/lab/partner/order/kit`);
    return response;
  }

}

const instance = new AdminService();
Object.freeze(instance);
export default instance;
