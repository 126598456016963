import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserSvc } from '@/Services'
import { useSnackbar } from 'notistack';
import {
  Formik,
  Form,
  useField,
  Field,
  ErrorMessage,
  validateYupSchema
} from 'formik';
import * as Yup from 'yup';
import {
  RightDrawer,
  FormButton,
  FormTextField,
  DrawerCardContent,
  DrawerCardActions,
  FormMenuItem
} from '@/Theme';
import useWindowDimension from '@/Utils/window';

const UserDrawer = ({
  openDrawer,
  setOpenDrawer,
  selectedUser,
  roles,
  statuses,
  refreshUsers,
  inputVariables,
}) => {
  console.log('### Selected user for edit: ', selectedUser);
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // const sessionRole = Session.getRole();
  const navigate = useNavigate();
  const { height } = useWindowDimension();
  const handleClose = () => {
    setOpenDrawer(false);
  };
  const initValues = () => {
    return {
      id: selectedUser.id,
      first_name: selectedUser.first_name || '',
      last_name: selectedUser.last_name || '',
      email: selectedUser.email || '',
      role: selectedUser.role || '',
      status: selectedUser.status || ''
    };
  };
  const form = () => {
    console.log('### Setup form with selected user', selectedUser);
    return (
      <Formik
        initialValues={initValues()}
        validationSchema={Yup.object().shape({
          email: Yup.string('Enter email').email('Email input be an email').required('Email is required'),
          first_name: Yup.string("Enter first name").required("First name is required"),
          last_name: Yup.string("Enter last name").required("Last name is required")
        })}
        onSubmit={(values, { setSubmitting }) => {
          console.log("##### save user", values);
          let saved = UserSvc.saveUser({
            id: selectedUser.id,
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            role: values.role || "",
            status: values.status || ""
          });
          if (saved) {
            enqueueSnackbar("User saved.", { variant: "success"})
            refreshUsers();
            handleClose();
          }
        }}

      >
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue
          } = props;
          return (
            <Form onSubmit={handleSubmit} autoComplete='nope' style={{ overflow: "auto" }}>
              <DrawerCardContent>
                <FormTextField
                  margin='dense'
                  name='email'
                  label={(touched.email && errors.email) ? errors.email : 'Email'}
                  placeholder='Enter an email'
                  value={values.email}
                  type="email"
                  required
                  InputProps={{
                    readOnly: values.id != null
                  }}
                  error={touched.email && Boolean(errors.email)}
                  onChange={handleChange}
                />
                <FormTextField
                  select
                  width='200px'
                  required
                  name='role'
                  value={values.role}
                  placeholder="Select a role"
                  label={(touched.role && errors.role) ? errors.role : 'Role'}
                  error={touched.role && Boolean(errors.role)}
                  onChange={handleChange}
                >
                  <FormMenuItem value=''>Select Role</FormMenuItem>
                  {roles.map(role => (
                    <FormMenuItem key={role.value} value={role.value}>
                      {role.value}
                    </FormMenuItem>
                  ))}
                </FormTextField>
                <FormTextField
                  margin='dense'
                  name='first_name'
                  value={values.first_name}
                  placeholder="Enter user first name"
                  required
                  label={(touched.first_name && errors.first_name) ? errors.first_name : 'First name'}
                  error={touched.first_name && Boolean(errors.first_name)}
                  onChange={handleChange}
                />
                <FormTextField
                  margin='dense'
                  name='last_name'
                  value={values.last_name}
                  placeholder="Enter user last name"
                  required
                  label={(touched.last_name && errors.last_name) ? errors.last_name : 'Last name'}
                  error={touched.last_name && Boolean(errors.last_name)}
                  onChange={handleChange}
                />

                <FormTextField
                  select
                  width='200px'
                  required
                  name='status'
                  value={values.status}
                  placeholder="Select a status"
                  label={(touched.status && errors.status) ? errors.status : 'Status'}
                  error={touched.status && Boolean(errors.status)}
                  onChange={handleChange}
                >
                  <FormMenuItem value=''>Select Status</FormMenuItem>
                  {statuses.map(status => (
                    <FormMenuItem key={status} value={status}>
                      {status}
                    </FormMenuItem>
                  ))}
                </FormTextField>
              </DrawerCardContent>
              <DrawerCardActions>
                <FormButton primary type='submit' >
                  Submit
                </FormButton>
                <FormButton onClick={handleClose}>Cancel</FormButton>
              </DrawerCardActions>
            </Form>
          );
        }}
      </Formik>
    );
  };
  return (
    <div>
      <RightDrawer
        title={selectedUser.id ? 'Edit User' : 'Add User'}
        open={openDrawer}
        handleClose={handleClose}
      >
        {form()}
      </RightDrawer>
    </div>
  );
};
export default UserDrawer;
