import React, { useState, useEffect } from 'react';

import {
  RightDrawer,
  DrawerCardContent,
  DataList,
  DataListItem,
} from '@/Theme';
import { DateTime } from 'luxon';

const View = ({ openDrawer, setOpenDrawer, log }) => {
  console.log('### Selected item for view: ', log);
  const handleCancel = () => {
    setOpenDrawer(false);
  };
  const handleCopy = (content) => {
    if (content) {
      navigator.clipboard.writeText(JSON.stringify(content));
    }
  }
  return (
    <div>
      <RightDrawer
        title="Wix Log"
        handleClose={handleCancel}
        open={openDrawer}
      >
        <DrawerCardContent>
          <DataList>
            <DataListItem label='ID' value={log.id}/>
            <DataListItem label='Ran on' value={DateTime.fromISO(log.updated).toLocaleString(DateTime.DATETIME_SHORT)}/>
            <DataListItem label='Elasped(ms)' value={log.elasped}/>
            <DataListItem label='Total' value={log.totalOrders}/>
            <DataListItem label='New' value={log.newOrders}/>
            <DataListItem label='Skipped' value={log.skippedOrders}/>
            <DataListItem label='Updated' value={log.updatedOrders}/>
            <DataListItem label='Responses' value={log.Responses}/>
            <DataListItem label="Order Numbers" contentprops={{ secondaryTypographyProps: { variant: "string" } }} onClick={() => { handleCopy(log.ordernumbers) }} value={
              <pre style={{ maxWidth: "300px", maxHeight: "100px", overflow: "auto" }}>
                {JSON.stringify(log.ordernumbers, null, 2)}
              </pre>
            } />
            <DataListItem label="Responses" contentprops={{ secondaryTypographyProps: { variant: "string" } }} onClick={() => { handleCopy(log.responses) }} value={
              <pre style={{ maxWidth: "300px", maxHeight: "100px", overflow: "auto" }}>
                {JSON.stringify(log.responses, null, 2)}
              </pre>
            } />
            <DataListItem label="Log" contentprops={{ secondaryTypographyProps: { variant: "string" } }} onClick={() => { handleCopy(log.log) }} value={
              <pre style={{ maxWidth: "300px", maxHeight: "100px", overflow: "auto" }}>
                {JSON.stringify(log.log, null, 2)}
              </pre>
            } />
          </DataList>
        </DrawerCardContent>
      </RightDrawer>
    </div>
  );
};
export default View;
