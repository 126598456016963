import React, { useState, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from 'notistack';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { AdminSvc } from '@/Services';

import {
  Formik,
  Form,
  useField,
  Field,
  ErrorMessage,
  validateYupSchema,
} from 'formik';
import * as Yup from 'yup';
import {
  RightDrawer,
  DrawerForm,
  FormButton,
  FormTextField,
  InputField,
  DrawerCardContent,
  DrawerCardActions,
  FormMenuItem,
} from '@/Theme';
import { Refresh } from '@mui/icons-material';
import { MenuItem } from '@mui/material';

const Edit = ({ openDrawer, setOpenDrawer, panelId, testType, success }) => {
  console.log('### Selected item for edit: ', testType);
  const [selectedItem, setSelectedItem] = useState(testType);
  const [organizations, setOrganizations] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const fetchOrganizations = async () => {
    let response = await AdminSvc.searchOrganizations();
    if (response && response.data) {
      setOrganizations(response.data);
    }
  };

  const handleSuccess = (savedTestType) => {
    setOpenDrawer(false);
    success(savedTestType);
  };
  const handleCancel = () => {
    setOpenDrawer(false);
  };
  const initValues = () => {
    let organization = selectedItem.organization;
    let organizationId = '';
    if (organization && organization.id){
      organizationId = organization.id;
    }
    return {
      id: selectedItem.id,
      name: selectedItem.name || '',
      organizationId: organizationId,
      description: selectedItem.description || '',
      displayName: selectedItem.displayName || '',
      displayDescription: selectedItem.displayDescription || '',
      externalId: selectedItem.externalId || '',
      active: selectedItem.active || true,
    };
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    organizationId: Yup.string().required('Organization is required'),
    externalId: Yup.string().required('External ID is required'),
    description: Yup.string(),
    displayName: Yup.string(),
    displayDescription: Yup.string(),
    active: Yup.boolean(),
  });
  useEffect(() => {
    fetchOrganizations();
  }, []);
  const form = () => {
    return (
      <Formik
        initialValues={initValues()}
        onSubmit={async (values, { setSubmitting }) => {
          console.log('##### save', values, panelId);
          validationSchema
            .validate(values)
            .then(async (valid) => {
              if (valid) {
                let savedPanel = await AdminSvc.savePanel({
                  id: selectedItem.id,
                  name: values.name,
                  description: values.description,
                  organizationId: values.organizationId,
                  displayName: values.displayName,
                  displayDescription: values.displayDescription,
                  externalId: values.externalId,
                  active: values.active,
                });

                if (savedPanel) {
                  enqueueSnackbar('Panel saved.', { variant: 'success' });
                  setSelectedItem(savedPanel);
                  handleSuccess(savedPanel.data);
                }
              }
            })
            .catch((err) => {
              console.log(err.errors);
              enqueueSnackbar(err.errors, { variant: 'error' });
            });
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
          } = props;
          const handleChecked = (value) => {
            setFieldValue('active', value);
          };
          return (
            <DrawerForm onSubmit={handleSubmit} autoComplete='nope'>
              <DrawerCardContent>
                <InputField field='name' label='Name' required props={props} />
                <InputField
                  field='description'
                  label='Description'
                  multiline
                  rows={2}
                  props={props}
                />
                <InputField
                  field='organizationId'
                  label='organization'
                  value={values.organizationId}
                  props={props}
                  select
                >
                  {/* <MenuItem value=''>Select</MenuItem> */}
                  {organizations.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </InputField>
                <InputField
                  field='externalId'
                  label='Lab Panel ID'
                  props={props}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name='active'
                      value={values.active}
                      checked={values.active}
                      onChange={() => handleChecked(!values.active)}
                    />
                  }
                  label='Active'
                />
              </DrawerCardContent>
              <DrawerCardActions>
                <FormButton primary type='submit'>
                  Save
                </FormButton>
                <FormButton onClick={handleCancel}>Cancel</FormButton>
              </DrawerCardActions>
            </DrawerForm>
          );
        }}
      </Formik>
    );
  };
  return (
    <div>
      <RightDrawer
        title={selectedItem.id ? 'Edit Panel' : 'Add New Panel'}
        handleClose={handleCancel}
        open={openDrawer}
      >
        {form()}
      </RightDrawer>
    </div>
  );
};
export default Edit;
