import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from 'notistack';
import { AdminSvc } from '@/Services';
import { CustomerService } from '@/Services';
import {
  Formik,
  Form,
  useField,
  Field,
  ErrorMessage,
  validateYupSchema,
} from 'formik';
import * as Yup from 'yup';
import {
  RightDrawer,
  FormButton,
  FormTextField,
  InputField,
  DrawerCardContent,
  DrawerCardActions,
  FormMenuItem,
} from '@/Theme';

const Edit = ({
  openDrawer,
  setOpenDrawer,
  selectedItem,
  refreshList,
  inputVariables,
}) => {
  console.log('srp - Selected item for edit: ', selectedItem);
  const { enqueueSnackbar } = useSnackbar();

  // const sessionRole = Session.getRole();
  const navigate = useNavigate();
  const handleClose = () => {
    setOpenDrawer(false);
    refreshList();
  };
  const initValues = () => {
    return {
      id: selectedItem.id,
      firstName: selectedItem.firstName || '',
      lastName: selectedItem.lastName || '',
      email: selectedItem.email || '',
    };
  };
  const form = () => {
    return (
      <Formik
        initialValues={initValues()}
        // validationSchema={Yup.object().shape({
        //   name: Yup.string('Enter test type name').required('Name is required'),
        // })}
        onSubmit={async (values, { setSubmitting }) => {
          console.log('##### save', values);
          // let savedTestType = await AdminSvc.saveTest({
          //customerSvc.save(data, sessionUser);
          let savedTestType = await CustomerService.save({  
            id: selectedItem.id,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email
          });
          if (savedTestType) {
            enqueueSnackbar('Customer saved.', { variant: 'success' });
            handleClose();
          }
        }}
      > 
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
          } = props;
          const handleChecked = (value) => {
            setFieldValue('active', value);
          };
          const handlePanel = (value) => {
            setFieldValue('isPanel', value);
          };
          // const handleSubmit = async (e) => {
          //   e.preventDefault();
          //   console.log('handle submit called');
          //   //console.log(value);
          //   console.log(values);
          //   console.log('##### save', values);
          //   let savedTestType = await AdminSvc.saveTest({
          //     id: selectedItem.id,
          //     name: values.name,
          //   });
          //   if (savedTestType) {
          //     enqueueSnackbar('Test type saved.', { variant: 'success' });
          //     handleClose();
          //   }            
          // };          
          return (
            <Form onSubmit={handleSubmit} autoComplete='nope'>
              <DrawerCardContent>
                <InputField field='email' label='Email' props={props} />
                <InputField
                  field='firstName'
                  label='First Name'
                  props={props}
                />
                <InputField field='lastName' label='Last Name' props={props} />
              </DrawerCardContent>
              <DrawerCardActions>
                <FormButton primary type='submit'>
                  Submit
                </FormButton>
                <FormButton onClick={handleClose}>Cancel</FormButton>
              </DrawerCardActions>
            </Form>
          );
        }}
      </Formik>
    );
  };
  return (
    <div>
      <RightDrawer
        title={selectedItem.id ? 'Update Customer' : 'Add New Customer'}
        handleClose={handleClose}
        open={openDrawer}
      >
        {form()}
      </RightDrawer>
    </div>
  );
};
export default Edit;