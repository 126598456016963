import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { MenuItem } from '@mui/material';
import List from './List';
import View from './View';
import {
  FilterBox,
  PageHeader,
  PageAction,
  PrimaryButton,
  FormButton,
  SelectFilterField,
  PrimaryPage,
} from '@/Theme';
import { LabService } from '@/Services';
import { useSnackbar } from 'notistack';
import { DateTime } from 'luxon';

const Home = () => {
  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [filter, setFilter] = useState({ search: "", time: "allTime", status: ['New', 'Kit Received', 'Submitted', 'Rejected', 'Completed', 'Canceled'] });
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState(['New', 'Kit Received', 'Submitted', 'Rejected', 'Completed', 'Canceled']);
  const [time, setTime] = useState('allTime');
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(50);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('created');
  const [direction, setDirection] = useState('desc');

  //For editing
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawer, setDrawer] = useState('');
  const [selectedItem, setSelectedItem] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  const filters = {
    where: {},
    size: size,
    page: page,
    sort: sort,
    order: direction,
  };
  const fetch = async () => {
    setLoading(true);
    filters.where = filter;
    let response = await LabService.search(filters);
    if (response && response.data && response.data.objects) {
      const objects = response.data.objects;
      setListData(objects);
      setCount(response.data.count);
    }
    setLoading(false);
  };
  useEffect(() => {
    let kn = searchParams.get("kn");
    if (kn) {
      let updatedFilter = { ...filter, ...{ search: kn } }
      setSearch(kn);
      setFilter(updatedFilter);
    }
  }, []);

  useEffect(() => {
    console.log('### fetch test types', search, size, page, sort, direction);
    filter.where = { freeText: search };
    fetch();
  }, [filter, size, page, sort, direction]);

  const onSearchChange = (e) => {
    e.preventDefault();
    console.log("set search change", e.target.value);
    setSearch(e.target.value);
  };
  const onSearchPressed = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearch(e.target.value);
      let codes = getCodes(e.target.value);
      let searchVal = codes && codes.length > 0 ? "" : search;
      let updatedFilter = {
        ...filter,
        ...{ search: searchVal },
        ...{ codes: codes },
        ...{ status: status },
        ...{ time: time }
      }
      setFilter(updatedFilter);
    }
  };
  const getCodes = (val) => {
    let codes = [];
    if (val && val.indexOf(',') > 0) {
      codes = search.split(',').map(element => element.trim().toUpperCase());
    }
    return codes;
  }
  const handleApplyFilter = () => {
    let codes = getCodes(search);
    let searchVal = codes && codes.length > 0 ? "" : search;
    let updatedFilter = {
      ...filter,
      ...{ search: searchVal },
      ...{ codes: codes },
      ...{ status: status },
      ...{ time: time }
    }
    setFilter(updatedFilter);
  }
  const onStatusSelect = (event) => {
    const { target: { value } } = event;
    setStatus(typeof value === 'string' ? value.split(',') : value,);
  }
  const onTimeSelect = (event) => {
    const { target: { value } } = event;
    setTime(value);
  }
  const handleEdit = (item) => {
    setSelectedItem(item);
    setDrawer('new');
    setOpenDrawer(true);
  };
  const handleNew = () => {
    navigate('/lab/new');
  };
  /**
   * Pull lab order 
   */
  const handlePull = () => {
    navigate('/lab/pull');
  };
  const handlePullResults = async () => {
    const response = await LabService.pullResults();
    if (response) {
      enqueueSnackbar('New results added', { variant: 'success' });
      fetch();
    }
  };
  const handlePushResults = async (testOrderId) => {
    const response = await LabService.pushResults(testOrderId);
    if (response.success) {
      enqueueSnackbar('Results have been pushed', { variant: 'success' });
      fetch();
    }
  };
  const contentDrawer = () => {
    let drawerContent = '';
    if (openDrawer) {
      if (drawer === 'view') {
        drawerContent = (
          <View
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            selectedItem={selectedItem}
            refreshList={fetch}
            inputVariables={filter}
          />
        );
      }
    }
    return drawerContent;
  };


  const convertArrayOfObjectsToCSV = () => {
    let result;
    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = ["Registration Code", "External ID", "Lab Kit", "Patient", "Status", "Last Update"];
    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
    listData.forEach(item => {
      result += item.kitNumber + columnDelimiter;
      result += (item.externalId || "") + columnDelimiter;
      result += item.labKit.name + columnDelimiter;
      // eslint-disable-next-line no-useless-concat
      result += (item.patient.firstName || "") + " " + (item.patient.lastName || "")  + columnDelimiter;
      result += item.status + columnDelimiter;
      // eslint-disable-next-line no-useless-concat
      result += DateTime.fromISO(item.updated).toLocaleString(DateTime.DATE_SHORT) + " " + DateTime.fromISO(item.updated).toLocaleString(DateTime.TIME_WITH_SECONDS);
      result += lineDelimiter;
    });

    return result;
  }

  const downloadCSV = () => {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV();
    if (csv === null) return;

    const filename = 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }


  return (
    <PrimaryPage title='Lab Orders'>
      <PageHeader>
        <PageAction>
          <FilterBox value={search} onChange={onSearchChange} onPress={onSearchPressed}
            extend={
              <>
                <SelectFilterField
                  labelId="filter-status-label"
                  id="filter-status-id"
                  value={status}
                  multiple
                  onChange={onStatusSelect}
                >
                  <MenuItem value="New">New</MenuItem>
                  <MenuItem value="Kit Received">Kit Received</MenuItem>
                  <MenuItem value="Submitted">Submitted</MenuItem>
                  <MenuItem value="Rejected">Rejected</MenuItem>
                  <MenuItem value="Completed">Completed</MenuItem>
                  <MenuItem value="Canceled">Canceled</MenuItem>
                </SelectFilterField>
                <SelectFilterField
                  labelId="filter-time-label"
                  id="filter-time-id"
                  value={time}
                  onChange={onTimeSelect}
                >
                  <MenuItem value="today">Today</MenuItem>
                  <MenuItem value="thisWeek">This Week</MenuItem>
                  <MenuItem value="thisMonth">This Month</MenuItem>
                  <MenuItem value="lastMonth">Last Month</MenuItem>
                  <MenuItem value="thisYear">This Year</MenuItem>
                  <MenuItem value="allTime">All Time</MenuItem>
                </SelectFilterField>
                <FormButton compact primary onClick={handleApplyFilter}>Filter</FormButton>
                <FormButton compact primary onClick={downloadCSV}>Export</FormButton>
              </>
            } />
        </PageAction>
        <PrimaryButton onClick={handleNew}>Register Lab Order</PrimaryButton>
        <PrimaryButton onClick={handlePull}>Pull Lab Order</PrimaryButton>
        <PrimaryButton onClick={handlePullResults}>Pull Results</PrimaryButton>
      </PageHeader>
      <List
        listData={listData}
        setOpenDrawer={setOpenDrawer}
        handleEdit={handleEdit}
        handlePushResults={handlePushResults}
        loading={loading}
        count={count}
        size={size}
        setSize={setSize}
        page={page}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
        direction={direction}
        setDirection={setDirection}
      />
      {contentDrawer()}
    </PrimaryPage>
  );
};
export default Home;
