/** PACKAGE IMPORTS */
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { DataTableStyles, EmptyData, LoadingData } from '@/Theme';
import { Avatar, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PanelIcon from '@mui/icons-material/Bookmarks';
import TestIcon from '@mui/icons-material/Bookmark';

const List = ({
  listData,
  loading,
  handleEdit,
  count,
  size,
  setSize,
  page,
  setPage,
  setSort,
  setDirection,
}) => {
  const navigate = useNavigate();

  const handleRowClick = (customer) => {
    let link = `/customer/${customer.id}`;
    navigate(link);
  };
  const columns = useMemo(
    () => [
      {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
      },
      {
        name: 'First Name',
        selector: row => row.firstName,
        sortable: true,
      },
      {
        name: 'Last Name',
        selector: row => row.lastName,
        sortable: true,
      },
      {
        name: 'Middle Name',
        selector: row => row.middleName,
        sortable: true,
      },
      {
        name: 'Primary Phone',
        selector: row => row.home,
        sortable: true,
      },
      {
        name: 'Mobile',
        selector: row => row.mobile,
        sortable: true,
      },
      {
        name: 'Organization',
        selector: row => row.organizationId,
        sortable: true,
        grow: 1,
        cell: (row) => (row.organization && row.organization.name)
      },
      // {
      //   name: 'External ID',
      //   selector: 'externalId',
      //   sortable: true,
      //   grow: 2,
      // },
      // {
      //   name: '',
      //   sortable: false,
      //   width: '56px',
      //   button: true,
      //   cell: (row) => {
      //     return (
      //       <IconButton onClick={() => handleEdit(row)}>
      //         <EditIcon />
      //       </IconButton>
      //     );
      //   },
      // },      
    ],
    []
  );
  const handlePageChange = (p) => {
    console.log('### handlePageChange', page, '--', p);
    setPage(p);
  };
  const handlePerRowsChange = (perPage, p) => {
    console.log('### handleRowsChange', perPage, p);
    setSize(perPage);
    setPage(p);
  };
  const handleSort = (sort, direction) => {
    console.log('start==>' + direction);
    let startDirection = direction;
    console.log('### handleSort', sort, direction);
    setSort(sort.selector);
    setDirection(direction);
    let endDirection = direction;
    console.log('end==>' + direction);
    // if (startDirection === 'desc' && endDirection === 'desc') {
    //   console.log('both ds==>' + direction);

    //   setSort(sort.selector);
    //   setDirection('asc');
    //   fetch();
    // }
    // if (startDirection === 'asc' && endDirection === 'asc') {
    //   console.log('both as==>' + direction);
    //   setSort(sort.selector);
    //   setDirection('desc');
    // }
  };

  return (
    <>
      {loading && listData.length === 0 && <LoadingData />}
      {listData.length > 0 && (
        <DataTable
          className='pagination-custom'
          columns={columns}
          data={listData}
          noHeader
          onRowClicked={handleRowClick}
          highlightOnHover
          customStyles={DataTableStyles}
          paginationDefaultPage={page}
          persistTableHead
          paginationPerPage={size}
          pagination
          paginationServer
          paginationTotalRows={count}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          defaultSortField='1'
          defaultSortAsc={false}
          onSort={handleSort}
          sortServer
          paginationRowsPerPageOptions={[5, 10, 15, 25, 50, 100, 200, 500]}
        />
      )}
      {!loading && !listData.length && <EmptyData entity='customers' />}
    </>
  );
};
export default List;
