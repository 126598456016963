/** PACKAGE IMPORTS */
import React, { useEffect, useState } from 'react';
import { Route, useNavigate } from 'react-router-dom';
import List from './List';
import {
  DataTableStyles, EmptyData, LoadingData,
  DisplayList
} from '@/Theme';
import { ListItem, ListItemText, IconButton, Tooltip, Typography} from '@mui/material';
import {
  PrimaryPage,
  ContentCardContent,
  ContentCardShadowed,
  ContentCardHeader,
  FormButton,
  SearchBox,
  EmptyPage
} from '@/Theme';
import { Redux as WebAuth } from '@ihdlab/web-auth';
import { UserSvc, OrderService } from '@/Services';
import {
  Grid,
  CardActions,
  CardHeader,
} from '@mui/material';
import KitIcon from '@mui/icons-material/MedicalServices';
import { LabService } from '@/Services';

const Auth = WebAuth.Auth;

const getGreeting = () => {
  let data = [
    [22, 'Thank you! Working late'],
    [18, 'Good evening'],
    [12, 'Good afternoon'],
    [5, 'Good morning'],
    [0, 'Whoa, early bird'],
  ];
  let hr = new Date().getHours();
  let msg = 'Welcome';
  for (let i = 0; i < data.length; i++) {
    if (hr >= data[i][0]) {
      msg = data[i][1];
      break;
    }
  }
  return msg;
};

const Home = () => {
  const [kn, setKn] = useState("");
  const [loading, setLoading] = useState(false);
  const [labloading, setLabLoading] = useState(false);
  const [labloadingNew, setLabLoadingNew] = useState(false);
  const [listData, setListData] = useState([]);
  const [listOrderData, setLisOrdertData] = useState([]);
  const [listOrderDataNew, setLisOrdertDataNew] = useState([]);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState({ search: "", status: ['New', 'Pending'] });
  const [filterLabTest1, setFilterLabTest1] = useState({ search: "", status: ['Kit Received'] });
  const [filterLabTest2, setFilterLabTest2] = useState({ search: "", status: ['New'] });
  const [status, setStatus] = useState([])
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(5);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('created');
  const [direction, setDirection] = useState('desc');

  const navigate = useNavigate();
  const user = UserSvc.me();
  const acl = UserSvc.acl();

  let greeting = getGreeting();
  const onKnChanged = (e) => {
    e.preventDefault();
    setKn(e.target.value);
  };
  const onKnPressed = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setKn(e.target.value);
      let linkTo = "/lab?kn=" + e.target.value;
      navigate(linkTo);
    }
  };


  const handleViewNews = () => {
    let link = `/kit/order`;
    navigate(link);
  };
  const handlelabOrder = () => {
    let link = `/lab`;
    navigate(link);
  };

  const filters = {
    where: {},
    size: size,
    page: page,
    sort: sort,
    order: direction,
  };
  const fetch = async () => {
    setLoading(true);
    filters.where = filter;
    let response = await OrderService.search(filters);
    if (response && response.data && response.data.objects) {
      const objects = response.data.objects;
      setCount(response.data.count);
      setListData(objects);
    }
    setLoading(false);
  };

  const fetchOrderNew = async () => {
    setLabLoadingNew(true);
    filters.where = filterLabTest2;
    let response = await LabService.search(filters);
    console.log('### fetchOrderNew', response);
    if (response && response.data && response.data.objects) {
      const objects = response.data.objects;
      setCount(response.data.count);
      setLisOrdertDataNew(objects);
    }
    setLabLoadingNew(false);
  };

  const fetchOrderKitReceived = async () => {
    setLabLoading(true);
    filters.where = filterLabTest1;
    let response = await LabService.search(filters);
    console.log('### fetchOrderNew', response);
    if (response && response.data && response.data.objects) {
      const objects = response.data.objects;
      setCount(response.data.count);
      setLisOrdertData(objects);
    }
    setLabLoading(false);
  };
  useEffect(() => {
    console.log('### fetch test types', search, size, page, sort, direction);
    if (user && user.role && acl && acl["kit orders"].includes(user.role)) {
      fetch();
    }
    if (user && user.role && acl && acl["lab orders"].includes(user.role)) {
      fetchOrderNew();
      fetchOrderKitReceived();
    }
  }, [user, acl]);

  const updateStatus = async (order) => {
    order.status = order.status === "Completed" ? order.status = 'Pending' : 'Completed';
    let response = await OrderService.save(order);
    fetch();
  };
  const handleUpdateStatus = (row) => {
    updateStatus(row);
  };

  const testColumns1 = "1"

  const testColumns2 = "2"
  return (
    <>
      {user && user.role && user.role === 'Patient' && (
        <EmptyPage className='flex-col-cc' style={{ width: "100%", minHeight: '15rem' }}>
          <Typography variant='h5'>You do not have permission to access this page. Please see an Admin for help.</Typography>
        </EmptyPage>
      )
      }
      {user && user.role && acl &&
        <PrimaryPage title={greeting + ' ' + user.first_name}>
          <Grid container spacing={3}>
            {user && user.role && acl && acl["kit orders"].includes(user.role) &&
              <Grid item xs={9}>
                <ContentCardShadowed>
                  <ContentCardHeader title='New Kit Orders'
                    action={
                      <>
                        <FormButton primary compact onClick={handleViewNews}>
                          Manage New Orders
                        </FormButton>
                      </>
                    }
                  />
                  <ContentCardContent>
                    <List
                      testColumns={testColumns1}
                      listData={listData}
                      loading={loading}
                      handleUpdateStatus={handleUpdateStatus}
                    />
                  </ContentCardContent>
                </ContentCardShadowed>
              </Grid>
            }
            {user && user.role && acl && acl["lab orders"].includes(user.role) &&
              <Grid item xs={3}>
                <ContentCardShadowed>
                  <CardHeader title="Process Lab Order" subheader="Enter Registration Code to get started." />
                  <ContentCardContent>
                    <SearchBox value={kn} onChange={onKnChanged} onPress={onKnPressed} placeholder="Registration Code" />
                  </ContentCardContent>
                </ContentCardShadowed>
              </Grid>
            }

            {user && user.role && acl && acl["lab orders"].includes(user.role) &&
              <Grid item xs={9}>
                <ContentCardShadowed>
                  <ContentCardHeader title='Lab Orders - Kit Received (Not Resulted)'
                    action={
                      <>
                        <FormButton primary compact onClick={handlelabOrder}>
                          Manage Lab Orders
                        </FormButton>
                      </>
                    }
                  />
                  <ContentCardContent>
                    <List
                      testColumns={testColumns2}
                      listData={listOrderData}
                      loading={labloading}
                    />
                  </ContentCardContent>
                </ContentCardShadowed>
              </Grid>
            }

            {user && user.role && acl && acl["lab orders"].includes(user.role) &&
              <Grid item xs={9}>
                <ContentCardShadowed>
                  <ContentCardHeader title='Lab Orders - Submitted (Kit Not Received)'
                    action={
                      <>
                        <FormButton primary compact onClick={handlelabOrder}>
                          Manage Lab Orders
                        </FormButton>
                      </>
                    }
                  />
                  <ContentCardContent>
                    <List
                      testColumns={testColumns2}
                      listData={listOrderDataNew}
                      loading={labloadingNew}
                    //listData={listOrderDataNew}
                    //loading={labloadingNew}
                    />
                  </ContentCardContent>
                </ContentCardShadowed>
              </Grid>
            }

          </Grid>
        </PrimaryPage>
      }
    </>
  );
};
export default Home;
