import BaseSvc from './base-service';

class AuthService {
  constructor() {
		this.authConfig = {};
		if (!AuthService.instance) {
			this.authConfig = {};
			AuthService.instance = this;
		}
		return AuthService.instance;
	}

  async verify(code){
    console.log("#### AuthSvc#verify", code)
    let response = await BaseSvc.securePost("/auth/code/auth", {code:code})
    return response;
  }
}

const instance = new AuthService();
Object.freeze(instance);
export default instance;
