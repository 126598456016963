/** PACKAGE IMPORTS */
import React, { useEffect } from 'react';
import {
  PrimaryPage,
  FormButton,
  ContentCardContent,
  ContentCardShadowed,
  ContentCardHeader,
  SecondaryPage,
} from '@/Theme';
import { Redux as WebAuth } from '@ihdlab/web-auth';
import { UserSvc } from '@/Services';
import { CardActions, Typography } from '@mui/material';

const Auth = WebAuth.Auth;

const Development = () => {
  const token = Auth.getToken();
  const isLoggedIn = Auth.isLoggedIn();
  const user = UserSvc.me();
  return (
    <SecondaryPage title='Integratons' back='/partner'>
      <ContentCardShadowed>
        <ContentCardHeader title='Integration Token (TBD)' />
        <ContentCardContent>
          <code>...</code>
        </ContentCardContent>
        <CardActions>
          <FormButton compact primary>
            Generate new token
          </FormButton>
        </CardActions>
      </ContentCardShadowed>
      <ContentCardShadowed group>
        <ContentCardHeader title='Webhooks(TBD)' />
        <ContentCardContent>
          <code>...</code>
        </ContentCardContent>
        <CardActions>
          <FormButton compact primary>
            Add new webhook
          </FormButton>
        </CardActions>
      </ContentCardShadowed>
      <ContentCardShadowed group>
        <ContentCardHeader title='Developement (TBD)' />
        <ContentCardContent>
          <a href='/'>Get Started</a>
        </ContentCardContent>
      </ContentCardShadowed>
    </SecondaryPage>
  );
};
export default Development;
