import React, { useState, useEffect } from 'react';
import {
  Nav,
  NavButton,
  NavIcon,
  MainMenuDrawer,
  MainMenuHeader,
  MainMenuContent,
  MainMenu,
  MainMenuItem,
  MainMenuFooter,
  AccountMenu,
  AccountAvatar,
  AccountMenuItem,
} from './Style';
import {
  Toolbar,
  Typography,
  IconButton,
  Badge,
  Slide,
  useMediaQuery,
  useScrollTrigger,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  CloseIcon,
  NotificationIcon,
  DashboardIcon,
  OrdersIcon,
  TestsIcon,
  ShippingsIcon,
  KitsIcon,
  CustomersIcon,
  PatientsIcon,
  ProfileIcon,
  AdminIcon,
  ExitIcon,
  BackIcon,
  ArrowDownIcon,
  LogoIcon,
  HelpIcon,
} from './Icons';
import PropTypes from 'prop-types';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Redux as WebAuth } from '@ihdlab/web-auth';
import { UserSvc } from '@/Services';

const Auth = WebAuth.Auth;
const HideOnScroll = (props) => {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });
  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  );
};
HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

const Navigation = (props) => {
  const location = useLocation();
  const currentPath = (location && location.pathname) || '/dashboard';
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(currentPath);
  const desktop = useMediaQuery('(min-width:600px)');
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const accountMenuOpen = Boolean(anchorEl);
  const isLoggedIn = Auth.isLoggedIn();
  const openCloseNav = () => {
    setOpen(!open);
  };
  const handleChange = (event, newValue) => {
    openCloseNav();
    setValue(newValue);
  };
  const handleAccountMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAccountMenu = () => {
    setAnchorEl(null);
  };
  const openProfile = (e) => {
    e.preventDefault();
    setAnchorEl(null);
    navigate('/profile');
  };
  const signOut = (e) => {
    e.preventDefault();
    setAnchorEl(null);
    Auth.signout();
    window.location.reload();
  };
  const user = UserSvc.me();
  const acl = UserSvc.acl();
  let firstName = user ? user.first_name : '';

  return (
    <>
      <HideOnScroll {...props}>
        <Nav className=' ' position='fixed' color='transparent' elevation={0}>
          <Toolbar variant='dense'>
            <NavButton aria-label='menu' onClick={openCloseNav} edge='start'>
              <ArrowDownIcon id='primary-menu-opener' />
              <NavIcon w='2.8rem' h='2.8rem' icon={LogoIcon} />
            </NavButton>
            <Typography variant='h4' style={{ color: '#2a2277' }}>
              {props.title}
            </Typography>
            {isLoggedIn && (
              <div className='flex flex-row-ec'>
                <NavButton>
                  <Badge badgeContent={0} color='secondary'>
                    <NavIcon icon={<NotificationIcon />} />
                  </Badge>
                </NavButton>
                <IconButton
                  size='small'
                  aria-label='Account'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  onClick={handleAccountMenu}
                  color='inherit'
                >
                  <AccountAvatar
                    alt={firstName}
                    src={firstName}
                    aria-label='Account'
                    aria-controls='menu-appbar'
                    aria-haspopup='true'
                    color='inherit'
                  />
                </IconButton>
                <AccountMenu
                  id='menu-appbar'
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={accountMenuOpen}
                  onClose={handleCloseAccountMenu}
                >
                  <AccountMenuItem onClick={openProfile}>
                    <ListItemIcon>
                      <ProfileIcon />
                    </ListItemIcon>
                    <ListItemText primary='Profile' />
                  </AccountMenuItem>
                  <AccountMenuItem onClick={signOut}>
                    <ListItemIcon>
                      <ExitIcon />
                    </ListItemIcon>
                    <ListItemText primary='Sign out' />
                  </AccountMenuItem>
                </AccountMenu>
              </div>
            )}
          </Toolbar>
        </Nav>
      </HideOnScroll>
      <MainMenuDrawer
        width={desktop ? '300px' : '100%'}
        anchor='left'
        open={open}
        onClose={openCloseNav}
      >
        <MainMenuHeader>
          <Typography
            variant='h4'
            noWrap
            className='flex flex-row-cc'
            style={{ color: '#2a2277' }}
          >
            Order Management
          </Typography>
          <NavButton onClick={openCloseNav}>
            <NavIcon icon={<CloseIcon />} />
          </NavButton>
        </MainMenuHeader>
        <MainMenuContent className='flex '>
          <MainMenu
            showLabels
            className='flex flex-wrap'
            value={value}
            onChange={handleChange}
          >
            ${user && user.role && acl && acl.dashboard.includes(user.role) &&
              <MainMenuItem
                component={Link}
                to='/dashboard'
                label={
                  <>
                    Dashboard
                    <br />
                    <br />
                  </>
                }
                value='/dashboard'
                icon={<NavIcon primary icon={<DashboardIcon />} />}
              />
            }
            ${user && user.role && acl && acl["kit orders"].includes(user.role) &&
              <MainMenuItem
                component={Link}
                to='/kit/order'
                label='Kit Orders'
                value='/kit/order'
                icon={<NavIcon primary icon={<OrdersIcon />} />}
              />
            }
            ${user && user.role && acl && acl.kits.includes(user.role) &&
              <MainMenuItem
                component={Link}
                to='/kit'
                label={
                  <>
                    Kits
                    <br />
                    <br />
                  </>
                }
                value='/kit'
                icon={<NavIcon primary icon={<KitsIcon />} />}
              />
            }
            ${user && user.role && acl && acl.shipping.includes(user.role) &&
              <MainMenuItem
                component={Link}
                to='/shipping'
                label={
                  <>
                    Shipping
                    <br />
                    <br />
                  </>
                }
                value='/shipping'
                icon={<NavIcon primary icon={<ShippingsIcon />} />}
              />}
            ${user && user.role && acl && acl.customers.includes(user.role) &&
              <MainMenuItem
                component={Link}
                to='/customer'
                label={
                  <>
                    Customers
                    <br />
                    <br />
                  </>
                }
                value='/customer'
                icon={<NavIcon primary icon={<CustomersIcon />} />}
              />
            }
            ${user && user.role && acl && acl["lab orders"].includes(user.role) &&
              <MainMenuItem
                component={Link}
                to='/lab'
                label='Lab Orders'
                value='/lab'
                icon={<NavIcon primary icon={<TestsIcon />} />}
              />
            }
            ${user && user.role && acl && acl.patients.includes(user.role) &&
              <MainMenuItem
                component={Link}
                to='/patient'
                label='Patients'
                value='/patient'
                icon={<NavIcon primary icon={<PatientsIcon />} />}
              />
            }
            <MainMenuItem
              component={Link}
              to='/help'
              label={
                <>
                  Help
                  <br />
                  Center
                </>
              }
              value='/help'
              icon={<NavIcon primary icon={<HelpIcon />} />}
            />
            {/* <MainMenuItem
              component={Link}
              to='/partner/dashboard'
              label='Partner'
              value="/partner/dashboard"
              icon={<NavIcon primary icon={<PartnerIcon />} />}
            /> */}
            ${user && user.role && acl && acl.admin.includes(user.role) &&
              <MainMenuItem
                component={Link}
                to='/admin/dashboard'
                label='Admin'
                value='/admin'
                icon={<NavIcon primary icon={<AdminIcon />} />}
              />
            }
          </MainMenu>
        </MainMenuContent>
        <MainMenuFooter>
          <div>
            <NavIcon w='2.8rem' h='2.8rem' icon={LogoIcon} />
            <Typography variant='h4' noWrap>
              IHD
            </Typography>
          </div>
        </MainMenuFooter>
      </MainMenuDrawer>
    </>
  );
};

const PageToolbar = (props) => {
  let title = props.title || '';
  let icon = props.icon || '';
  let back = props.back || '/';
  return (
    <HideOnScroll {...props}>
      <Nav className=' ' position='fixed' color='transparent' elevation={0}>
        <Toolbar variant='dense' className='flex flex-row-sc '>
          <NavButton aria-label='menu' component={Link} to={back} edge='start'>
            <NavIcon icon={<BackIcon />} />
          </NavButton>
          {icon}
          <Typography
            variant='h4'
            noWrap
            className='flex-row-sc'
            style={{ color: '#2a2277', marginLeft: icon ? '1rem' : '0' }}
          >
            {title}
          </Typography>
          <div className='flex '>{props.form}</div>
          <div>{props.actions}</div>
        </Toolbar>
      </Nav>
    </HideOnScroll>
  );
};

export { Navigation, NavButton, NavIcon, PageToolbar };
