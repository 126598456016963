import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  FormButton,
  InputField,
  SecondaryPage,
  ContentCard,
  ContentCardHeader,
  DataList,
  DataListItem,
  ContentCardContent,
} from '@/Theme';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { CardHeader, CardActions } from '@mui/material';
import {
  CardContent,
  Grid,
  StepContent,
  Stepper,
  StepButton,
  Typography,
} from '@mui/material';
import { LabService, KitService, AdminSvc, OrderService } from '@/Services';
import { useSnackbar } from 'notistack';
import { FixedGrid, FancyStep } from './Style';
import { DropdownIcon } from '@/Theme/Icons';

const New = () => {
  const [kit, setKit] = useState(null);
  const [labKit, setLabKit] = useState(null);
  const [organizations, setOrganizations] = useState([{}]);
  const [validKit, setValidKit] = useState(false);
  const [kitResultMsg, setKitResultMsg] = useState(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleBackToHome = () => {
    navigate('/lab');
  };

  const fetchLabOrder = async (code, org) => {
    let response = await LabService.pullPartnerOrder(code.trim(), org);
    console.log({ response });
    if (response && response.data && response.data.id) {
      console.log(response.data);
    }
  };
  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const initValues = {
    code: searchParams.get('code') || ' ',
    organizationId: '',
    orderId: ' ',
  };
  const form = () => {
    return (
      <Formik initialValues={initValues}>
        {(props) => {
          const { values, setValues } = props;
          const pullOrderFromCheclab = async () => {
            let code = values.code.trim();
            await fetchLabOrder(code, 'checlab');
          };
          return (
            <Form>
              <SecondaryPage
                title='Pull Lab Order'
                back='/lab'
                style={{ overflow: 'visible' }}
                actions={
                  <>
                    <FormButton onClick={handleBackToHome}>Cancel</FormButton>
                  </>
                }
              >
                <Grid container spacing={2} className='flex'>
                  <FixedGrid item sm={12} className=''>
                    <ContentCard border>
                      <ContentCardContent>
                        <Stepper
                          nonLinear
                          orientation='vertical'
                          activeStep={activeStep}
                        >
                          <FancyStep key='organizationStep' expanded>
                            <StepButton>
                              <Typography variant='h5'>
                                Find Lab Order
                              </Typography>
                            </StepButton>
                            <StepContent>
                              <Grid container spacing={0}>
                                <Grid item sm={4}>
                                  <ContentCard>
                                    <CardContent>
                                      <InputField
                                        field='code'
                                        label='Kit barcode/registration code'
                                        props={props}
                                      />
                                    </CardContent>
                                    <CardActions>
                                      <FormButton
                                        onClick={pullOrderFromCheclab}
                                        primary
                                      >
                                        Pull Lab Order From Checlab
                                      </FormButton>
                                    </CardActions>
                                  </ContentCard>
                                </Grid>
                                <Grid item sm={4}>
                                  {kitResultMsg && (
                                    <Typography variant='h5'>
                                      {kitResultMsg}
                                    </Typography>
                                  )}
                                  {labKit && labKit.id && (
                                    <>
                                      <DataList>
                                        <DataListItem
                                          label='Lab Kit'
                                          value={labKit.name}
                                        />
                                        <DataListItem
                                          label='Organization'
                                          value={labKit.organization.name}
                                        />
                                      </DataList>
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                            </StepContent>
                          </FancyStep>
                          <FancyStep
                            key='orderStep'
                            disabled={!validKit}
                            expanded={validKit}
                          >
                            <div className='flex flex-row-sc justify-between'>
                              <StepButton
                                onClick={handleStep(2)}
                                className='flex '
                              >
                                <Typography variant='h5'>Lab Order</Typography>
                              </StepButton>
                            </div>
                            <StepContent>
                              <ContentCard sx={{ maxWidth: '500px' }}>
                                <CardContent>Order Detail</CardContent>
                                <CardActions>
                                  <FormButton type='submit' primary>
                                    Submit to Process
                                  </FormButton>
                                </CardActions>
                              </ContentCard>
                            </StepContent>
                          </FancyStep>
                        </Stepper>
                      </ContentCardContent>
                    </ContentCard>
                  </FixedGrid>
                </Grid>
              </SecondaryPage>
            </Form>
          );
        }}
      </Formik>
    );
  };
  return form();
};

export default New;
