import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CardHeader from '@mui/material/CardHeader';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from 'notistack';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { AdminSvc } from '@/Services';
import styled from 'styled-components';

import {
  Formik,
  Form,
  useField,
  Field,
  ErrorMessage,
  validateYupSchema,
} from 'formik';
import * as Yup from 'yup';
import {
  RightDrawer,
  DrawerForm,
  FormButton,
  FormTextField,
  InputField,
  DrawerCardContent,
  DrawerCardActions,
  FormMenuItem,
} from '@/Theme';

const Edit = ({ openDrawer, setOpenDrawer, selectedItem, success }) => {
  console.log('### Selected item for edit: ', selectedItem);
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const handleClose = () => {
    setOpenDrawer(false);
    success();
  };
  const initValues = () => {
    return {
      id: selectedItem.id,
      name: selectedItem.name || '',
      externalId: selectedItem.externalId,
      organizationId: selectedItem.organizationId,
      locationId: selectedItem.locationId,
      npi: selectedItem.npi,
      timezone: selectedItem.timezone,
      code: selectedItem.code,
      logo: selectedItem.logo,
      active: selectedItem.active === true,
      shareResults: selectedItem.shareResults === true
    };
  };
  const form = () => {
    return (
      <Formik
        initialValues={initValues()}
        validationSchema={Yup.object().shape({
          name: Yup.string('Enter partner name').required('Name is required'),
          timezone: Yup.string('Enter time zone').required(
            'Time zone is required'
          ),
          organizationId: Yup.string('Enter Organization ID').required(
            'Organization ID is required'
          ),
          locationId: Yup.string('Enter Location ID').required(
            'Location ID is required'
          ),
          code: Yup.string('Enter code').required(
            'Code is required'
          ),
          logo: Yup.string('Enter logo URL').required('Logo URL is required'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          console.log('##### save', values);
          let savedPartner = await AdminSvc.saveOrganization({
            id: selectedItem.id,
            name: values.name,
            npi: values.npi,
            organizationId: values.organizationId,
            locationId: values.locationId,
            externalId: values.externalId,
            timezone: values.timezone,
            code: values.code,
            logo: values.logo,
            active: values.active,
            shareResults: values.shareResults
          });
          if (savedPartner) {
            enqueueSnackbar('Partner saved.', { variant: 'success' });
            handleClose();
          }
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
          } = props;
          const handleChecked = (field, value) => {
            setFieldValue(field, value);
          };
          const fileUpload = async e => {
            let file = e.target.files[0]
            let name = `${values.code}-logo.${file.name.split('.').pop()}`
            await AdminSvc.uploadLogo(name, file);
            setFieldValue('logo', `https://cdn.myihdlab.com/partners/${name}`)
          }
          return (
            <DrawerForm onSubmit={handleSubmit} autoComplete='nope'>
              <DrawerCardContent>
                <InputField field='name' label='Name' props={props} required />
                <InputField field='timezone' label='Time Zone' props={props} required />
                <InputField
                  field='code'
                  label='Code'
                  placeholder='Enter a 3-letter alpha code for the organization'
                  props={props}
                  required
                />
                <InputField field='logo' label='Logo' props={props} required />
                
                <div>
                  <input type='file' onChange={fileUpload} />
                </div>

                <InputField
                  field='organizationId'
                  label='Organization ID'
                  props={props}
                  required
                />
                <InputField
                  field='locationId'
                  label='Location ID'
                  props={props}
                  required
                />
                <InputField
                  field='npi'
                  label='NPI'
                  props={props}
                  required
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name='active'
                      value={values.active}
                      checked={values.active}
                      onChange={() => handleChecked('active', !values.active)}
                    />
                  }
                  label='Active'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name='shareResults'
                      value={values.shareResults}
                      checked={values.shareResults}
                      onChange={() => handleChecked('shareResults', !values.shareResults)}
                    />
                  }
                  label='Share Results'
                />
                <InputField
                  field='externalId'
                  label='External ID'
                  props={props}
                />
                <InputField
                  field='metadata'
                  label='Metadata'
                  multiline
                  rows={4}
                  props={props}
                />
              </DrawerCardContent>
              <DrawerCardActions>
                <FormButton primary type='submit'>
                  Submit
                </FormButton>
                <FormButton onClick={handleClose}>Cancel</FormButton>
              </DrawerCardActions>
            </DrawerForm>
          );
        }}
      </Formik>
    );
  };
  return (
    <div>
      <RightDrawer
        title={selectedItem.id ? 'Edit Organization' : 'Add New Organization'}
        handleClose={handleClose}
        open={openDrawer}
      >
        {form()}
      </RightDrawer>
    </div>
  );
};
export default Edit;
