import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import CardContent from '@mui/material/CardContent';
import configs from '@/config';
import { ContentCardHeader, FormTextField, FormButton } from '@/Theme';
import { LoginCard, ActionContainer } from './Style';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

const Request = ({ updateRequest }) => {
  const { enqueueSnackbar } = useSnackbar();

  const login = (email) => {
    console.log('### login with email', email);
    axios({
      method: 'post',
      url: `${configs.api.REST_URI}/auth/code/login`,
      data: { email: email },
    })
      .then(function (response) {
        console.debug('### REST Test Response', response);
        let data = response ? response.data : {};
        if (data.ok) {
          updateRequest(true);
        } else {
          let msg = data.message || "Something went wrong. Please try again."
          enqueueSnackbar(msg, { variant: "error" , persist: true })
        }
      })
      .catch(function (error) {
        alert('error' + error);
      });
  };
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email is not valid.')
      // .test('test-business', 'Email is not a business email address', (val) => {
      //   return !val || !(val.indexOf('gmail.com') > 0);
      // })
      .required('Email is required'),
  });
  return (
    <div className='flex-col-cc'>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={LoginSchema}
        onSubmit={(values, { setSubmitting }) => {
          login(values.email);
        }}
      >
        {(props) => {
          const { values, touched, errors, handleChange, handleSubmit } = props;
          return (
            <Form onSubmit={handleSubmit}>
              <LoginCard>
                <ContentCardHeader
                  title={<Typography variant='h3'>Sign In</Typography>}
                />
                <CardContent>
                  <FormTextField
                    large
                    name='email'
                    value={values.email}
                    placeholder='Enter email to login...'
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    margin='dense'
                  />
                  <ActionContainer>
                    <FormButton primary large type='submit'>
                      Sign In
                    </FormButton>
                  </ActionContainer>
                </CardContent>
              </LoginCard>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
export default Request;
