import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Redux as WebAuth } from '@ihdlab/web-auth';
import Kits from '@/Components/Admin/Kit';
import Kit from '@/Components/Admin/Kit/View';
import Panels from '@/Components/Admin/Panel';
import Panel from '@/Components/Admin/Panel/View';
import Tests from '@/Components/Admin/Test';
import Test from '@/Components/Admin/Test/View';
import Dashboard from '@/Components/Admin/Dashboard';
import Specimens from '@/Components/Admin/Specimen';
import ICDCodes from '@/Components/Admin/ICDCode';
import Organizations from '@/Components/Admin/Organization';
import Organization from '@/Components/Admin/Organization/View';
import Users from '@/Components/Admin/User';
import Tasks from '@/Components/Admin/Task';
import Wix from '@/Components/Admin/Wix';
import APIAccess from '@/Components/Admin/APIRequest';
import Notifications from '@/Components/Admin/Notification';

const Private = WebAuth.Private;
const Admin = () => {
  return (
    <Routes>
      <Route exact path='/dashboard' element={<Private comp={Dashboard} />} />
      <Route exact path='/kit' element={<Private comp={Kits} />} />
      <Route exact path='/kit/:id' element={<Private comp={Kit} />} />
      <Route exact path='/panel' element={<Private comp={Panels} />} />
      <Route exact path='/panel/:id' element={<Private comp={Panel} />} />
      <Route exact path='/test' element={<Private comp={Tests} />} />
      <Route exact path='/test/:id' element={<Private comp={Test} />} />
      <Route exact path='/user' element={<Private comp={Users} />} />
      <Route exact path='/specimen' element={<Private comp={Specimens} />} />
      <Route exact path='/icdcode' element={<Private comp={ICDCodes} />} />
      <Route exact path='/organization' element={<Private comp={Organizations} />} />
      <Route exact path='/organization/:id' element={<Private comp={Organization} />} />
      <Route exact path='/task' element={<Private comp={Tasks} />} />
      <Route exact path='/wix' element={<Private comp={Wix} />} />
      <Route exact path='/api' element={<Private comp={APIAccess} />} />
      <Route exact path='/notification' element={<Private comp={Notifications} />} />
    </Routes>
  );
};

export default Admin;
