import { combineReducers } from 'redux';
import companyReducer from './Company';
import userReducer from './User';
import roleReducer from './Role';
import aclReducer from './ACL';
import windowDimensionReducer from './WindowDimension';
import { Redux as WebAuth } from '@ihdlab/web-auth';

const AuthReducer = WebAuth.Reducer;
const rootReducer = combineReducers(
  {
    ...{
      company: companyReducer,
      user: userReducer,
      role: roleReducer,
      acl: aclReducer,
      windowDimension: windowDimensionReducer
    }, 
    ...AuthReducer
  });

export default rootReducer;
