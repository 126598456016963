import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MenuItem,
  CardContent,
  Grid,
  StepContent,
  Stepper,
  CardActions,
  StepButton,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { AdminSvc, KitService } from '@/Services';
import {
  Formik,
  Form,
} from 'formik';
import * as Yup from 'yup';
import {
  FormButton,
  InputField,
  SecondaryPage,
  ContentCard,
  ContentCardHeader,
  DataList,
  DataListItem,
  ContentCardContent,
  FancyStep,
  FixedGrid
} from '@/Theme';
import {AddIcon} from '@/Theme/Icons';

const Edit = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [labKits, setLabKits] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [orgSelected, setOrgSelected] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState({});
  const [kits, setKits] = useState({});

  const fetchLabKits = async (organizationId) => {
    let response = await AdminSvc.listKits(organizationId);
    if (response && response.data) {
      setLabKits(response.data);
    }
    return response.data;
  };

  const fetchOrganizations = async () => {
    let response = await AdminSvc.searchOrganizations();
    if (response && response.data) {
      setOrganizations(response.data);
    }
  };
  const initValues = {
    organizationId: '',
    labKits: []
  }
  const handleClose = () => {
    let link = `/kit`;
    navigate(link);
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const processSubmit = async (values, { setSubmitting }) => {
    let result = await KitService.createKits(values);
    if (result.error) {
      enqueueSnackbar(result.message || 'Error while creating kits.', {
        variant: 'error',
      });
    } else {
      enqueueSnackbar('Kits created.', { variant: 'success' });
      handleClose();
    }
  };
  const form = () => {
    return (
      <Formik
        initialValues={initValues}
        onSubmit={async (values, { setSubmitting }) =>
          processSubmit(values, setSubmitting)
        }
      >
        {(props) => {
          const {
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
          } = props;

          const addKit = () => {
            if (labKits && labKits.length > 0) {
              let labKit = labKits[0];
              let tests = [...values.labKits];
              tests.push({ id: labKit.id, quantity: 1 });
              setFieldValue('labKits', tests);
            }
          };
          const handleOrgSelected = async (event) => {
            setOrgSelected(true);
            let organizationId = event.target.value;
            let labKitPanels = await fetchLabKits(organizationId);
            setFieldValue('organizationId', organizationId);
            if (labKitPanels && labKitPanels.length > 0) {
              let labKit = labKitPanels[0];
              setFieldValue('labKits', [{ id: labKit.id, quantity: 1 }]);
            }
            setActiveStep(1);
            let org = organizations.find((o) => o.id === organizationId);
            setSelectedOrg(org);
          };
          return (
            <Form onSubmit={handleSubmit} autoComplete='nope'>
              <SecondaryPage
                title='New Kits'
                back='/kit'
                actions={
                  <>
                    <FormButton onClick={handleClose}>Cancel</FormButton>
                  </>
                }
              >
                <Grid container spacing={2} className='flex'>
                  <FixedGrid item sm={12}>
                    <ContentCard border>
                      <ContentCardContent>
                        <Stepper
                          nonLinear
                          orientation='vertical'
                          activeStep={activeStep}
                        >
                          <FancyStep key='organizationStep' expanded>
                            <StepButton>
                              <Typography variant='h5'>Organization</Typography>
                            </StepButton>
                            <StepContent>
                              <ContentCard>
                                <CardContent>
                                  <InputField
                                    sx={{ width: '300px' }}
                                    className='flex-9'
                                    field='organizationId'
                                    value={values.organizationId}
                                    label='Select an organization for the order'
                                    props={props}
                                    select
                                    onChange={(e) => {
                                      handleChange(e);
                                      handleOrgSelected(e);
                                    }}
                                  >
                                    {organizations.map((option) => (
                                      <MenuItem
                                        key={option.id}
                                        value={option.id}
                                      >
                                        {option.name}
                                      </MenuItem>
                                    ))}
                                  </InputField>
                                </CardContent>
                              </ContentCard>
                            </StepContent>
                          </FancyStep>
                          <FancyStep
                            key='testStep'
                            disabled={!orgSelected}
                            expanded={orgSelected}
                          >
                            <div className='flex flex-row-sc justify-between'>
                              <StepButton
                                onClick={handleStep(1)}
                                className='flex '
                              >
                                <Typography variant='h5'>
                                  Lab Kits
                                </Typography>
                              </StepButton>
                              <FormButton
                                sx={{
                                  display: orgSelected ? 'inherit' : 'none',
                                }}
                                compact
                                variant='outlined'
                                startIcon={<AddIcon size='small' />}
                                onClick={() => addKit()}
                              >
                                Add More Lab Kit
                              </FormButton>
                            </div>
                            <StepContent>
                              <ContentCard>
                                <CardContent>
                                  {values.labKits.map((test, index) => (
                                    <div
                                      className='flex flex-row-cc justify-between '
                                      key={`labKits.${'00' + index}.text`}
                                    >
                                      <InputField
                                        className='flex-9'
                                        field={`labKits[${index}].id`}
                                        value={values.labKits[index].id}
                                        label='Lab Kit'
                                        props={props}
                                        select
                                      >
                                        {labKits.map((option) => (
                                          <MenuItem
                                            key={option.id}
                                            value={option.id}
                                          >
                                            {option.name}
                                          </MenuItem>
                                        ))}
                                      </InputField>
                                      <span className='flex-1' />
                                      <InputField
                                        className='flex-2'
                                        field={`labKits[${index}].quantity`}
                                        value={values.labKits[index].quantity}
                                        label='Quantity'
                                        props={props}
                                      />
                                    </div>
                                  ))}
                                </CardContent>
                                <CardActions>
                                  <FormButton primary type='submit'>
                                    Create Kits
                                  </FormButton>
                                </CardActions>
                              </ContentCard>
                            </StepContent>
                          </FancyStep>
                        </Stepper>
                      </ContentCardContent>
                    </ContentCard>
                  </FixedGrid>
                </Grid>
              </SecondaryPage>
            </Form>
          );
        }}
      </Formik>
    );
  };

  return form();
};
export default Edit;
