import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  FormButton,
  InputField,
  SecondaryPage,
  ContentCard,
  DataList,
  DataListItem,
  ContentCardContent,
} from '@/Theme';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { CardActions } from '@mui/material';
import {
  MenuItem,
  CardContent,
  Grid,
  StepContent,
  Stepper,
  StepButton,
  Typography,
} from '@mui/material';
import { LabService, KitService } from '@/Services';
import { useSnackbar } from 'notistack';
import { FixedGrid, FancyStep } from './Style';

const New = () => {
  const [kit, setKit] = useState(null);
  const [labKit, setLabKit] = useState(null);
  const [organization, setOrganization] = useState({});
  const [validKit, setValidKit] = useState(false);
  const [kitResultMsg, setKitResultMsg] = useState(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleBackToHome = () => {
    navigate('/lab');
  };
  const fetchKit = async (code) => {
    let response = await KitService.findKit(code.trim());
    console.log({ response });
    if (response && response.data && response.data.id) {
      console.log(response.data);
      let result = response.data;
      if (result && result.id && !result.used) {
        setKit(result);
        setLabKit(result.labKit);
        setOrganization(result.organization);
        setValidKit(true);
        setKitResultMsg('Found lab kit for given registration code.');
      } else {
        setKitResultMsg(
          'Kit not found or already been registered.  Please verify registration code.'
        );
      }
    }
  };
  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  useEffect(() => {
    let code = searchParams.get('code');
    if (code) {
      fetchKit(code);
    }
    // fetchFields();
  }, []);

  const initValues = {
    code: searchParams.get('code') || '',
    organizationId: '',
    kitId: '',
    firstName: '',
    lastName: '',
    middleName: '',
    dob: '',
    gender: '',
    passport: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    postalCode: '',
    email: '',
    phone: '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().trim().required('First Name is required'),
    lastName: Yup.string().trim().required('Last Name is required'),
    middleName: Yup.string().trim(),
    dob: Yup.string().trim().required('Date of Birth is required'),
    gender: Yup.string().required('Gender is required'),
    passport: Yup.string().trim(),
    street1: Yup.string().trim(),
    street2: Yup.string().trim(),
    city: Yup.string().trim(),
    state: Yup.string().trim(),
    postalCode: Yup.string().trim(),
    email: Yup.string().trim().email(),
    home: Yup.string().trim(),
    mobile: Yup.string().trim(),
  });

  const handleSubmit = async (values) => {
    // values.kitId = kit.id;
    // values.organizationId = organization.id;
    console.log('submitting', values);
    const registrationData = {
      registrationCode: values.code,
      labKitId: kit.labKitId,
      organizationId: organization.id,
      patient: {
        id: values.patientId,
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        dob: values.dob,
        assignedSex: values.gender,
        street1: values.street1,
        street2: values.street2,
        city: values.city,
        state: values.state,
        postalCode: values.postalCode,
        email: values.email,
        phone: values.phone,
      },
    };
    const response = await LabService.register(registrationData);
    console.log({ response });
    if (response) {
      enqueueSnackbar('Lab order registered', { variant: 'success' });
      handleBackToHome();
    }
  };

  const form = () => {
    return (
      <Formik
        initialValues={initValues}
        onSubmit={async (values) => {
          validationSchema
            .validate(values)
            .then(async (valid) => {
              if (valid) {
                handleSubmit(values);
              }
            })
            .catch((err) => {
              console.log(err);
              enqueueSnackbar(err.errors, { variant: 'error' });
            });
        }}
      >
        {(props) => {
          const { values, setValues } = props;
          const findKit = async () => {
            let code = values.code.trim();
            await fetchKit(code);
          };
          return (
            <Form>
              <SecondaryPage
                title='Register Lab Order'
                back='/lab'
                style={{ overflow: 'visible' }}
                actions={
                  <>
                    <FormButton onClick={handleBackToHome}>Cancel</FormButton>
                  </>
                }
              >
                <Grid container spacing={2} className='flex'>
                  <FixedGrid item sm={12} className=''>
                    <ContentCard border>
                      <ContentCardContent>
                        <Stepper
                          nonLinear
                          orientation='vertical'
                          activeStep={activeStep}
                        >
                          <FancyStep key='organizationStep' expanded>
                            <StepButton>
                              <Typography variant='h5'>Find Kit</Typography>
                            </StepButton>
                            <StepContent>
                              <Grid container spacing={0}>
                                <Grid item sm={4}>
                                  <ContentCard>
                                    <CardContent>
                                      <InputField
                                        field='code'
                                        label='Registration Code'
                                        props={props}
                                      />
                                    </CardContent>
                                    <CardActions>
                                      <FormButton
                                        compact
                                        primary
                                        onClick={findKit}
                                      >
                                        Find Kit
                                      </FormButton>
                                    </CardActions>
                                  </ContentCard>
                                </Grid>
                                <Grid item sm={4}>
                                  {kitResultMsg && (
                                    <Typography variant='h5'>
                                      {kitResultMsg}
                                    </Typography>
                                  )}
                                  {labKit && labKit.id && (
                                    <>
                                      <DataList>
                                        <DataListItem
                                          label='Lab Kit'
                                          value={labKit.name}
                                        />
                                        <DataListItem
                                          label='Organization'
                                          value={organization.name}
                                        />
                                      </DataList>
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                            </StepContent>
                          </FancyStep>
                          <FancyStep
                            key='customerStep'
                            disabled={!validKit}
                            expanded={validKit}
                          >
                            <div className='flex flex-row-sc justify-between'>
                              <StepButton
                                onClick={handleStep(2)}
                                className='flex '
                              >
                                <Typography variant='h5'>
                                  Patient Detail
                                </Typography>
                              </StepButton>
                            </div>
                            <StepContent>
                              <ContentCard sx={{ maxWidth: '500px' }}>
                                <CardContent>
                                  <InputField
                                    field='firstName'
                                    label='First Name'
                                    props={props}
                                    value={values.firstName}
                                  />
                                  <InputField
                                    field='middleName'
                                    label='Middle Name'
                                    props={props}
                                    value={values.middleName}
                                  />
                                  <InputField
                                    field='lastName'
                                    label='Last Name'
                                    props={props}
                                    value={values.lastName}
                                  />
                                  <InputField
                                    field='dob'
                                    label='Date of Birth'
                                    props={props}
                                    value={values.dob}
                                  />
                                  <InputField
                                    field='gender'
                                    label='Gender'
                                    value={values.gender}
                                    props={props}
                                    select
                                  >
                                    <MenuItem value=''>Select</MenuItem>
                                    <MenuItem value='male'>Male</MenuItem>
                                    <MenuItem value='female'>Female</MenuItem>
                                  </InputField>
                                  <InputField
                                    field='passport'
                                    label='Passport'
                                    props={props}
                                    value={values.passport}
                                  />
                                  <InputField
                                    field='email'
                                    label='Email'
                                    props={props}
                                    value={values.email}
                                  />
                                  <InputField
                                    field='phone'
                                    label='Phone'
                                    props={props}
                                    value={values.phone}
                                  />
                                  <InputField
                                    field='street1'
                                    label='Steet 1'
                                    props={props}
                                    value={values.street1}
                                  />
                                  <InputField
                                    field='street2'
                                    label='Street 2'
                                    props={props}
                                    value={values.street2}
                                  />
                                  <InputField
                                    field='city'
                                    label='City'
                                    props={props}
                                    value={values.city}
                                  />
                                  <InputField
                                    field='state'
                                    label='State'
                                    props={props}
                                    value={values.state}
                                  />
                                  <InputField
                                    field='postalCode'
                                    label='Zip Code'
                                    props={props}
                                    value={values.postalCode}
                                  />
                                </CardContent>
                                <CardActions>
                                  <FormButton type='submit' primary>
                                    Register
                                  </FormButton>
                                </CardActions>
                              </ContentCard>
                            </StepContent>
                          </FancyStep>
                        </Stepper>
                      </ContentCardContent>
                    </ContentCard>
                  </FixedGrid>
                </Grid>
              </SecondaryPage>
            </Form>
          );
        }}
      </Formik>
    );
  };
  return form();
};

export default New;
