import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { WINDOW_DIMENSION } from '@/Redux/action/types';


const getWindowDimensions = () => {
  const {innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height
	};
};

const useWindowDimension = () => {
  const dispatch = useDispatch();

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      let dimensions = getWindowDimensions();
      setWindowDimensions(dimensions);
      dispatch({type:WINDOW_DIMENSION, dimensions});
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowDimensions;
};

export default useWindowDimension;
