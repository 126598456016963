import BaseSvc from './base-service';
import axios from 'axios';

/**
 * Kit Order Service
 */
class OrderService {

	constructor() {
		this.authConfig = {};
		if (!OrderService.instance) {
			this.authConfig = {};
			OrderService.instance = this;
		}
		return OrderService.instance;
	}

	async search(filter){
		console.log("#### OrderService#search", filter)
		let response = await BaseSvc.secureGet("/kit/order", filter);
		return response;
	}

	async save(data){
		console.log("#### OrderService#save", data)
		let response = await BaseSvc.securePut("/kit/order", data)
		return response;
	}

	async saveShipping(data) {
		console.log('#### OrderService#saveShipping', data);
		let response = await BaseSvc.securePut(`/kit/order/shipping`, data);
		return response.data;
	  }

	async newOrder(data){
		console.log("#### OrderService#newOrder", data)
		let response = await BaseSvc.securePost('/kit/order', data);
		return response;
	}
	
	async getOrder(id){
		console.log("#### OrderService#getOrder", id)
		let response = await BaseSvc.secureGet("/kit/order/"+id);
		return response;
	}

	async createLabel(id, data){
		let response = await BaseSvc.securePost("/kit/order/"+id + "/label", data);
		return response;
	};

	async getZPLContent(id, type, shippingId){
		let params = {type: type, shippingId: shippingId}
		let response = await BaseSvc.secureGet("/kit/order/"+id + "/label", params);
		return response;
	};

	async handlePrintLabel(order, type, shippingId){
		let zpl = await this.getZPLContent(order.id, type, shippingId);
		let formData = new FormData();
		formData.append('file', zpl.data);
		let rsp = await axios.post('https://api.labelary.com/v1/printers/8dpmm/labels/4x6/0/', formData, { responseType: 'blob', headers: { 'Accept': 'application/pdf' } });
		let uri = URL.createObjectURL(rsp.data);
		window.open(uri, '_blank');
	};

  handleDownloadLabel(shipping){
    if (shipping && shipping.labelUrl) {
      window.open(shipping.labelUrl, '_blank');
    }
  };

	async updateStatus(order, status){
    order.status = status;
    let response = await this.save(order);
  };


}

const instance = new OrderService();
Object.freeze(instance);
export default instance;
