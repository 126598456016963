import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from 'notistack';
import { OrderService } from '@/Services';
import {
  Formik,
  Form,
  useField,
  Field,
  ErrorMessage,
  validateYupSchema,
} from 'formik';
import * as Yup from 'yup';
import {
  RightDrawer,
  FormButton,
  FormTextField,
  InputField,
  DrawerCardContent,
  DrawerCardActions,
  FormMenuItem,
} from '@/Theme';

const Edit = ({
  openDrawer,
  setOpenDrawer,
  selectedItem,
  refreshList,
  inputVariables,
}) => {
  console.log('### Selected item for edit: ', selectedItem);
  const { enqueueSnackbar } = useSnackbar();

  // const sessionRole = Session.getRole();
  const navigate = useNavigate();
  const handleClose = () => {
    setOpenDrawer(false);
    refreshList();
  };
  const initValues = () => {
    return {
      id: selectedItem.shippingAddress.id,
      epId: selectedItem.shippingAddress.epId || '',
      name: selectedItem.shippingAddress.name || '',
      phone: selectedItem.shippingAddress.phone || '',
      email: selectedItem.shippingAddress.email || '',
      street1: selectedItem.shippingAddress.street1 || '',
      street2: selectedItem.shippingAddress.street2 || '',
      city: selectedItem.shippingAddress.city || '',
      state: selectedItem.shippingAddress.state || '',
      postalCode: selectedItem.shippingAddress.postalCode || '',
      country: selectedItem.shippingAddress.country || ''
    };
  };
  const form = () => {
    return (
      <Formik
        initialValues={initValues()}
        validationSchema={Yup.object().shape({
          name: Yup.string('Enter test type name').required('Name is required'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          console.log('##### save', values);
          let saved = await OrderService.saveShipping({
            id: values.id,
            epId: values.epId,
            name: values.name,
            phone: values.phone,
            email: values.email,
            street1: values.street1,
            street2: values.street2,
            city: values.city,
            state: values.state,
            postalCode: values.postalCode,
            country: values.country
          });
          if (saved) {
            enqueueSnackbar('Shipping info saved.', { variant: 'success' });
            handleClose();
          }
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
          } = props;
          const handleChecked = (value) => {
            setFieldValue('active', value);
          };
          const handlePanel = (value) => {
            setFieldValue('isPanel', value);
          };
          return (
            <Form onSubmit={handleSubmit} autoComplete='nope'>
              <DrawerCardContent>
                <InputField field='name' label='Name' props={props} />
                <InputField field='phone' label='Phone' props={props} />
                <InputField field='email' label='Email' props={props} />
                <InputField field='street1' label='Address 1' props={props} />
                <InputField field='street2' label='Address 2' props={props} />
                <InputField field='city' label='City' props={props} />
                <InputField field='state' label='State' props={props} />
                <InputField field='postalCode' label='Zip Code' props={props} />
                <InputField field='country' label='Country' props={props} />
                <InputField field='epId' label='Shipping ID' props={props} />
              </DrawerCardContent>
              <DrawerCardActions>
                <FormButton primary type='submit'>
                  Submit
                </FormButton>
                <FormButton onClick={handleClose}>Cancel</FormButton>
              </DrawerCardActions>
            </Form>
          );
        }}
      </Formik>
    );
  };
  console.log("#### selectedItemselectedItem", selectedItem)
  return (
    <div>
      <RightDrawer
        width={700}
        title={selectedItem.id ? 'Edit Order' : 'Add New Order'}
        handleClose={handleClose}
        open={openDrawer}
      >
        {form()}
      </RightDrawer>
    </div>
  );
};
export default Edit;
