import { CLEAR_AUTH_SESSION, SET_AUTH_SESSION } from './types'

const initialState = {
  isLoggedIn: false,
  token: ""
}

const session = (state = initialState, action) => {
	switch (action.type) {
    case SET_AUTH_SESSION: {
			return Object.assign({}, 
        {token: action.session, isLoggedIn: true});
		}
    case CLEAR_AUTH_SESSION:
      return initialState
    default:
      return state
  }
}

export default session
