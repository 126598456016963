import styled from 'styled-components';
import { ContentCard } from '@/Theme';
import { Button } from '@mui/material';

const LoginCard = styled(ContentCard)`
  && {
    min-width: 30rem;
    max-width: 30rem;
    border: 1px solid #e0e0e0;
  }
`;
const ActionContainer = styled.div`
  padding: 0.8rem 0;
`;
const RequestButton = styled(Button)`
  && {
    text-transform: lowercase;
    font-weight: 500;
    color: #0277bd;
    line-height: 1.4rem;
    padding: 0px;
    font-size: 1.4rem;
  }
`;
const NoteContainer = styled.div`
  padding: 1rem;
  color: #757575;
  font-size: 1.4rem;
`;

export { LoginCard, ActionContainer, RequestButton, NoteContainer };
