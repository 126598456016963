/** PACKAGE IMPORTS */
import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { DataTableStyles, EmptyData, LoadingData } from '@/Theme';
import { DateTime } from 'luxon';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { AdminSvc } from '@/Services';

const List = ({
  listData,
  loading,
  handleView,
  count,
  size,
  setSize,
  page,
  setPage,
  setSort,
  setDirection,
  refreshData
}) => {
  const columns = useMemo(
    () => [
      {
        name: 'Type',
        selector: row => row.type,
        sortable: true,
      },
      {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
      },
      {
        name: 'Order Code',
        selector: row => row.labOrder.labOrderCode,
        sortable: true,
      },
      {
        name: 'Kit Number',
        selector: row => row.labOrder.kitNumber,
        sortable: true,
      },
      {
        name: 'Retries',
        selector: row => row.retries,
      },
      {
        name: 'Elasped',
        selector: row => row.elasped,
      },
      {
        name: 'Created On',
        sortable: true,
        selector: row => row.created,
        cell: (row) => {
          return `${DateTime.fromISO(row.updated).toLocaleString(
            DateTime.DATETIME_SHORT
          )}`;
        },
      },
      {
        name: 'Updated On',
        sortable: true,
        selector: row => row.updated,
        cell: (row) => {
          return `${DateTime.fromISO(row.updated).toLocaleString(
            DateTime.DATETIME_SHORT
          )}`;
        },
      },
			{
				name: '',
				width: '75px',
				cell: (row) => {
					return (
						<>
						<IconButton onClick={() => deleteTask(row)}>
              <DeleteIcon />
            </IconButton>
						</>
					);
				},
			},
    ],
    []
  );
  const handlePageChange = (p) => {
    setPage(p);
  };
  const handlePerRowsChange = (perPage, p) => {
    setSize(perPage);
    setPage(p);
  };
  const handleSort = (s, d) => {
    setSort(s.selector);
    setDirection(d);
  };
  const deleteTask = async (task) => {
		let response = await AdminSvc.deleteTask(task);
    if (response){
      refreshData();
    }

	};
  return (
    <>
      {loading && listData.length === 0 && <LoadingData />}
      {listData.length > 0 && <DataTable
        className='pagination-custom'
        columns={columns}
        data={listData}
        onRowClicked={handleView}
        highlightOnHover
        pointerOnHover
        customStyles={DataTableStyles}
        paginationDefaultPage={page}
        persistTableHead
        paginationPerPage={size}
        paginationRowsPerPageOptions={[10, 15, 25, 50, 100, 200, 500]}
        pagination
        paginationServer
        paginationTotalRows={count}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        defaultSortField='created'
        defaultSortAsc={false}
        onSort={handleSort}
        sortServer
        noHeader
        noDataComponent={
          <EmptyData />
        }
        noTableHead={(listData && listData.length <= 0)}
      />}
      {!loading && !listData.length && <EmptyData entity='tasks' />}
    </>
  );
};
export default List;
