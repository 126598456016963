/** PACKAGE IMPORTS */
import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { DataTableStyles, EmptyData, LoadingData } from '@/Theme';
import PanelIcon from '@mui/icons-material/Bookmarks';
import TestIcon from '@mui/icons-material/Bookmark';
import { DateTime } from 'luxon';

const List = ({
  listData,
  loading,
  handleEdit,
  count,
  size,
  setSize,
  page,
  setPage,
  setSort,
  setDirection,
}) => {
  const columns = useMemo(
    () => [
      {
        name: 'Name',
        sort: 'name',
        selector: row => row.name,
        sortable: true,
        grow: 3,
        cell: (row) => {
          let icon = row.isPanel ? (
            <PanelIcon fontSize='large' />
          ) : (
            <TestIcon fontSize='large' />
          );
          return (
            <>
              {icon} {row.name}
            </>
          );
        },
      },
      // {
      //   name: 'Friendly Name',
      //   selector: 'displayName',
      //   sortable: true,
      //   grow: 2,
      // },
      {
        name: 'Lab Test ID',
        sort: 'externalId',
        selector: row => row.externalId,
        sortable: true,
        grow: 1,
      },
      {
        name: 'Active',
        sort: 'active',
        selector: row => row.active,
        sortable: true,
        width: '125px',
        cell: (row) => {
          return row.active ? 'Yes' : 'No';
        },
      },
      {
        name: 'Last Update',
        sort: 'updated',
        selector: row => row.updated,
        sortable: true,
        cell: (row) => {
          return `${DateTime.fromISO(row.updated).toLocaleString(
            DateTime.DATETIME_SHORT
          )}`;
        },
      },
      {
        name: 'Created On',
        sort: 'created',
        selector: row => row.created,
        sortable: true,
        cell: (row) => {
          return `${DateTime.fromISO(row.created).toLocaleString(
            DateTime.DATETIME_SHORT
          )}`;
        },
      },
    ],
    []
  );
  const handlePageChange = (p) => {
    console.log('### handlePageChange', page, '--', p);
    setPage(p);
  };
  const handlePerRowsChange = (perPage, p) => {
    console.log('### handleRowsChange', perPage, p);
    setSize(perPage);
    setPage(p);
  };
  const handleSort = (column, direction) => {
    console.log('### handleSort', column, direction);
    setSort(column.sort);
    setDirection(direction);
  };
  return (
    <>
      {loading && listData.length === 0 && <LoadingData />}
      {listData.length > 0 && <DataTable
        className='pagination-custom'
        columns={columns}
        data={listData}
        onRowClicked={handleEdit}
        highlightOnHover
        pointerOnHover
        customStyles={DataTableStyles}
        paginationDefaultPage={page}
        persistTableHead
        paginationPerPage={size}
        paginationRowsPerPageOptions={[10, 15, 25, 50, 100, 200, 500]}
        pagination
        paginationServer
        paginationTotalRows={count}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        defaultSortField='created'
        defaultSortAsc={false}
        onSort={handleSort}
        sortServer
        noHeader
        noDataComponent={
          <EmptyData />
        }
        noTableHead={(listData && listData.length <= 0)}
      />}
      {!loading && !listData.length && <EmptyData entity='tests' />}
    </>
  );
};
export default List;
