import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CardHeader from '@mui/material/CardHeader';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from 'notistack';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { AdminSvc } from '@/Services';

import {
  Formik,
  Form,
  useField,
  Field,
  ErrorMessage,
  validateYupSchema,
} from 'formik';
import * as Yup from 'yup';
import {
  RightDrawer,
  DrawerForm,
  FormButton,
  FormTextField,
  InputField,
  DrawerCardContent,
  DrawerCardActions,
  FormMenuItem,
} from '@/Theme';

const Edit = ({
  openDrawer,
  setOpenDrawer,
  selectedItem,
  refreshList,
  inputVariables,
}) => {
  console.log('### Selected item for edit: ', selectedItem);
  const { enqueueSnackbar } = useSnackbar();

  // const sessionRole = Session.getRole();
  const navigate = useNavigate();
  const handleClose = () => {
    setOpenDrawer(false);
    refreshList();
  };
  const initValues = () => {
    return {
      id: selectedItem.id,
      description: selectedItem.description || '',
      code: selectedItem.code || '',
      active: selectedItem.active || false,
    };
  };
  const validationSchema = Yup.object().shape({
    code: Yup.string().required('Code is required'),
  });
  const form = () => {
    return (
      <Formik
        initialValues={initValues()}
        onSubmit={async (values, { setSubmitting }) => {
          console.log('##### save', values);
          validationSchema
            .validate(values)
            .then(async (valid) => {
              if (valid) {
                let savedTestType = await AdminSvc.saveICDCode({
                  id: selectedItem.id,
                  code: values.code,
                  description: values.description,
                  active: values.active,
                });
                if (savedTestType) {
                  enqueueSnackbar('ICD Code saved.', { variant: 'success' });
                  handleClose();
                }
              }
            })
            .catch((err) => {
              console.log(err.errors);
              enqueueSnackbar(err.errors, { variant: 'error' });
            });
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
          } = props;
          const handleChecked = (value) => {
            setFieldValue('active', value);
          };
          return (
            <DrawerForm onSubmit={handleSubmit} autoComplete='nope'>
              <DrawerCardContent>
                <InputField field='code' label='Code' props={props} required />
                <InputField
                  field='description'
                  label='Description'
                  multiline
                  rows={4}
                  props={props}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name='active'
                      checked={values.active}
                      onChange={() => handleChecked(!values.active)}
                    />
                  }
                  label='Active'
                />
              </DrawerCardContent>
              <DrawerCardActions>
                <FormButton primary type='submit'>
                  Submit
                </FormButton>
                <FormButton onClick={handleClose}>Cancel</FormButton>
              </DrawerCardActions>
            </DrawerForm>
          );
        }}
      </Formik>
    );
  };
  return (
    <div>
      <RightDrawer
        title={selectedItem.id ? 'Edit ICD Code' : 'Add New ICD Code'}
        handleClose={handleClose}
        open={openDrawer}
      >
        {form()}
      </RightDrawer>
    </div>
  );
};
export default Edit;
