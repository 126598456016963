import React, { useEffect, useState } from 'react';
import List from './List';
import { MenuItem } from '@mui/material';
import {
  FilterBox,
  PageHeader,
  PageAction,
  SelectFilterField,
  FormButton,
  SecondaryPage,
} from '@/Theme';
import { AdminSvc } from '@/Services';
import NotificationDetail from './NotificationDetail';

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [filter, setFilter] = useState({ search: "", status: ["Failed"] });
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(50);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('created');
  const [direction, setDirection] = useState('desc');
  //For editing
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState({});

  const filters = {
    where: {},
    size: size,
    page: page,
    sort: sort,
    order: direction,
  };
  const fetch = async () => {
    setLoading(true);
    filters.where = filter;
    let response = await AdminSvc.searchNotifications(filters);
    if (response && response.data && response.data.objects) {
      const objects = response.data.objects;
      setListData(objects);
      setCount(response.data.count);
    }
    setLoading(false);
  };
  useEffect(() => {
    console.log('### fetch test types', search, size, page, sort, direction);
    fetch();
  }, [filter, size, page, sort, direction]);

  const onSearchChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };
  const onSearchPressed = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let updatedFilter = { ...filter, ...{ search: e.target.value } }
      setFilter(updatedFilter);
    }
  };
  const handleApplyFilter = () => {
    let updatedFilter = {
      ...filter,
      ...{ search: search },
    }
    setFilter(updatedFilter);
  }
  const handleViewNotification = (notification) => {
    setSelectedNotification(notification);
    setOpenDrawer(true);
  };
  const contentDrawer = () => {
    let drawerContent = '';
    if (openDrawer) {
      drawerContent = (
        <NotificationDetail
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          selectedNotification={selectedNotification}
        />
      );
    }
    return drawerContent;
  };
  return (
    <SecondaryPage title='Notifications' back='/admin/dashboard'>
      <PageHeader>
        <PageAction>
          <FilterBox
            onChange={onSearchChange} onPress={onSearchPressed}
            extend={
              <>
                <FormButton compact primary onClick={handleApplyFilter}>Filter</FormButton>
              </>
            }
          />
        </PageAction>
      </PageHeader>
      <List
        listData={listData}
        setOpenDrawer={setOpenDrawer}
        loading={loading}
        handleViewNotification={handleViewNotification}
        count={count}
        size={size}
        setSize={setSize}
        page={page}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
        direction={direction}
        setDirection={setDirection}
        refreshData={fetch}
      />
      {contentDrawer()}
    </SecondaryPage>
  );
};
export default Home;
