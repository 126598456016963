import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import PersonIcon from '@mui/icons-material/Person';
import TestIcon from '@mui/icons-material/Bookmark';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';

import {
  SecondaryPage,
  DataTableStyles,
  EmptyData,
  LoadingData,
  DataList,
  DataListItem,
  ContentCardContent,
  ContentCardHeader,
  ContentCardShadowed,
  FormButton,
} from '@/Theme';
import { Grid, Avatar, IconButton } from '@mui/material';
/** Local imports */
import Edit from './Edit';
import { CustomerService } from '@/Services';

const View = () => {
  const { id } = useParams();
  const [selectedItem, setSelectedItem] = useState({ address: {} });
  const [subTest, setSubTest] = useState({});
  const [action, setAction] = useState(null);
  const [loading, setLoading] = useState(false);
  //for editing
  const [openDrawer, setOpenDrawer] = useState(false);

  const fetch = async () => {
    setLoading(true);
    let response = await CustomerService.getCustomer(id);
    console.log('##### response', response);
    if (response && response.data) {
      setSelectedItem(response.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetch();
  }, [id]);

  const handleEdit = () => {
    setAction('edit');
    setOpenDrawer(true);
  };
  const handleAdd = () => {
    setAction('test');
    setSubTest({});
    setOpenDrawer(true);
  };
  const refresh = () => {
    fetch();
  };
  const contentDrawer = () => {
    let drawerContent = '';
    if (openDrawer && action) {
      if (action === 'edit') {
        drawerContent = (
          <Edit
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            testType={selectedItem}
            success={refresh}
          />
        );
      } else if (action === 'test') {
        drawerContent = (
          <Edit
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            testType={subTest}
            panelId={selectedItem.id}
            success={refresh}
          />
        );
      }
    }
    return drawerContent;
  };
  const navigate = useNavigate();
  const handleRowClick = (order) => {
    let link = `/kit/order/${order.id}`;
    navigate(link);
  };
  const columns = useMemo(
    () => [
      {
        name: 'ID',
        selector: row => row.id,
        sortable: true,
        grow: 2,
      },
      {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
        grow: 2,
      },
      {
        name: 'Placed on',
        selector: row => row.created,
        sortable: true,
        cell: (row) => {
          return `${DateTime.fromISO(row.created).toLocaleString(
            DateTime.DATETIME_SHORT
          )}`;
        },
      },
    ],
    []
  );
  const title = () => {
    return (
      <>
        <PersonIcon />
        <span style={{ marginLeft: '1rem' }}>
          Customer: {selectedItem.firstName} {selectedItem.lastName}
        </span>
      </>
    );
  };
  return (
    <SecondaryPage title={title()} back='/customer'>
      <ContentCardShadowed>
        <ContentCardHeader
          title='General Info'
        // action={
        // 	<FormButton primary compact onClick={handleEdit}>Edit</FormButton>
        // }
        />
        <ContentCardContent>
          <Grid container>
            <Grid item xs={6}>
              <DataList>
                <DataListItem
                  label='First Name'
                  value={selectedItem.firstName}
                />
                <DataListItem label='Last Name' value={selectedItem.lastName} />
                <DataListItem
                  label='Middle Name'
                  value={selectedItem.middleName}
                />
                <DataListItem label='Email' value={selectedItem.email} />
                <DataListItem label='Primary Phone' value={selectedItem.home} />
                <DataListItem
                  label='Mobile Phone'
                  value={selectedItem.mobile}
                />
                <DataListItem
                  label='External Id'
                  value={selectedItem.externalId}
                />

              </DataList>
            </Grid>
            <Grid item xs={6}>
              <DataList>

                <DataListItem label='Metadata' value={selectedItem.metadata} />
                <DataListItem
                  label='Street line 1 '
                  value={selectedItem.address.street1}
                />
                <DataListItem
                  label='Street line 2'
                  value={selectedItem.address.street2}
                />
                <DataListItem label='City' value={selectedItem.address.city} />
                <DataListItem
                  label='State'
                  value={selectedItem.address.state}
                />
                <DataListItem
                  label='Postal Code'
                  value={selectedItem.address.postalCode}
                />
                <DataListItem
                  label='Country'
                  value={selectedItem.address.country}
                />
              </DataList>
            </Grid>
          </Grid>
        </ContentCardContent>
      </ContentCardShadowed>
      <ContentCardShadowed group>
        <ContentCardHeader title='Kit Orders' />
        <ContentCardContent>
          <DataTable
            className='pagination-custom'
            columns={columns}
            data={selectedItem.orders}
            noHeader
            onRowClicked={handleRowClick}
            highlightOnHover
            customStyles={DataTableStyles}
            persistTableHead
            defaultSortField='name'
            defaultSortAsc={false}
          />
        </ContentCardContent>
      </ContentCardShadowed>
      {contentDrawer()}
    </SecondaryPage>
  );
};
export default View;
