import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  SecondaryPage,
  DataList,
  DataListItem,
  ContentCardContent,
  ContentCardHeader,
  ContentCardShadowed,
  FormButton,
} from '@/Theme';
import { Grid} from '@mui/material';
/** Local imports */
import APIAccesses from './APIAccesses';
import NewAPIAccess from './NewAPIAccess';
import Webhook from './Webhook';
import Webhooks from './Webhooks';
import Edit from './Edit';
import { Image } from './Style';
import { AdminSvc } from '@/Services';

const View = () => {
  const { id } = useParams();
  const [selectedItem, setSelectedItem] = useState({
    apiAccess: [],
    apiRequests: [],
  });
  const [action, setAction] = useState(null);
  const [loading, setLoading] = useState(false);
  //for editing
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState({});

  const fetch = async () => {
    setLoading(true);
    let response = await AdminSvc.getOrganization(id);
    if (response && response.data) {
      setSelectedItem(response.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetch();
  }, [id]);

  const handleEdit = () => {
    setAction('edit');
    setOpenDrawer(true);
  };
  const handleNewAPIAccess = () => {
    setAction('api');
    setOpenDrawer(true);
  };
  const handleWebhook = () => {
    setAction('webhook');
    setOpenDrawer(true);
  };
  const refresh = () => {
    fetch();
  };
  const contentDrawer = () => {
    let drawerContent = '';
    if (openDrawer && action) {
      if (action === 'edit') {
        drawerContent = (
          <Edit
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            selectedItem={selectedItem}
            success={refresh}
          />
        );
      } else if (action === 'api') {
        drawerContent = (
          <NewAPIAccess
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            selectedItem={selectedItem}
            success={refresh}
          />
        );
      } else if (action === 'webhook') {
        drawerContent = (
          <Webhook
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            selectedItem={selectedItem}
            success={refresh}
          />
        );
      }
    }
    return drawerContent;
  };
  const title = () => {
    return (
      <>
        <img
          src={selectedItem.logo}
          alt='logo'
          style={{ maxHeight: '2.5rem' }}
        />
        <span style={{ marginLeft: '1rem' }}>{selectedItem.name}</span>
      </>
    );
  };
  return (
    <SecondaryPage title={title()} back='/admin/organization'>
      <ContentCardShadowed>
        <ContentCardHeader
          title='Organization Info'
          action={
            <FormButton primary compact onClick={handleEdit}>
              Edit
            </FormButton>
          }
        />
        <ContentCardContent>
          <Grid container>
            <Grid item xs={6}>
              <DataList>
                <DataListItem label='Name' value={selectedItem.name} />
                <DataListItem
                  label='Organization ID'
                  value={selectedItem.organizationId}
                />
                <DataListItem
                  label='Location ID'
                  value={selectedItem.locationId}
                />
                <DataListItem
                  label='Code'
                  value={selectedItem.code}
                />
                <DataListItem
                  label='Kit Number Pattern'
                  value={selectedItem.kitPattern}
                />
                <DataListItem label='Time Zone' value={selectedItem.timezone} />
                <DataListItem
                  label='Active'
                  value={selectedItem.active ? 'Yes' : 'No'}
                />
                <DataListItem
                  label='External Id'
                  value={selectedItem.externalId}
                />
              </DataList>
            </Grid>
            <Grid item xs={6}>
              <Image
                src={selectedItem.logo}
                alt={selectedItem.name}
                maxHeight='10rem'
              />
            </Grid>
          </Grid>
        </ContentCardContent>
      </ContentCardShadowed>
      <ContentCardShadowed group>
        <ContentCardHeader
          title='API Access'
          action={
            <FormButton primary compact onClick={handleNewAPIAccess}>
              New
            </FormButton>
          }
        />
        <ContentCardContent>
          <APIAccesses apiAccesses={selectedItem.apiAccesses} />
        </ContentCardContent>
      </ContentCardShadowed>
      <ContentCardShadowed group>
        <ContentCardHeader
          title='Webhooks'
          action={
            <FormButton primary compact onClick={handleWebhook}>
              New
            </FormButton>
          }
        />
        <ContentCardContent>
        <Webhooks webhooks={selectedItem.webhooks} refreshData={refresh}/>
        </ContentCardContent>
      </ContentCardShadowed>
      {contentDrawer()}
    </SecondaryPage>
  );
};
export default View;
