/** PACKAGE IMPORTS */
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { DataTableStyles, EmptyData, LoadingData } from '@/Theme';
import KitTable from '@/Components/Common/KitTable';

const List = ({
  listData,
  loading,
  handleEdit,
  count,
  size,
  setSize,
  page,
  setPage,
  setSort,
  setDirection,
}) => {
  const navigate = useNavigate();

  const handleRowClick = (kit) => {
    let link = `/admin/kit/${kit.id}`;
    navigate(link);
  };
  const columns = useMemo(() => KitTable, []);
  const handlePageChange = (p) => {
    console.log('### handlePageChange', page, '--', p);
    setPage(p);
  };
  const handlePerRowsChange = (perPage, p) => {
    console.log('### handleRowsChange', perPage, p);
    setSize(perPage);
    setPage(p);
  };
  const handleSort = (column, direction) => {
    console.log('### handleSort', column, direction);
    setSort(column.sort);
    setDirection(direction);
  };
  return (
    <>
      {loading && listData.length === 0 && <LoadingData />}
      {listData.length > 0 && <DataTable
        className='pagination-custom'
        columns={columns}
        data={listData}
        onRowClicked={handleRowClick}
        highlightOnHover
        pointerOnHover
        customStyles={DataTableStyles}
        paginationDefaultPage={page}
        persistTableHead
        paginationPerPage={size}
        paginationRowsPerPageOptions={[10, 15, 25, 50, 100, 200, 500]}
        pagination
        paginationServer
        paginationTotalRows={count}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        defaultSortField='created'
        defaultSortAsc={false}
        onSort={handleSort}
        sortServer
        noHeader
        noDataComponent={
          <EmptyData />
        }
        noTableHead={(listData && listData.length <= 0)}
      />}
      {!loading && !listData.length && <EmptyData entity='kits' />}
    </>
  );
};
export default List;
