import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from 'notistack';
import { AdminSvc } from '@/Services';

import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  RightDrawer,
  DrawerForm,
  FormButton,
  InputField,
  DrawerCardContent,
  DrawerCardActions,
} from '@/Theme';

const NewAPIAccess = ({ openDrawer, setOpenDrawer, selectedItem, success }) => {
  console.log('### Selected item for edit: ', selectedItem);
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const handleClose = () => {
    setOpenDrawer(false);
    success();
  };
  const initValues = () => {
    return {
      organizationId: selectedItem.id,
      ips: '',
      description: '',
      system: '',
      production: true,
      active: true,
    };
  };
  const form = () => {
    return (
      <Formik
        initialValues={initValues()}
        onSubmit={async (values, { setSubmitting }) => {
          console.log('##### save', values);
          let savedAPIAccess = await AdminSvc.createNewAPIAccess(
            selectedItem.id,
            {
              organizationId: selectedItem.id,
              ips: values.ips,
              description: values.description,
              system: values.system,
              production: values.production,
              active: true,
            }
          );
          if (savedAPIAccess && !savedAPIAccess.error) {
            enqueueSnackbar('New API Access created.', { variant: 'success' });
            handleClose();
          } else {
            enqueueSnackbar('Failed to create new API access.', {
              variant: 'error',
            });
          }
        }}
      >
        {(props) => {
          const { values, handleSubmit, setFieldValue } = props;
          const handleChecked = (value) => {
            setFieldValue('production', value);
          };
          return (
            <DrawerForm onSubmit={handleSubmit} autoComplete='nope'>
              <DrawerCardContent>
                <InputField
                  field='ips'
                  label='White-listed IPs'
                  props={props}
                />
                <InputField
                  field='description'
                  label='Description'
                  multiline
                  rows={4}
                  props={props}
                />
                <InputField
                  field='system'
                  label='System or domain'
                  props={props}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name='production'
                      value={values.production}
                      checked={values.production}
                      onChange={() => handleChecked(!values.production)}
                    />
                  }
                  label='Production'
                />
              </DrawerCardContent>
              <DrawerCardActions>
                <FormButton primary type='submit'>
                  Submit
                </FormButton>
                <FormButton onClick={handleClose}>Cancel</FormButton>
              </DrawerCardActions>
            </DrawerForm>
          );
        }}
      </Formik>
    );
  };
  return (
    <div>
      <RightDrawer
        title='Create New API Access Key'
        handleClose={handleClose}
        open={openDrawer}
      >
        {form()}
      </RightDrawer>
    </div>
  );
};
export default NewAPIAccess;
