import React, { useEffect, useState } from 'react';
import List from './List';
import Edit from './Edit';
import View from './View';
import {
  FilterBox,
  PageHeader,
  PageAction,
  PrimaryButton,
  SecondaryPage,
} from '@/Theme';
import { AdminSvc } from '@/Services';

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [filter, setFilter] = useState('');
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('name');
  const [direction, setDirection] = useState('desc');
  //For editing
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawer, setDrawer] = useState('');
  const [selectedItem, setSelectedItem] = useState({});

  const filters = {
    where: {},
    size: size,
    page: page,
    sort: sort,
    order: direction,
  };
  const fetch = async () => {
    setLoading(true);
    let response = await AdminSvc.searchSpecimens();
    if (response && response.data) {
      setListData(response.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    console.log('### fetch test types', search, size, page, sort, direction);
    fetch();
  }, [filter, size, page, sort, direction]);

  const onSearchChange = (e) => {
    e.preventDefault();
    console.log('### in onSearchChange', e.target.value);
    setSearch(e.target.value);
  };
  const onSearchPressed = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      console.log('### in onSearchChange', e.target.value);
      setFilter(e.target.value);
    }
  };
  const handleView = (item) => {
    setSelectedItem(item);
    setDrawer('view');
    setOpenDrawer(true);
  };
  const handleEdit = (item) => {
    setSelectedItem(item);
    setDrawer('new');
    setOpenDrawer(true);
  };
  const handleNew = () => {
    setSelectedItem({});
    setDrawer('new');
    setOpenDrawer(true);
  };
  const contentDrawer = () => {
    let drawerContent = '';
    if (openDrawer) {
      if (drawer === 'new') {
        drawerContent = (
          <Edit
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            selectedItem={selectedItem}
            refreshList={fetch}
            inputVariables={filters}
          />
        );
      } else if (drawer === 'view') {
        drawerContent = (
          <View
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            selectedItem={selectedItem}
            refreshList={fetch}
            inputVariables={filters}
          />
        );
      }
    }
    return drawerContent;
  };
  return (
    <SecondaryPage title='Specimen Types' back='/admin/dashboard'>
      <PageHeader>
        <PageAction>
          <FilterBox onChange={onSearchChange} onPress={onSearchPressed} />
        </PageAction>
        <PrimaryButton onClick={handleNew}>New Specimen Type</PrimaryButton>
      </PageHeader>
      <List
        listData={listData}
        setOpenDrawer={setOpenDrawer}
        handleView={handleView}
        handleEdit={handleEdit}
        loading={loading}
        count={count}
        size={size}
        setSize={setSize}
        page={page}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
        direction={direction}
        setDirection={setDirection}
      />
      {contentDrawer()}
    </SecondaryPage>
  );
};
export default Home;
