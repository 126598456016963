import BaseSvc from './base-service';

class ShippingService {
  constructor() {
    this.authConfig = {};
    if (!ShippingService.instance) {
      this.authConfig = {};
      ShippingService.instance = this;
    }
    return ShippingService.instance;
  }

  async search(params) {
    console.log('#### ShippingService#search');
    console.log({ params });
    let response = await BaseSvc.secureGet('/shipping', params);
    return response;
  }

  async save(data) {
    console.log('#### ShippingService#save', data);
    let response = await BaseSvc.securePost('/shipping', data);
    return response.data;
  }
}

const instance = new ShippingService();
Object.freeze(instance);
export default instance;
