/** PACKAGE IMPORTS */
import React, { useEffect } from 'react';
import { PrimaryPage } from '@/Theme';
import { Redux as WebAuth} from '@ihdlab/web-auth';
import {UserSvc} from '@/Services'

const Auth = WebAuth.Auth;

const Public = () => {
  const token = Auth.getToken();
  const isLoggedIn = Auth.isLoggedIn();
  const user = UserSvc.me();
  return (
    <>
      <PrimaryPage title="Dashboard">
        <h1>Dashboard</h1>
        <div>
          is loggedin ? {isLoggedIn ? 'yes' :'no'}
          <br/>
          <pre>{token}</pre>
          <pre>{JSON.stringify(user, null, 2)}</pre>
        </div>
      </PrimaryPage>
    </>
  );
};
export default Public;
