import React, { useState, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from 'notistack';
import { AdminSvc } from '@/Services';

import {
  Formik,
  Form,
  useField,
  Field,
  ErrorMessage,
  validateYupSchema,
} from 'formik';
import * as Yup from 'yup';
import {
  RightDrawer,
  FormButton,
  FormTextField,
  InputField,
  DrawerCardContent,
  DrawerCardActions,
  FormMenuItem,
} from '@/Theme';

const Edit = ({
  openDrawer,
  setOpenDrawer,
  selectedItem,
  refreshList,
  inputVariables,
}) => {
  console.log('### Selected item for edit: ', selectedItem);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpenDrawer(false);
    refreshList();
  };
  const initValues = () => {
    return {
      id: selectedItem.id,
      name: selectedItem.name || '',
      description: selectedItem.description,
      code: selectedItem.code,
      active: selectedItem.active,
    };
  };
  const form = () => {
    return (
      <Formik
        initialValues={initValues()}
        validationSchema={Yup.object().shape({
          name: Yup.string('Enter test type name').required('Name is required'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          console.log('##### save', values);
          let savedTestType = await AdminSvc.saveSpecimenType({
            id: selectedItem.id,
            name: values.name,
            description: values.description,
            code: values.code,
            active: values.active,
          });
          if (savedTestType) {
            enqueueSnackbar('Specimen type saved.', { variant: 'success' });
            handleClose();
          }
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
          } = props;
          const handleChecked = (value) => {
            setFieldValue('active', value);
          };
          return (
            <Form onSubmit={handleSubmit} autoComplete='nope'>
              <DrawerCardContent>
                <InputField field='name' label='Name' required props={props} />
                <InputField
                  field='description'
                  label='Description'
                  props={props}
                />
                <InputField field='code' label='Code' props={props} />
                <FormControlLabel
                  control={
                    <Checkbox
                      name='active'
                      checked={values.active}
                      onChange={() => handleChecked(!values.active)}
                    />
                  }
                  label='Active'
                />
              </DrawerCardContent>
              <DrawerCardActions>
                <FormButton primary type='submit'>
                  Save
                </FormButton>
                <FormButton onClick={handleClose}>Cancel</FormButton>
              </DrawerCardActions>
            </Form>
          );
        }}
      </Formik>
    );
  };
  return (
    <div>
      <RightDrawer
        title={selectedItem.id ? 'Edit Specimen Type' : 'Add New Specimen Type'}
        handleClose={handleClose}
        open={openDrawer}
      >
        {form()}
      </RightDrawer>
    </div>
  );
};
export default Edit;
