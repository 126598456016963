/** PACKAGE IMPORTS */
import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { DataTableStyles, EmptyData, LoadingData } from '@/Theme';
import { Avatar, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';

const List = ({
  listData,
  loading,
  handleView,
  handleEdit,
  count,
  size,
  setSize,
  page,
  setPage,
  setSort,
  setDirection,
}) => {
  // users = [];
  const columns = useMemo(
    () => [
      {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
        grow: 2,
      },
      {
        name: 'Code',
        selector: row => row.code,
        sortable: true,
        grow: 2,
      },
      {
        name: 'Description',
        selector: row => row.description,
        sortable: true,
        grow: 2,
      },
      {
        name: 'Active',
        selector: row => row.active,
        sortable: true,
        width: '75px',
        cell: (row) => {
          return row.active ? 'Yes' : 'No';
        },
      },
    ],
    []
  );
  const handlePageChange = (p) => {
    console.log('### handlePageChange', page, '--', p);
    setPage(p);
  };
  const handlePerRowsChange = (perPage, p) => {
    console.log('### handleRowsChange', perPage, p);
    setSize(perPage);
    setPage(p);
  };
  const handleSort = (sort, direction) => {
    console.log('### handleSort', sort, direction);
    setSort(sort.selector);
    setDirection(direction);
  };
  return (
    <>
      {loading && listData.length === 0 && <LoadingData />}
      {listData.length > 0 && (
        <DataTable
          className='pagination-custom'
          columns={columns}
          data={listData}
          noHeader
          onRowClicked={handleEdit}
          highlightOnHover
          customStyles={DataTableStyles}
          persistTableHead
          paginationPerPage={size}
          pagination={false}
          defaultSortField='name'
          defaultSortAsc={false}
          onSort={handleSort}
        />
      )}
      {!loading && !listData.length && <EmptyData />}
    </>
  );
};
export default List;
