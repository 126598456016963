import React from 'react';
import CardHeader from '@mui/material/CardHeader';
/** Local imports */
import {
  RightDrawer,
  DrawerCard,
  DataList,
  DataListItem,
  DrawerCardContent,
  DrawerCloseButton,
} from '@/Theme';
import { Grid } from '@mui/material';

const View = ({ openDrawer, setOpenDrawer, selectedItem }) => {
  const handleClose = () => {
    setOpenDrawer(false);
  };
  const content = () => {
    return (
      <>
        <DrawerCardContent>
          <Grid container>
            <Grid item xs={6}>
              <DataList>
                <DataListItem label='Name' value={selectedItem.name} />
                <DataListItem
                  label='Description'
                  value={selectedItem.description}
                />
                {/* <DataListItem
                  label='Patient Test Name'
                  value={selectedItem.displayName}
                />
                <DataListItem
                  label='Patient Test Description'
                  value={selectedItem.displayDescription}
                /> */}
                <DataListItem
                  label='External Id'
                  value={selectedItem.externalId}
                />
                <DataListItem label='Category' value={selectedItem.category} />
                <DataListItem label='Color' value={selectedItem.color} />
                <DataListItem
                  label='Active'
                  value={selectedItem.active ? 'Yes' : 'No'}
                />
                <DataListItem
                  label='Is Panel'
                  value={selectedItem.isPanel ? 'Yes' : 'No'}
                />
                <DataListItem label='Panel ID' value={selectedItem.panelId} />
              </DataList>
            </Grid>
            <Grid item xs={6}>
              <DataList>
                <DataListItem
                  label='Duration (minutes)'
                  value={selectedItem.duration}
                />
                <DataListItem
                  label='Result Ready Time (minutes)'
                  value={selectedItem.resultReady}
                />
                <DataListItem
                  label='Result Range From'
                  value={selectedItem.resultRangeFrom}
                />
                <DataListItem
                  label='Result Range To'
                  value={selectedItem.resultRangeTo}
                />
                <DataListItem
                  label='Length (inches)'
                  value={selectedItem.length}
                />
                <DataListItem
                  label='Width (inches)'
                  value={selectedItem.width}
                />
                <DataListItem
                  label='Height (inches)'
                  value={selectedItem.height}
                />
                <DataListItem label='Weight (oz)' value={selectedItem.weight} />
              </DataList>
            </Grid>
          </Grid>
        </DrawerCardContent>
      </>
    );
  };
  return (
    <div>
      <RightDrawer
        title={selectedItem.name}
        width={600}
        open={openDrawer}
        handleClose={handleClose}
      >
        {content()}
      </RightDrawer>
    </div>
  );
};
export default View;
