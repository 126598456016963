/** PACKAGE IMPORTS */
import React, { useEffect, useState } from 'react';
import { Redux as WebAuth } from '@ihdlab/web-auth';
import { PrimaryPage, ContentCardShadowed, NavListItem, SecondaryPage } from '@/Theme';
import {
  Grid,
  ListItemIcon,
  List,
  ListItemText,
  CardHeader,
  CardContent,
} from '@mui/material';
import { UserSvc, BaseSvc } from '@/Services';

const Auth = WebAuth.Auth;



const Profile = () => {

  const [dataStatus, setDataStatus] = useState(false);
  const [userData, setuserData] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");

  const getUserData = async () => {
    const UserData = await UserSvc.retriveProfile();
    let userName = UserData.data.first_name + " " + UserData.data.last_name;
    let userEmail = UserData.data.email;
    let userRole = UserData.data.role;

    setName(userName);
    setEmail(userEmail);
    setRole(userRole);
    setDataStatus(true);
  }

  getUserData();
  if (dataStatus) {
    return (
      <>
        <SecondaryPage title="Profile">
          <h1>Profile</h1>
          <br />
          <Grid container spacing={3}>
            <Grid item md={4}>
              <ContentCardShadowed>
                <CardHeader title='Name' />
                <CardContent>
                  {name}
                </CardContent>
                <CardHeader title='Email' />
                <CardContent>{email}</CardContent>

                <CardHeader title='Permissions' />
                <CardContent>{role}</CardContent>
              </ContentCardShadowed>
            </Grid>
          </Grid>
        </SecondaryPage>
      </>
    );
  } else {
    return <h1>Loading......</h1>
  }

  // return (
  //   <>
  //     <SecondaryPage title="Profile">
  //       <h1>Profile</h1>

  //       <br />
  //       <Grid container spacing={3}>
  //         <Grid item md={4}>
  //           <ContentCardShadowed>
  //             <CardHeader title='Name' />
  //             <CardContent>
  //               'dfdf'
  //             </CardContent>
  //             <CardHeader title='Email' />
  //             <CardContent>.... activities ....</CardContent>

  //             <CardHeader title='Permissions' />
  //             <CardContent>.... activities ....</CardContent>
  //           </ContentCardShadowed>
  //         </Grid>
  //       </Grid>
  //     </SecondaryPage>
  //   </>
  // );
};
export default Profile;
