/** PACKAGE IMPORTS */
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import {
  DataTableStyles, EmptyData, LoadingData,
  DisplayList
} from '@/Theme';
import { DateTime } from 'luxon';
import { ListItem, ListItemText, IconButton, Tooltip } from '@mui/material';
import { PrintIcon, TodoIcon, TodoCompletedIcon, EditIcon, DownloadIcon, DeliveryIcon, ReturnIcon } from '@/Theme/Icons'
import { OrderService } from '@/Services';

const List = ({
  listData,
  loading,
  testColumns,
  labloadingNew,
  listOrderDataNew,
  handleUpdateStatus
}) => {
  const navigate = useNavigate();

  const handleRowClick = (order) => {
    let link = `/kit/order/${order.id}`;
    navigate(link, { replace: true });
  };

  const colTest = useMemo(
    () => testColumns,
    []
  );
  let myColumn;



  const columnsLabOrder = useMemo(
    () => [
      {
        name: 'Registration Code',
        sortable: true,
        grow: 2,
        cell: (row) => {
          return (
            <>
              {row.labOrderCode &&
                (<DisplayList sx={{ width: "100%" }} labelWidth={8} contentWidth={4} padding='0'>
                  <ListItem >
                    <ListItemText
                      secondary={row.kitNumber}
                    />
                  </ListItem>

                </DisplayList>
                )}
            </>
          );
        },
      },
      {
        name: 'Code',
        sortable: true,
        grow: 3,
        cell: (row) => {
          return (
            <>
              {row.labOrderCode &&
                (<DisplayList sx={{ width: "100%" }} labelWidth={8} contentWidth={4} padding='0'>
                  <ListItem >
                    <ListItemText
                      secondary={row.labOrderCode}
                    />
                  </ListItem>

                </DisplayList>
                )}
            </>
          );
        },
      },
      {
        name: 'Lab Kit',
        sortable: true,
        grow: 3,
        cell: (row) => {
          return (
            <>
              {row.labOrderCode &&
                (<DisplayList sx={{ width: "100%" }} labelWidth={8} contentWidth={4} padding='0'>
                  <ListItem >
                    <ListItemText
                      secondary={row.labKit ? row.labKit.name : ""}
                    />
                  </ListItem>

                </DisplayList>
                )}
            </>
          );
        },
      },
      {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
        width: "75px"
      },
      {
        name: 'Last Update',
        sortable: true,
        selector: row => row.updated,
        cell: (row) => {
          return `${DateTime.fromISO(row.updated).toLocaleString(
            DateTime.DATETIME_SHORT
          )}`;
        },
      },
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        name: 'Customer',
        sortable: true,
        grow: 2,
        cell: (row) => {
          return (
            <>
              {row.customer &&
                (<DisplayList key={row.id + "-customer"} sx={{ width: "100%" }} labelWidth={8} contentWidth={4} padding='0'>
                  <ListItem key={row.id + row.customer.id + "-profile"} onClick={() => handleRowClick(row)}>
                    <ListItemText
                      secondary={row.customer.firstName + ' ' + row.customer.lastName}
                    />
                  </ListItem>
                  <ListItem key={row.id + row.customer.id + "-address"} onClick={() => handleRowClick(row)}>
                    <ListItemText
                      primary={
                        row.shippingAddress.street1 + ' ' +
                        (row.shippingAddress.street2 ? row.shippingAddress.street2 : "") + ", " +
                        row.shippingAddress.state + " " + row.shippingAddress.postalCode
                      }
                    />
                  </ListItem>
                </DisplayList>
                )}


            </>
          );
        },
      },
      {
        name: 'Line Items',
        sortable: true,
        grow: 3,
        cell: (row) => {
          return (
            <>
              {row.lineItems &&
                (<DisplayList key={row.id + "-lineItems"} sx={{ width: "100%" }} labelWidth={9} contentWidth={3} padding='0'>
                  {row.lineItems.map((lineItem) => {
                    return (
                      <div key={row.id + lineItem.id + "-detail"}>
                        <ListItem key={row.id + lineItem.id + "-info"} onClick={() => handleRowClick(row)}>
                          <ListItemText
                            primary={
                              lineItem.kit.name
                            }
                            secondary={
                              "x " + lineItem.quantity
                            }
                          />
                        </ListItem>
                        <ListItem key={row.id + lineItem.id + "-kit"} onClick={() => handleRowClick(row)}>
                          <ListItemText
                            primary={
                              row.organization.code + "-" + lineItem.kit.code + "-xxxxx"
                            }
                            secondary=" "
                          />
                        </ListItem>
                      </div>
                    )
                  })}
                </DisplayList>
                )}
            </>
          );
        },
      },
      {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
        width: "75px"
      },
      {
        name: 'Last Update',
        sortable: true,
        selector: row => row.updated,
        cell: (row) => {
          return `${DateTime.fromISO(row.updated).toLocaleString(
            DateTime.DATETIME_SHORT
          )}`;
        },
      },
    ],
    []
  );


  if (testColumns === "1") {
    myColumn = columns;

  }

  if (testColumns === "2") {
    myColumn = columnsLabOrder;
  }
  return (
    <>
      {/* {labloadingNew && <LoadingData />}
      {!labloadingNew && listOrderDataNew.length > 0 && (
        <DataTable
          className='pagination-custom'
          onRowClicked={handleRowClick}
          columns={colTest}
          data={listOrderDataNew}
          noHeader
          pointerOnHover
          highlightOnHover
          persistTableHead
          defaultSortField='createdOn'
        />
      )}
      {!labloadingNew && !listOrderDataNew.length && <EmptyData entity='kit order' />} */}


      {loading && listData.length === 0 && <LoadingData />}
      {listData.length > 0 && (
        <DataTable
          className='pagination-custom'
          onRowClicked={handleRowClick}
          columns={myColumn}
          data={listData}
          noHeader
          pointerOnHover
          highlightOnHover
          persistTableHead
          defaultSortField='createdOn'
        />
      )}
      {!loading && !listData.length && <EmptyData entity='orders' />}



    </>

  );

};
export default List;
