import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
  RightDrawer,
  DrawerForm,
  FormButton,
  InputField,
  DrawerCardContent,
  DrawerCardActions,
  DrawerCard,
  DataList,
  DataListItem,
} from '@/Theme';

const RequestDetail = ({ openDrawer, setOpenDrawer, selectedRequest, success }) => {

  const navigate = useNavigate();
  const handleClose = () => {
    setOpenDrawer(false);
  };
  const handleCopy = (content) => {
    if (content) {
      navigator.clipboard.writeText(JSON.stringify(content));
    }
  }
  const content = () => {
    return (
      <>
        <DrawerCardContent>
          <DataList>
            <DataListItem label="Requested On" value={
              `${DateTime.fromISO(selectedRequest.created).toLocaleString(DateTime.DATETIME_SHORT)}`} />
            <DataListItem label="Elaspsed" value={selectedRequest.elapsed} />
            <DataListItem label="API ID" value={selectedRequest.id} />
            <DataListItem label="IP" value={selectedRequest.ip} />
            <DataListItem label="End Point" value={selectedRequest.endpoint} />
            <DataListItem label="Method" value={selectedRequest.method} />
            <DataListItem label="Status" value={selectedRequest.status} />
            <DataListItem label="Body" contentprops={{ secondaryTypographyProps: { variant: "string" } }} onClick={() => { handleCopy(selectedRequest.body) }} value={
              <pre style={{ maxWidth: "300px", maxHeight: "100px", overflow: "auto" }}>
                {JSON.stringify(selectedRequest.body, null, 2)}
              </pre>
            } />
            <DataListItem label="Response" contentprops={{ secondaryTypographyProps: { variant: "string" } }} value={
              <pre style={{ maxWidth: "300px", maxHeight: "100px", overflow: "auto" }}>
                {JSON.stringify(selectedRequest.response, null, 2)}
              </pre>
            } />
          </DataList>
        </DrawerCardContent>
        <DrawerCardActions>
          <FormButton onClick={handleClose}>Cancel</FormButton>
        </DrawerCardActions>
      </>
    );
  };
  return (
    <div>
      <RightDrawer
        title='API Request Detail'
        handleClose={handleClose}
        open={openDrawer}
      >
        {content()}
      </RightDrawer>
    </div>
  );
};
export default RequestDetail;
