const configs = {
	"dev": {
		s3: {
			BUCKET: "DEV_S3_UPLOADS_BUCKET_NAME"
		},
		api: {
			REST_URI: "https://ihd-api.bolton.dev",
		}, 
		auth:{
			SIGN_IN_URI: "https://ihd-web.bolton.dev/login",
			SIGN_OUT_URI: "https://ihd-web.bolton.dev/login",
		},
		domains: {
			PATIENT: "https://ihd-patient.bolton.dev"
		}
	},
	"staging": {
		s3: {
			BUCKET: "YOUR_PROD_S3_UPLOADS_BUCKET_NAME"
		},
		api: {
			REST_URI: "https://staging-api.ihdlabapp.com",
		}
		, 
		auth:{
			SIGN_IN_URI: "https://staging.ihdlabapp.com/login",
			SIGN_OUT_URI: "https://staging.ihdlabapp.com/login",
		},
		domains: {
			PATIENT: "http://staging.myihdlab.com"
		}
	},
	"production": {
		s3: {
			BUCKET: "YOUR_PROD_S3_UPLOADS_BUCKET_NAME"
		},
		api: {
			REST_URI: "https://api.ihdlabapp.com",
		}
		, 
		auth:{
			SIGN_IN_URI: "https://ihdlabapp.com/login",
			SIGN_OUT_URI: "https://ihdlabapp.com/login",
		},
		domains: {
			PATIENT: "http://www.myihdlab.com"
		}
	}
}

const config = configs[process.env.REACT_APP_STAGE] || configs.dev;

export default {
	// Add common config values here
	MAX_ATTACHMENT_SIZE: 5000000,
	...config
};
