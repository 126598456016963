import assert from "assert";
import { SET_AUTH_SESSION, CLEAR_AUTH_SESSION, AUTH_SESSION_STORE } from "./types";
import { useSelector, shallowEqual } from "react-redux";
import LocalToken from "../utils/local-token"

/**
 * Auth with redux for a single app.
 * For use with web frameworks like React, etc
 */
class Auth {
  constructor() {
    this.authConfig = {};
    if (!Auth.instance) {
      this.authConfig = {};
      Auth.instance = this;
    }
    return Auth.instance;
  }

  config(options) {
    assert(options, "AUTH configuration is required");
    if (this.authConfig.callbackUri) {
      this.authConfig.authPath = link.pathname;
    }
    this.authConfig.signInUri = options.SIGN_IN_URI;
    this.authConfig.signOutUri = options.SIGN_OUT_URI;
    this.authConfig.useRedux = options.USE_REDUX || true;
  }
  getConfigs() {
    return this.authConfig;
  }
  printConfigs() {
    console.log("AUTH CONFIGS: ", this.authConfig);
  }
  /**
   * Check to see if user is already signed in,
   *  - if already signed in, return true/do nothing.
   *  - if not, take user to sign in screen.
   * - return sign in
   */
  signIn(token) {
    this.saveToken(token);
    return {
      type: SET_AUTH_SESSION,
      session: {
        token
      },
    };
  }
  /**
   * Remove token from local storage and redux store. 
   * - to call it, need to useDispatch from redux dispatch to 
   * - dispatch action event.  
   * @returns redux action type to clear session
   */
  signout() {
    LocalToken.remove();
    return { type: CLEAR_AUTH_SESSION };
  }
  getSession(marker) {
    let session = null;
    try {
      //check to see if session is in redux
      session = useSelector((state) => state[AUTH_SESSION_STORE], shallowEqual);
    } catch (e) {
      console.error("### ERROR WHILE TRYING TO GET SESSION FROM REDUX",marker, e);
    }
    return session;
  }
  /**
   * 
   * @returns Check to see if user is logged in
   */
  isLoggedIn() {
    let session = this.getSession();
    return session && session.isLoggedIn;
  }
  /**
   * Get JWT token
   */
  getToken(marker) {
    let session = this.getSession(marker);
    let token = session && session.token && session.token.token;
    if (!token){
      // console.log("### getting token from localstorage");
      token = LocalToken.get();
    }else{
      // console.log("### found token from redux session", token);
    }
    return token;
  }
  /**
   * Save JWT token in local storage
   * @param {*} token
   */
  saveToken(token) {
    LocalToken.save(token);
  }
}

const instance = new Auth();
Object.freeze(instance);
export default instance;
