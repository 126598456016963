import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LabService } from '@/Services';
import { useSnackbar } from 'notistack';
import {
  FormButton,
  InputField,
  DrawerForm,
  DrawerCardContent,
  DrawerCardActions,
  RightDrawer,
} from '@/Theme';
import {
  Formik,
} from 'formik';
import * as Yup from 'yup';

const Result = ({ openDrawer, setOpenDrawer, labOrder, result, success }) => {
  const { id } = useParams();
  const [results, setResults] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const initValues = () => {
    return {
      testName: result.testName || "",
      panelNumber: result.panelNumber || "",
      testNumber: result.testNumber || "",
      result: result.result || "",
      resultType: result.resultType || "",
      unitsOfMeasurement: result.unitsOfMeasurement || "",
      referenceRange: result.referenceRange || "",
      abnormalFlag: result.abnormalFlag || "",
      rejectionCode: result.rejectionCode || "",
      resultComment: result.resultComment || "",
    };
  };

  const TEST_DEF = {
    843: { panel: 843, tests: { 959: { test: 959, type: "NM", unit: "IU/mL", range: "0.00 - 9.00" } } },
    1712: { panel: 1712, tests: { 1712: { test: 1712, type: "NM", unit: "uIU/mL", range: "0.34 - 5.60" } } },
    837: { panel: 837, tests: { 947: { test: 947, type: "NM", unit: "ng/mL", range: "2.30 - 4.40" } } },
    838: { panel: 838, tests: { 950: { test: 950, type: "NM", unit: "ng/dL", range: "0.60 - 2.00" } } },
    17326: { panel: 17326, tests: { 17326: { test: 17326, type: "NM", unit: "mIU/m", range: "1.27 - 19.26" } } },
    401: {
      panel: 401, tests: {
        401: { test: 401, type: "NM", unit: "ng/mL", range: "3.70 - 9.50" },
        402: { test: 402, type: "NM", unit: "ng/mL", range: "1.20 - 3.00" },
        403: { test: 403, type: "NM", unit: "ng/mL", range: "0.60 - 1.90" },
        404: { test: 404, type: "NM", unit: "ng/mL", range: "0.40 - 1.00" },
      }
    },
    1703: {
      panel: 1703, tests: {
        1703: { test: 1703, type: "NM", unit: "ng/mL", range: "51.0 - 321.0" },
        1713: { test: 1713, type: "NM", unit: "ng/mL", range: "24.0 - 537.0" }
      }
    },
    2000: {
      panel: 2000, tests: {
        2000: { test: 2000, type: "NM", unit: "pg/mL", range: "49.00 - 291.00" },
        2001: { test: 2001, type: "NM", unit: "pg/mL", range: "<20 - 40" },
        2023: { test: 2023, type: "NM", unit: "pg/mL", range: "<20 - 47" }
      }
    },
    7326: {
      panel: 7326, tests: {
        2009: { test: 2009, type: "NM", unit: "mIU/mL", range: "3.85 - 8.78" },
        2010: { test: 2010, type: "NM", unit: "mIU/mL", range: "1.79 - 5.12" },
        2011: { test: 2011, type: "NM", unit: "mIU/mL", range: "16.74 - 113.59" },
        7326: { test: 7326, type: "NM", unit: "mIU/mL", range: "1.27 - 19.26" }
      }
    },
    816: {
      panel: 816, tests: {
        2006: { test: 2006, type: "NM", unit: "mIU/mL", range: "2.12 - 10.89" },
        816: { test: 816, type: "NM", unit: "mIU/mL", range: "10.87 - 58.64" },
        2007: { test: 2007, type: "NM", unit: "mIU/mL", range: "1.20 - 12.86" },
        2018: { test: 2018, type: "NM", unit: "mIU/mL", range: "1.24 - 8.62" }
      }
    },
    112002: {
      panel: 112002, tests: {
        2002: { test: 2002, type: "NM", unit: "ng/mL", range: "05.16 - 18.56" },
        2003: { test: 2003, type: "NM", unit: "ng/mL", range: "<0.08 - 0.78" },
        2024: { test: 2024, type: "NM", unit: "ng/mL", range: "0.14 - 2.06" }
      }
    },
    112004: {
      panel: 112004, tests: {
        2004: { test: 2004, type: "NM", unit: "ng/mL", range: "0.2 - 6.1" },
        2005: { test: 2005, type: "NM", unit: "ng/mL", range: "0.3 - 4.4" }
      }
    },
  };

  const handleClose = () => {
    setOpenDrawer(false);
    success();
  };
  const form = () => {
    return (
      <Formik
        initialValues={initValues()}
        validationSchema={Yup.object().shape({
        })}
        onSubmit={async (values, { setSubmitting }) => {
          console.log('##### save', values);
          let savedResult = await LabService.saveResult({
            labOrderId: labOrder.id,
            id: result.id,
            testName: values.testName,
            panelNumber: values.panelNumber,
            testNumber: values.testNumber,
            result: values.result,
            resultType: values.resultType,
            unitsOfMeasurement: values.unitsOfMeasurement,
            referenceRange: values.referenceRange,
            abnormalFlag: (values.abnormalFlag ? values.abnormalFlag.toUpperCase() : ""),
            rejectionCode: values.rejectionCode,
            resultComment: values.resultComment,
          });
          if (savedResult) {
            enqueueSnackbar('Result saved.', { variant: 'success' });
            handleClose();
          }
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
          } = props;

          const handlePanelInput = () => {
            let panelNumber = values.panelNumber.trim();
            let panel = TEST_DEF[panelNumber];
            console.log("### found panel", panel);
            if (panel) {
              let test = panel.tests[Object.keys(panel.tests)[0]];
              let testNumber = test.test;
              console.log("### found test", test);
              if (test) {
                setFieldValue("panelNumber", panelNumber + "");
                setFieldValue("testNumber", testNumber + "");
                setFieldValue("resultType", test.type);
                setFieldValue("unitsOfMeasurement", test.unit);
                setFieldValue("referenceRange", test.range);
              }
            }
          };
          const handleTestInput = () => {
            console.log("### handleTestInput", values.panelNumber, values.testNumber);
            let panelNumber = values.panelNumber.trim();
            let testNumber = values.testNumber.trim();
            let panel = TEST_DEF[panelNumber];
            if (panel) {
              let test = panel.tests[testNumber];
              console.log("### found test", test);
              if (test) {
                setFieldValue("panelNumber", panelNumber);
                setFieldValue("testNumber", testNumber);
                setFieldValue("resultType", test.type);
                setFieldValue("unitsOfMeasurement", test.unit);
                setFieldValue("referenceRange", test.range);
              }
            }
          };
          return (
            <DrawerForm onSubmit={handleSubmit} autoComplete='nope'>
              <DrawerCardContent>
                <InputField field='testName' label='Test Name' props={props} />
                <InputField field='panelNumber' label='Panel Number' props={props} onBlur={handlePanelInput} />
                <InputField field='testNumber' label='Test Number' props={props} onBlur={handleTestInput} />
                <InputField field='result' label='Result' props={props} />
                <InputField field='abnormalFlag' label='Abnormal Flag' props={props} />
                <InputField field='rejectionCode' label='Rejection Code' props={props} />
                <InputField field='resultType' label='Result Type' props={props} />
                <InputField field='unitsOfMeasurement' label='Units Of Measurement' props={props} />
                <InputField field='referenceRange' label='Reference Range' props={props} />
                <InputField field='resultComment' label='Comment' props={props} />
              </DrawerCardContent>
              <DrawerCardActions>
                <FormButton primary type='submit'>
                  Submit
                </FormButton>
                <FormButton onClick={handleClose}>Cancel</FormButton>
              </DrawerCardActions>
            </DrawerForm>
          );
        }}
      </Formik>
    );
  };
  return (
    <RightDrawer
      title={labOrder.id ? 'Edit Result' : 'Add Result'}
      handleClose={handleClose}
      open={openDrawer}
    >
      {form()}
    </RightDrawer>
  );
};

export default Result;
