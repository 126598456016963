import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import moment from 'moment';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
/** Local imports */
import {
  RightDrawer,
  DrawerCard,
  FormButton,
  DrawerCardContent,
  DrawerCloseButton,
  FormTextField,
  FormMenuItem,
} from '@/Theme';
import { InfoContainer, RequestDetail } from './Style';

const SERVICE_STATUS = {
  OPEN: { value: 'OPEN', description: 'Open' },
  COMPLETED: { value: 'COMPLETED', description: 'Completed' },
  REJECTED: { value: 'REJECTED', description: 'Rejected' },
  CLOSED: { value: 'CLOSED', description: 'Closed' },
};
const SERVICE_STATUSES = Object.values(SERVICE_STATUS);

const ServiceDrawer = ({
  openDrawer,
  setOpenDrawer,
  selectedRequest,
  refreshRequests,
  inputVariables,
}) => {
  const navigate = useNavigate();
  const { height } = 300;
  const [request, setRequest] = useState({});
  const [changed, setChanged] = useState(false);
  const [row, setRow] = useState(1);

  const getUser = () => {};

  const handleClose = () => {
    setOpenDrawer(false);
    if (changed && refreshRequests) {
      refreshRequests({
        variables: inputVariables,
      });
    }
  };
  const getRequest = () => {};

  useEffect(() => {
    getRequest({ variables: { id: selectedRequest.id } });
  }, []);

  const initialValues = () => {
    return {
      comment: '',
      status: selectedRequest.status,
    };
  };

  const form = () => {
    return <>xyz</>;
  };
  return (
    <div>
      <RightDrawer
        variant='temporary'
        width={400}
        anchor='right'
        open={openDrawer}
        onClose={handleClose}
      >
        {form()}
      </RightDrawer>
    </div>
  );
};
export default ServiceDrawer;
