import assert from "assert";
import LocalToken from "../utils/local-token"

/**
 * Auth with localstorage for a single app.
 * For use with web frameworks like React, etc
 */
class Auth {
  constructor() {
    this.authConfig = {};
    if (!Auth.instance) {
      this.authConfig = {};
      Auth.instance = this;
    }
    return Auth.instance;
  }

  config(options) {
    assert(options, "AUTH configuration is required");
    // Cognito.config(options);
    // Object.assign(this.authConfig, Cognito.getConfigs());
    if (this.authConfig.callbackUri) {
      // let link = new URL(this.authConfig.callbackUri);
      this.authConfig.authPath = link.pathname;
    }
    this.authConfig.signInUri = options.SIGN_IN_URI;
    this.authConfig.signOutUri = options.SIGN_OUT_URI;
    this.authConfig.useRedux = options.USE_REDUX || true;
  }
  getConfigs() {
    return this.authConfig;
  }
  printConfigs() {
    console.log("AUTH CONFIGS: ", this.authConfig);
  }

  /**
   * Check to see if user is already signed in,
   *  - if already signed in, return true/do nothing.
   *  - if not, take user to sign in screen.
   */
  signIn(token) {
    this.saveToken(token);
  }
  signOut(){
    LocalToken.remove();
    return true;
  }
  isLoggedIn(){
    console.log("AuthLocal#isLoggedIn")
    let token = this.getToken();
    console.log("### token", token);
    return token != null;
  }
  /**
   * Get JWT token
   */
  getToken() {
    return LocalToken.get();
  }
  /**
   * Save JWT token in local storage
   * @param {*} token
   */
  saveToken(token) {
    LocalToken.saveToken(token);
  }
}

const instance = new Auth();
Object.freeze(instance);
export default instance;
