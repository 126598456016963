import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import PersonIcon from '@mui/icons-material/Person';
import TestIcon from '@mui/icons-material/Bookmark';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';

import {
  SecondaryPage,
  DataTableStyles,
  EmptyData,
  LoadingData,
  DataList,
  DataListItem,
  ContentCardContent,
  ContentCardHeader,
  ContentCardShadowed,
  FormButton,
} from '@/Theme';
import { Grid, Avatar, IconButton } from '@mui/material';
/** Local imports */
import Edit from './Edit';
import { PatientService } from '@/Services';

const View = () => {
  const { id } = useParams();
  const [selectedItem, setSelectedItem] = useState({});
  const [address, setAddress] = useState({ });
  const [subTest, setSubTest] = useState({});
  const [action, setAction] = useState(null);
  const [loading, setLoading] = useState(false);
  //for editing
  const [openDrawer, setOpenDrawer] = useState(false);

  const fetch = async () => {
    setLoading(true);
    let response = await PatientService.getPatient(id);
    console.log('##### response', response);
    if (response && response.data) {
      setSelectedItem(response.data);
      if (response.data.address){
        setAddress(response.data.address)  
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    fetch();
  }, [id]);

  const handleEdit = () => {
    let link = `/patient/survey/${selectedItem.id}`;
    navigate(link);
  };
  const refresh = () => {
    fetch();
  };
  const contentDrawer = () => {
    let drawerContent = '';
    if (openDrawer && action) {
      if (action === 'edit') {
        drawerContent = (
          <Edit
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            testType={selectedItem}
            success={refresh}
          />
        );
      }
    }
    return drawerContent;
  };
  const navigate = useNavigate();
  const handleRowClick = (order) => {
    let link = `/lab/${order.id}`;
    navigate(link);
  };
  const columns = useMemo(
    () => [
      {
        name: 'Registration Code',
        selector: row => row.kitNumber,
        sortable: true,
        sortField: 'kitNumber',
        grow: 1,
      },
      {
        name: 'IHD Code',
        selector: row => row.labOrderCode,
        sortable: true,
        sortField: "labOrderCode",
        grow: 1,
      },
      {
        name: 'Lab Kit',
        selector: row => row.labKit ? row.labKit.name : "",
        sortable: true,
        sortField: "labKit.name",
        grow: 1,
      },
      {
        name: 'Status',
        selector: row => row.status,
        sortField: "status",
        sortable: true,
      },
      {
        name: 'Last Update',
        sortable: true,
        sortField: "updated",
        cell: (row) => {
          return `${DateTime.fromISO(row.updated).toLocaleString(
            DateTime.DATETIME_SHORT
          )}`;
        },
      },
    ],
    []
  );
  const title = () => {
    return (
      <>
        <PersonIcon />
        <span style={{ marginLeft: '1rem' }}>
          Patient: {selectedItem.firstName} {selectedItem.lastName}
        </span>
      </>
    );
  };
  return (
    <SecondaryPage title={title()} back='/patient'>
      <ContentCardShadowed>
        <ContentCardHeader
          title='General Info'
          // action={
          // 	<FormButton primary compact onClick={handleEdit}>Edit</FormButton>
          // }
        />
        <ContentCardContent>
          <Grid container>
            <Grid item xs={6}>
              <DataList>
                <DataListItem
                  label='First Name'
                  value={selectedItem.firstName}
                />
                <DataListItem label='Last Name' value={selectedItem.lastName} />
                <DataListItem
                  label='Middle Name'
                  value={selectedItem.middleName}
                />
                <DataListItem
                  label='DOB'
                  value={selectedItem.dob}
                />
                <DataListItem label='Email' value={selectedItem.email} />
                <DataListItem
                  label='Phone'
                  value={selectedItem.phone}
                />
                <DataListItem
                  label='Organization'
                  value={selectedItem.organization && selectedItem.organization.name}
                />
                <DataListItem
                  label='External Id'
                  value={selectedItem.externalId}
                />
                <DataListItem label='Metadata' value={selectedItem.metadata} />
              </DataList>
            </Grid>
            <Grid item xs={6}>
              <DataList>
                <DataListItem
                  label='Street line 1 '
                  value={address.street1}
                />
                <DataListItem
                  label='Street line 2'
                  value={address.street2}
                />
                <DataListItem label='City' value={address.city} />
                <DataListItem
                  label='State'
                  value={address.state}
                />
                <DataListItem
                  label='Postal Code'
                  value={address.postalCode}
                />
                <DataListItem
                  label='Country'
                  value={address.country}
                />
              </DataList>
            </Grid>
          </Grid>
        </ContentCardContent>
      </ContentCardShadowed>
      <ContentCardShadowed>
        <ContentCardHeader
          title='Survey Info'
          // action={
          // 	<FormButton primary compact onClick={handleEdit}>Edit</FormButton>
          // }
        />
        <ContentCardContent>
          <Grid container>
            <Grid item xs={6}>
              <DataList>
                <DataListItem
                  label='PCP'
                  value={selectedItem.healthProfile && selectedItem.healthProfile.pcp}
                />
                <DataListItem label='Height' value={selectedItem.healthProfile && selectedItem.healthProfile.feet+ " feets "+selectedItem.healthProfile.inches+" inches"} />
                <DataListItem label='Weight' value={selectedItem.healthProfile && selectedItem.healthProfile.weight + " lbs" } />
                <DataListItem
                  label='Alcohol'
                  value={selectedItem.healthProfile && selectedItem.healthProfile.alcohol}
                />
                <DataListItem
                  label='History'
                  value={selectedItem.healthProfile && selectedItem.healthProfile.history.join(", ")}
                />
                <DataListItem
                  label='Tobacco'
                  value={selectedItem.healthProfile && selectedItem.healthProfile.tobacco}
                />
                <DataListItem label='Exercise' value={selectedItem.healthProfile && selectedItem.healthProfile.exercise} />
              </DataList>
            </Grid>
            <Grid item xs={6}>
              <DataList>
                <DataListItem
                  label='Race'
                  value={selectedItem.healthProfile && selectedItem.healthProfile.race.join(", ")}
                />
                <DataListItem
                  label='Ethnicity'
                  value={selectedItem.healthProfile && selectedItem.healthProfile.ethnicity.join(", ")}
                />
                <DataListItem
                  label='Family History'
                  value={selectedItem.healthProfile && selectedItem.healthProfile.familyHistory.join(", ")}
                />
              </DataList>
            </Grid>
          </Grid>
        </ContentCardContent>
      </ContentCardShadowed>
      <ContentCardShadowed group>
        <ContentCardHeader title='Lab Orders' />
        <ContentCardContent>
          <DataTable
            className='pagination-custom'
            columns={columns}
            data={selectedItem.tests}
            noHeader
            onRowClicked={handleRowClick}
            highlightOnHover
            customStyles={DataTableStyles}
            persistTableHead
            defaultSortField='name'
            defaultSortAsc={false}
          />
        </ContentCardContent>
      </ContentCardShadowed>
      {contentDrawer()}
    </SecondaryPage>
  );
};
export default View;
