/** PACKAGE IMPORTS */
import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { DataTableStyles, EmptyData, LoadingData } from '@/Theme';
import { DateTime } from 'luxon';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { AdminSvc } from '@/Services';

const List = ({
  listData,
  loading,
  handleViewRequest,
  count,
  size,
  setSize,
  page,
  setPage,
  setSort,
  setDirection,
  refreshData
}) => {
  const columns = useMemo(
    () => [
      {
        name: 'Organization',
        selector: row=>row.Organization.name,
        sortable: true,
      },
      {
        name: 'API Access ID',
        selector: row=>row.apiId,
        sortable: true,
      },
      {
        name: 'IP',
        selector: row=>row.ip,
        sortable: true,
      },
      {
        name: 'End Point',
        selector: row=>row.endpoint,
        sortable: true,
        grow: 2
      },
      {
        name: 'Method',
        selector: row=>row.method,
        sortable: true,
      },
      {
        name: 'Status',
        selector: row=>row.status,
        sortable: true,
      },
      {
        name: 'Elasped(ms)',
        selector: row=>row.elasped,
        sortable: true,
      },
      {
        name: 'Requested On',
        selector: row=>row.created,
        sortable: true,
        cell: (row) => {
          return `${DateTime.fromISO(row.created).toLocaleString(
            DateTime.DATETIME_SHORT
          )}`;
        },
      },
      {
        name: '',
        width: '75px',
        cell: (row) => {
          return (
            <>
              <IconButton onClick={() => deleteTask(row)}>
                <DeleteIcon />
              </IconButton>
            </>
          );
        },
      },
    ],
    []
  );
  const handlePageChange = (p) => {
    setPage(p);
  };
  const handlePerRowsChange = (perPage, p) => {
    setSize(perPage);
    setPage(p);
  };
  const handleSort = (s, d) => {
    setSort(s.selector);
    setDirection(d);
  };
  const deleteTask = async (task) => {
    let response = await AdminSvc.deleteTask(task);
    if (response) {
      refreshData();
    }

  };
  return (
    <>
      {loading && listData.length === 0 && <LoadingData />}
      {listData.length > 0 && <DataTable
        className='pagination-custom'
        columns={columns}
        data={listData}
        onRowClicked={handleViewRequest}
        highlightOnHover
        pointerOnHover
        customStyles={DataTableStyles}
        paginationDefaultPage={page}
        persistTableHead
        paginationPerPage={size}
        paginationRowsPerPageOptions={[10, 15, 25, 50, 100, 200, 500]}
        pagination
        paginationServer
        paginationTotalRows={count}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        defaultSortField='created'
        defaultSortAsc={false}
        onSort={handleSort}
        sortServer
        noHeader
        noDataComponent={
          <EmptyData />
        }
        noTableHead={(listData && listData.length <= 0)}
      />}
      {!loading && !listData.length && <EmptyData entity='requests' />}
    </>
  );
};
export default List;
