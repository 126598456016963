import { SET_USER, LOGOUT_USER } from '../action/types';

const initialState = {
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return action.user;
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};
