import React from 'react';
import styled from "styled-components";
import {
  Avatar,
  Paper,
  ListItemAvatar,
  ListItemText,
  List,
  Accordion
} from '@mui/material';

const ActivityContainer = styled(Accordion)`
  width:100%;
  margin: 0;
  padding:0;
`;

const ActivityDetail = styled(ListItemText)`
  &&{
    .MuiListItemText-primary	{
      font-size: 1.2rem;
    }
    .MuiListItemText-secondary	{
      font-size: 1rem;
    }
  }
`;

const ActivityAvatar = styled(ListItemAvatar)`
  &&{
    min-width:36px;
    .MuiAvatar-root{
      width:24px;
      height: 24px;
    }
  }
`;
const ActivityComment = styled.p`
  border-radius: .5rem;
  padding: 8px;
  width: 100%;
  background:#FBFBFB;
  border:1px solid #F3F3F3;
  font-size:1.2rem;
`;

const RequestDetail = styled(List)`
  &&{
    .MuiListItem-gutters{
      padding:0;
    }
    .MuiListItemText-multiline{
      padding:0;
    }
    .MuiListItemText-primary	{
      font-size: 1rem;
      color: #757575;
    }
    .MuiListItemText-secondary	{
      font-size: 1.3rem;
      padding-left:.5rem;
      color:#000000;
    }
  }
`;
const InfoContainer = styled(Paper)`
  width:100%;
  margin: 0 0 20px 0;
  padding:6px 12px 12px 12px;
`

const AccountAvatar = styled(Avatar)`
  &&{
    width:30px;
		height:30px;
		text-transform: uppercase;
    background:#2a2277;
    &:hover{
      background:#EF599D; 
    }
  }
`;


const ImageContainer = styled.div`
  max-width: ${(props) => props.maxWidth || '100px'};
  max-height:${(props) => props.maxHeight || '40px'};
  img {
    object-fit: cover;
    max-width: ${(props) => props.maxWidth || '100px'};
    max-height:${(props) => props.maxHeight || '40px'};
  }
`;
const Image = styled(({ src, alt, ...otherProps}) =>(
  <ImageContainer {...otherProps}>
    <img src={src} alt={alt} />
  </ImageContainer> 
))``;
export {
  ActivityContainer,
  ActivityDetail,
  ActivityAvatar,
  ActivityComment,
  InfoContainer,
  RequestDetail,
  AccountAvatar,
  Image
}