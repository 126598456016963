import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import PanelIcon from '@mui/icons-material/Bookmarks';
import TestIcon from '@mui/icons-material/Bookmark';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  SecondaryPage,
  DataTableStyles,
  EmptyData,
  LoadingData,
  DataList,
  DataListItem,
  ContentCardContent,
  ContentCardHeader,
  ContentCardShadowed,
  FormButton,
} from '@/Theme';
import { Grid, Avatar, IconButton } from '@mui/material';
/** Local imports */
import Edit from './Edit';
import Tests from './Tests';
import { AdminSvc } from '@/Services';
import TestTable from '@/Components/Common/TestTable';

const View = () => {
  const { id } = useParams();
  const [selectedItem, setSelectedItem] = useState({});
  const [tests, setTests] = useState([]);
  const [action, setAction] = useState(null);
  const [loading, setLoading] = useState(false);
  //for editing
  const [openDrawer, setOpenDrawer] = useState(false);

  const fetch = async () => {
    setLoading(true);
    let response = await AdminSvc.getPanel(id);
    console.log('##### response', response);
    if (response && response.data) {
      setSelectedItem(response.data.panel);
      setTests(response.data.tests);
      console.log('#### tests', response.data.tests);
    }
    setLoading(false);
  };
  const deleteTest = async (test) => {
    setLoading(true);
    let response = await AdminSvc.removeTestFromPanel(id, test.testId);
    console.log('##### response', response);
    if (response && response.data) {
      fetch();
    }
    setLoading(false);
  };
  useEffect(() => {
    fetch();
  }, [id]);

  useEffect(() => {
    fetch();
  }, [id]);
  const handleEdit = () => {
    setAction('edit');
    setOpenDrawer(true);
  };
  const handleAdd = () => {
    setAction('test');
    setOpenDrawer(true);
  };
  const handleRowClick = (test) => {
    setAction('test');
    setOpenDrawer(true);
  };
  const handleDelete = (test) => {
    deleteTest(test);
  };
  const refresh = () => {
    fetch();
  };
  const contentDrawer = () => {
    let drawerContent = '';
    if (openDrawer && action) {
      if (action === 'edit') {
        drawerContent = (
          <Edit
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            testType={selectedItem}
            success={refresh}
          />
        );
      } else if (action === 'test') {
        drawerContent = (
          <Tests
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            panel={selectedItem}
            success={refresh}
          />
        );
      }
    }
    return drawerContent;
  };

  const columns = useMemo(() =>
    [
      {
        name: 'Name',
        selector: row => row.test.name,
        sortable: true,
        grow: 2,
      },
      {
        name: 'Common Name',
        selector: row => row.test.displayName,
        sortable: true,
        grow: 2,
      },
      {
        name: 'Lab Test ID',
        selector: row => row.test.externalId,
        sortable: true,
        grow: 2,
      },
      {
        name: 'Active',
        selector: row => row.active,
        sortable: true,
        width: '100px',
        cell: (row) => {
          return row.test.active ? 'Yes' : 'No';
        },
      },
      {
        name: '',
        sortable: false,
        width: '56px',
        button: true,
        cell: (row) => {
          return (
            <IconButton onClick={() => handleDelete(row)}>
              <DeleteIcon />
            </IconButton>
          );
        },
      },
    ], []);

  return (
    <SecondaryPage
      icon={<PanelIcon fontSize='large' />}
      title={selectedItem.name}
      back='/admin/panel'
    >
      <ContentCardShadowed>
        <ContentCardHeader
          title='Panel Info'
          action={
            <FormButton primary compact onClick={handleEdit}>
              Edit
            </FormButton>
          }
        />
        <ContentCardContent>
          <Grid container>
            <Grid item xs={6}>
              <DataList>

                <DataListItem label='Name' value={selectedItem.name} />
                <DataListItem
                  label='Description'
                  value={selectedItem.description}
                />

                <DataListItem
                  label='Display Name'
                  value={selectedItem.displayName}
                />
                <DataListItem
                  label='Display Description'
                  value={selectedItem.displayDescription}
                />

              </DataList>
            </Grid>
            <Grid item xs={6}>
              <DataList>
                <DataListItem
                  label='Organization'
                  value={
                    selectedItem.organization && selectedItem.organization.name
                  }
                />
                <DataListItem
                  label='Active'
                  value={selectedItem.active ? 'Yes' : 'No'}
                />
                <DataListItem
                  label='Lab Panel Id'
                  value={selectedItem.externalId}
                />
              </DataList>
            </Grid>
          </Grid>
        </ContentCardContent>
      </ContentCardShadowed>
      <ContentCardShadowed group>
        <ContentCardHeader
          title='Tests'
          action={
            <FormButton primary compact onClick={handleAdd}>
              Add tests
            </FormButton>
          }
        />
        <ContentCardContent>
          <DataTable
            className='pagination-custom'
            columns={columns}
            data={tests}
            noHeader
            onRowClicked={handleRowClick}
            highlightOnHover
            customStyles={DataTableStyles}
            persistTableHead
            defaultSortField='name'
            defaultSortAsc={false}
          />
        </ContentCardContent>
      </ContentCardShadowed>
      {contentDrawer()}
    </SecondaryPage>
  );
};
export default View;
