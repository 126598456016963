import React, { useState } from 'react';
import {
  FullPage
} from '@/Theme';
import Request from './Request';
import Verify from './Verify';

const Login = () => {
  const [requested, setRequested] = useState(false);
  return (
    <FullPage className="flex flex-col-cc">
      {requested ? <Verify updateRequest={setRequested}/> : <Request  updateRequest={setRequested}/>}
    </FullPage>
  );
};
export { Login };
