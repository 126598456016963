/** PACKAGE IMPORTS */
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { DataTableStyles, EmptyData, LoadingData } from '@/Theme';
import { Avatar, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Image } from './Style';

const List = ({
  listData,
  loading,
  handleView,
  handleEdit,
  count,
  size,
  setSize,
  page,
  setPage,
  setSort,
  setDirection,
}) => {
  const navigate = useNavigate();

  const handleRowClick = (organization) => {
    let link = `/admin/organization/${organization.id}`;
    navigate(link);
  };

  const columns = useMemo(
    () => [
      {
        name: '',
        selector: row => row.name,
        sortable: true,
        width: '125px',
        cell: (row) => {
          return <Image src={row.logo} alt={row.name} />;
        },
      },
      {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
        grow: 2,
      },
      {
        name: 'NPI',
        selector: row => row.npi,
        sortable: true,
        width: '125px',
      },
      {
        name: 'Location',
        selector: row => row.locationId,
        sortable: true,
        width: '125px',
      },
      {
        name: 'Organization ID',
        selector: row => row.organizationId,
        width: '150px',
        sortable: true,
      },
      {
        name: 'Time Zone',
        selector: row => row.timezone,
        sortable: true,
        width: '125px',
      },
      {
        name: 'Kit Number Pattern',
        selector: row => row.kitPattern,
        sortable: true,
        width: '200px',
      },
      {
        name: 'Active',
        selector: row => row.active,
        sortable: true,
        width: '100px',
        cell: (row) => {
          return row.active ? 'Yes' : 'No';
        },
      },
    ],
    []
  );
  const handlePageChange = (p) => {
    console.log('### handlePageChange', page, '--', p);
    setPage(p);
  };
  const handlePerRowsChange = (perPage, p) => {
    console.log('### handleRowsChange', perPage, p);
    setSize(perPage);
    setPage(p);
  };
  const handleSort = (sort, direction) => {
    console.log('### handleSort', sort, direction);
    setSort(sort.selector);
    setDirection(direction);
  };
  return (
    <>
      {loading && listData.length === 0 && <LoadingData />}
      {listData.length > 0 && (
        <DataTable
          className='pagination-custom'
          columns={columns}
          data={listData}
          noHeader
          onRowClicked={handleRowClick}
          highlightOnHover
          customStyles={DataTableStyles}
          persistTableHead
          pagination={false}
          defaultSortField='name'
          defaultSortAsc={false}
          onSort={handleSort}
        />
      )}
      {!loading && !listData.length && <EmptyData />}
    </>
  );
};
export default List;
