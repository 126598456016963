import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { Redux as WebAuth } from '@ihdlab/web-auth';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { CardContent, CardActions, Button, Typography } from '@mui/material';
import configs from '@/config';
import { AuthSvc } from '@/Services';
import { ContentCardHeader, FormTextField, FormButton } from '@/Theme';
import {
  LoginCard,
  ActionContainer,
  RequestButton,
  NoteContainer,
} from './Style';

const Auth = WebAuth.Auth;

const Verify = ({ updateRequest }) => {
  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();
  const login = async (code, setFieldError) => {
    let response = await AuthSvc.verify(code);
    let data = response.data;
    if (data.ok) {
      let token = data.extra.jwt.token;
      if (token) {
        dispatch(Auth.signIn(token));
        setRedirect(true);
      }
    } else {
      setFieldError('code', data.message);
    }
  };
  const LoginSchema = Yup.object().shape({
    code: Yup.string().required('Code is required'),
  });
  return redirect ? (
    <Navigate replace to='/' />
  ) : (
    <>
      <Formik
        initialValues={{
          code: '',
        }}
        validationSchema={LoginSchema}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          login(values.code, setFieldError);
        }}
      >
        {(props) => {
          const { values, touched, errors, handleChange, handleSubmit } = props;
          return (
            <Form onSubmit={handleSubmit}>
              <LoginCard border='true'>
                <ContentCardHeader
                  title={<Typography variant='h3'>Verify</Typography>}
                />
                <CardContent>
                  <Typography variant='h5'>
                    A verification code was sent to you in the email you
                    entered, please enter the verification code below to login.
                  </Typography>
                  <ErrorMessage name='email' />
                  <FormTextField
                    large
                    name='code'
                    height='100%'
                    value={values.code}
                    placeholder='Enter the verfication code...'
                    onChange={handleChange}
                    error={touched.code && Boolean(errors.code)}
                    helperText={errors.code && touched.code && errors.code}
                    margin='dense'
                  />
                  <ActionContainer>
                    <FormButton primary large type='submit' style={{background:values.code.length >= 6 ? '#DE639B' : false}}>
                      Login
                    </FormButton>
                  </ActionContainer>
                </CardContent>
                <NoteContainer>
                  Didn&apos;t see your verfication code? Please check your spam
                  folder. Or{' '}
                  <RequestButton onClick={() => updateRequest(false)}>
                    request
                  </RequestButton>{' '}
                  it again.
                </NoteContainer>
              </LoginCard>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
export default Verify;
