import React from 'react';
import { Typography } from '@mui/material';
import { EmptyPage } from './Content';
import Skeleton from '@mui/material/Skeleton';

/**
 * @see: https://github.com/jbetancur/react-data-table-component/blob/master/src/DataTable/styles.js
 */
const DataTableStyles = {
  headRow: {
    style: {
      backgroundColor: '#f0f0f0',
      fontSize: '1.2rem',
      fontWeight: '500',
      color: '#212121',
      borderLeft: '1px solid rgba(0,0,0,.12)',
      borderRight: '1px solid rgba(0,0,0,.12)',
      borderTop: '1px solid rgba(0,0,0,.12)',
    },
  },
  headCells: {
    style: {
      fontSize: '1.4rem',
    },
    activeSortStyle: {},
  },
  rows: {
    style: {
      borderLeft: '1px solid rgba(0,0,0,.12)',
      borderRight: '1px solid rgba(0,0,0,.12)',
    },
    highlightOnHoverStyle: {
      outlineWidth: '0',
    },
  },
  cells: {
    style: {
      fontSize: '1.3rem',
    },
  },
  expanderRow: {
    style: {
      backgroundColor: '#fafafa',
    },
  },
  pagination: {
    style: {padding: "1rem;"},
  },
};
const EmptyData = ({ entity = 'record' }) => (
  <EmptyPage className='flex-col-cc' style={{ width: "100%", minHeight: '15rem' }}>
    <Typography variant='h5'>
      No {entity} found. Please adjust your filter.
    </Typography>
  </EmptyPage>
);
const LoadingData = () => (
  <div>
    <Skeleton height={200} />
  </div>
);
export { DataTableStyles, EmptyData, LoadingData };
