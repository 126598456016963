/** PACKAGE IMPORTS */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { PrimaryPage, NavListItem, ContentCardShadowed } from '@/Theme';
import { Redux as WebAuth } from '@ihdlab/web-auth';
import { UserSvc } from '@/Services';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CardHeader,
  CardContent,
} from '@mui/material';
import UsersIcon from '@mui/icons-material/People';
import TestTypeIcon from '@mui/icons-material/Beenhere';
import SpecimenIcon from '@mui/icons-material/Opacity';
import IntegrationsIcon from '@mui/icons-material/SettingsEthernet';
import ActiviesIcon from '@mui/icons-material/List';

const Auth = WebAuth.Auth;

const Dashboard = () => {
  const navigate = useNavigate();
  const token = Auth.getToken();
  const isLoggedIn = Auth.isLoggedIn();
  const user = UserSvc.me();
  return (
    <>
      <PrimaryPage title='Partner'>
        <Grid container spacing={3}>
          <Grid item md={9}>
            <ContentCardShadowed>
              <CardHeader title='Overview' />
              <CardContent>overview</CardContent>
              <CardHeader title='Development' />
              <CardContent>development</CardContent>
            </ContentCardShadowed>
          </Grid>
          <Grid item md={3}>
            <ContentCardShadowed>
              <CardContent>
                <List disablePadding>
                  <NavListItem button onClick={() => navigate('/admin/user')}>
                    <ListItemIcon>
                      <UsersIcon />
                    </ListItemIcon>
                    <ListItemText primary='Users' secondary='Manage users' />
                  </NavListItem>
                  <NavListItem button onClick={() => navigate('/admin/test')}>
                    <ListItemIcon>
                      <TestTypeIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary='Test Types'
                      secondary='Manage test types'
                    />
                  </NavListItem>
                  <NavListItem
                    button
                    onClick={() => navigate('/admin/specimentype')}
                  >
                    <ListItemIcon>
                      <SpecimenIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary='Specimen Types'
                      secondary='Manage specimen types'
                    />
                  </NavListItem>
                  <NavListItem
                    button
                    onClick={() => navigate('/admin/icdcode')}
                  >
                    <ListItemIcon>
                      <ActiviesIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary='Activities'
                      secondary='View events, ctivities'
                    />
                  </NavListItem>
                  <NavListItem
                    button
                    onClick={() => navigate('/partner/integration')}
                  >
                    <ListItemIcon>
                      <IntegrationsIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary='Integrations'
                      secondary='Integration token, webhooks'
                    />
                  </NavListItem>
                </List>
              </CardContent>
            </ContentCardShadowed>
          </Grid>
        </Grid>
      </PrimaryPage>
    </>
  );
};
export default Dashboard;
