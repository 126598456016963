import React, { useEffect, useState } from 'react';
import UsersTable from './List';
import EditUser from './Edit';
import User from './View';
import { MenuItem } from '@mui/material';
import {
  PageHeader,
  PageAction,
  PrimaryButton,
  FormButton,
  SecondaryPage,
  SelectFilterField,
  FilterBox,
} from '@/Theme';
import { UserSvc } from '@/Services';

const Users = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState({ search: "", status:["Created", "Active"] });
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(20);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('created');
  const [direction, setDirection] = useState('desc');
  const [status, setStatus] = useState(["Created", "Active"]);
  const [role, setRole] = useState([]);

  //For editing
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawer, setDrawer] = useState('');
  const [selectedUser, setSelectedUser] = useState({});
  const [roles, setRoles] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const filters = {
    where: {},
    size: size,
    page: page,
    sort: sort,
    order: direction,
  };
  const fetchUsers = async () => {
    setLoading(true);
    filters.where = filter;
    let response = await UserSvc.search(filters);
    if (response && response.data && response.data.objects) {
      const objects = response.data.objects;
      setUsers(objects);
      setCount(response.data.count);
    }
    let userEnums = await UserSvc.userEnums();
    if (userEnums) {
      setRoles(Object.values(userEnums.ROLES));
      setStatuses(Object.values(userEnums.PROFILE_STATUS));
    }
    setLoading(false);
  };
  
  useEffect(() => {
    console.log('### fetch users', search, size, page, sort, direction);
    fetchUsers();
  }, [filter, size, page, sort, direction]);

  const onSearchChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };
  const onSearchPressed = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let updatedFilter = {
        ...filter,
        ...{ search: search },
        ...{ status: status },
        ...{ role: role },
      }
      setFilter(updatedFilter);
    }
  };
  const handleApplyFilter = () => {
    let updatedFilter = {
      ...filter,
      ...{ search: search },
      ...{ status: status },
      ...{ role: role },
    }
    setFilter(updatedFilter);
  }
  const onStatusSelect = (event) => {
    const { target: { value } } = event;
    setStatus(typeof value === 'string' ? value.split(',') : value,);
  }
  const onRoleSelect = (event) => {
    const { target: { value } } = event;
    setRole(typeof value === 'string' ? value.split(',') : value,);
  }
  const handleViewAction = (user) => {
    setSelectedUser(user);
    setDrawer('new');
    setOpenDrawer(true);
  };
  const handleNewAction = () => {
    setSelectedUser({});
    setDrawer('new');
    setOpenDrawer(true);
  };
  const contentDrawer = () => {
    let drawerContent = '';
    if (openDrawer) {
      if (drawer === 'new') {
        drawerContent = (
          <EditUser
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            selectedUser={selectedUser}
            refreshUsers={fetchUsers}
            inputVariables={filters}
            roles={roles}
            statuses={statuses}
          />
        );
      } else if (drawer === 'view') {
        drawerContent = (
          <User
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            selectedUser={selectedUser}
            refreshUsers={fetchUsers}
            inputVariables={filters}
          />
        );
      }
    }
    return drawerContent;
  };
  return (
    <SecondaryPage title='Users' back='/admin/dashboard'>
      <PageHeader>
        <PageAction>
          <FilterBox onChange={onSearchChange} onPress={onSearchPressed}
            extend={
              <>
                <SelectFilterField
                  labelId="filter-status-label"
                  id="filter-status-id"
                  value={status}
                  multiple
                  onChange={onStatusSelect}
                >
                  {statuses.map(statusItem => (
                    <MenuItem key={statusItem} value={statusItem}>
                      {statusItem}
                    </MenuItem>
                  ))}
                </SelectFilterField>
                <SelectFilterField
                  labelId="filter-status-label"
                  id="filter-status-id"
                  value={role}
                  multiple
                  onChange={onRoleSelect}
                >
                  {roles.map(roleItem => (
                    <MenuItem key={roleItem.value} value={roleItem.value}>
                      {roleItem.value}
                    </MenuItem>
                  ))}
                </SelectFilterField>
                <FormButton compact primary onClick={handleApplyFilter}>Filter</FormButton>
              </>
            }
          />
        </PageAction>
        <PrimaryButton onClick={handleNewAction}>New User</PrimaryButton>
      </PageHeader>
      <UsersTable
        users={users}
        setOpenDrawer={setOpenDrawer}
        handleViewRequest={handleViewAction}
        loading={loading}
        count={count}
        size={size}
        setSize={setSize}
        page={page}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
        direction={direction}
        setDirection={setDirection}
      />
      {contentDrawer()}
    </SecondaryPage>
  );
};
export default Users;
