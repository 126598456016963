import React, { useState, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSnackbar } from 'notistack';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { AdminSvc } from '@/Services';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

import {
  Formik,
  Form,
  useField,
  Field,
  ErrorMessage,
  validateYupSchema,
} from 'formik';
import * as Yup from 'yup';
import {
  RightDrawer,
  DrawerForm,
  FormButton,
  FormTextField,
  InputField,
  DrawerCardContent,
  DrawerCardActions,
  FormMenuItem,
  DrawerCard,
  DrawerContent,
} from '@/Theme';
import { Refresh } from '@mui/icons-material';
import { check } from 'prettier';

const Edit = ({ openDrawer, setOpenDrawer, panel, success }) => {
  console.log('### Selected item for edit: ', panel);
  const [selectedPanel, setSelectedPanel] = useState(panel);
  const { enqueueSnackbar } = useSnackbar();
  const [tests, setTests] = useState([]);
  const [checked, setChecked] = useState([]);

  const fetchTests = async () => {
    let response = await AdminSvc.listTests();
    if (response && response.data) {
      console.log('#### tests', response.data);
      setTests(response.data);
    }
  };
  const addTests = async () => {
    let response = await AdminSvc.addTestsToPanel(selectedPanel.id, checked);
    if (response && response.data) {
      console.log('#### tests', response.data);
      success();
      setOpenDrawer(false);
    }
  };
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  useEffect(() => {
    fetchTests();
  }, []);

  const handleSuccess = (savedTestType) => {
    setOpenDrawer(false);
    success(savedTestType);
  };
  const handleCancel = () => {
    setOpenDrawer(false);
  };

  const handleSubmit = async () => {
    console.log('##### checked', checked);
    await addTests();
    // setOpenDrawer(false);
  };

  const form = () => {
    return (
      <DrawerCard>
        <DrawerCardContent>
          <List>
            {tests.map((test) => {
              const labelId = `checkbox-list-label-${test.id}`;
              return (
                <ListItem
                  key={test.id}
                  role={undefined}
                  dense
                  button
                  onClick={handleToggle(test.id)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge='start'
                      checked={checked.indexOf(test.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${test.name}`} secondary={`${test.externalId}`}/>
                </ListItem>
              );
            })}
          </List>
        </DrawerCardContent>
        <DrawerCardActions>
          <FormButton primary type='submit' onClick={handleSubmit}>
            Save
          </FormButton>
          <FormButton onClick={handleCancel}>Cancel</FormButton>
        </DrawerCardActions>
      </DrawerCard>
    );
  };
  return (
    <div>
      <RightDrawer
        title='Add Tests to Panel'
        handleClose={handleCancel}
        open={openDrawer}
      >
        {form()}
      </RightDrawer>
    </div>
  );
};
export default Edit;
