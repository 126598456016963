import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';

const medium = css`
  padding: 8px 18px;
`;

const small = css`
  border: 1px solid rgba(152, 152, 152, 0.45);
  padding: 5px 12px;
  font-size:10px;
  font-size:1rem;
  font-weight: 400;
`;

const primary = css`
  background: #2a2277;
  color: #ffffff;
  &:hover {
    background: #ef599d;
  }
`;

const secondary = css`
  background: #ef599d;
  color: #fff;
  &:hover {
    background: #2a2277;
  }
`;

const masterColor = css`
  ${(props) =>
    props.masterColor?.background
      ? `background: ${props.masterColor.background};`
      : ''}
  ${(props) =>
    props.masterColor?.color ? `color: ${props.masterColor.color};` : ''}
  ${(props) =>
    props.masterColor?.border ? `border: ${props.masterColor.border};` : ''}

  ${(props) =>
    props.masterColor?.hover
      ? `
      &:hover {
        background: ${props.masterColor?.hover};
      }
    `
      : ''}
`;

const variantToStyleMap = { primary, secondary };
const sizeToStyleMap = { small, medium };

const getButtonSize = (size = 'medium') => {
  return sizeToStyleMap[size];
};

const getButtonVariant = (variant = 'primary') => {
  if (!variant) {
    return '';
  }

  return variantToStyleMap[variant];
};

const PrimaryButton = styled(Button)`
  && {
    border-radius: 40px;
    font-size: 12px;
    font-size: 1.2rem;
    font-weight: 500;
    margin-left:5px;

    ${(props) => getButtonSize(props.size)}
    ${(props) => getButtonVariant(props.color)};
    ${(props) => (props.masterColor ? masterColor : '')}
  }
`;

export { PrimaryButton };
