import BaseSvc from './base-service';
import configs from '@/config';
import { jsPDF as JSPDF } from 'jspdf';
import 'jspdf-barcode';
import { batch } from 'react-redux';

const QRCode = require('qrcode')

class KitService {
  constructor() {
    this.authConfig = {};
    if (!KitService.instance) {
      this.authConfig = {};
      KitService.instance = this;
    }
    return KitService.instance;
  }

  async search(filter) {
    console.log('#### KitService#search');
    let response = await BaseSvc.secureGet('/kit', filter);
    return response;
  }

  async save(data) {
    console.log('#### KitService#save', data);
    let response = await BaseSvc.securePost('/kit', data);
    return response.data;
  }

  async getKit(id) {
    console.log('#### KitService#getKit', id);
    let response = await BaseSvc.secureGet('/kit/' + id);
    return response;
  }

  async findKit(barcode) {
    let response = await BaseSvc.secureGet('/kit/barcode/' + barcode);
    console.log({ response });
    return response;
  }

  async createKits(data) {
    let response = await BaseSvc.securePost('/kit/batch', data);
    console.log({ response });
    return response;
  }

  async printLables(kits) {
    const doc = new JSPDF({ unit: 'in', format: [1, 2], orientation: 'l' });
    if (kits && kits.length > 0) {
      let counter = 0;
      let batchUpdate = [];
      kits.forEach(async (kit) => {
          let qr = await QRCode.toDataURL(`${configs.domains.PATIENT}/test/new?regcode=${kit.kitNumber}`)
          doc.barcode(kit.kitNumber, {
            fontSize: 30,
            textColor: '#000000',
            x: 0.23,
            y: 0.55,
          });
          doc.setFont('Arial', 'normal', 'bold');
          doc.setFontSize(16);
          doc.text(kit.kitNumber, 1, 0.75, {
            align: 'center',
            baseline: 'middle',
            fontWeight: 'bold'
          });
          doc.addPage([1, 1], 'l');
          doc.addImage(qr, 'png', 0, 0, 1, 1);
          counter++;
          if (counter < kits.length) {
            doc.addPage([1, 2], 'l');
          }
          batchUpdate.push(BaseSvc.securePost("/kit/" + kit.id + "/print"));
      })
      await Promise.all(batchUpdate);
      doc.autoPrint();
      doc.output('dataurlnewwindow');
      // doc.save('kit-labels.pdf'); //if to save labels instead of print.
    }
  }
}

const instance = new KitService();
Object.freeze(instance);
export default instance;
