/** PACKAGE IMPORTS */
import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { DataTableStyles, EmptyData, LoadingData } from '@/Theme';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';

const List = ({
  listData,
  loading,
  count,
  size,
  setSize,
  page,
  setPage,
  setSort,
  setDirection,
}) => {
  const navigate = useNavigate();
  const handleRowClick = (kit) => {
    let link = `/kit/${kit.id}`;
    navigate(link);
  };
  const getPrintedTitle = () => {
    return <span title="When the pdf is launched, the system considers it printed."> Printed </span>
  }

  const getUsedTitle = () => {
    return <span title="This changes automatically from No to Yes when a consumer registers a test with this code."> Used </span>
  }
  const columns = useMemo(
    () => [
      {
        name: 'Registration Code',
        sort: 'kitNumber',
        selector: row => row.kitNumber,
        sortable: true,

      },
      {
        name: 'Test',
        sort: 'labKit.name',
        sortable: true,
        grow: 2,
        cell: (row) => {
          return row.labKit && row.labKit.name;
        },
      },
      {
        name: getUsedTitle(),
        sort: 'used',
        sortable: true,
        cell: (row) => {
          return row.used ? "Yes" : "No";
        },
      },
      {
        name: getPrintedTitle(),
        sort: 'printed',
        sortable: true,
        cell: (row) => {
          return row.printed ? "Yes" : "No";
        },
      },
      {
        name: 'Printed User',
        sort: 'printed',
        sortable: true,
        cell: (row) => {
          return row.printed ? "Yes" : "No";
        },
      },

      {
        name: 'Last Printed',
        sort: 'lastPrint',
        sortable: true,
        selector: row => row.lastPrint,
        cell: (row) => {
          return row.printed ? `${DateTime.fromISO(row.lastPrint).toLocaleString(
            DateTime.DATETIME_SHORT
          )}` : "N/a";
        },
      },
      {
        name: 'Created On',
        sort: 'created',
        sortable: true,
        selector: row => row.created,
        cell: (row) => {
          return `${DateTime.fromISO(row.created).toLocaleString(
            DateTime.DATETIME_SHORT
          )}`;
        },
      }
    ],
    []
  );
  const handlePageChange = (p) => {
    console.log('### handlePageChange', page, '--', p);
    setPage(p);
  };
  const handlePerRowsChange = (perPage, p) => {
    console.log('### handleRowsChange', perPage, p);
    setSize(perPage);
    setPage(p);
  };
  const handleSort = (column, direction) => {
    console.log('### handleSort', column, direction);
    setSort(column.sort);
    setDirection(direction);
  };
  return (
    <>
      {loading && listData.length === 0 && <LoadingData />}
      {listData.length > 0 && <DataTable
        className='pagination-custom'
        columns={columns}
        data={listData}
        noHeader
        onRowClicked={handleRowClick}
        highlightOnHover
        pointerOnHover
        customStyles={DataTableStyles}
        paginationDefaultPage={page}
        persistTableHead
        paginationPerPage={size}
        pagination
        paginationServer
        paginationTotalRows={count}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        defaultSortField='name'
        defaultSortAsc={false}
        onSort={handleSort}
        sortServer
      />}
      {!loading && !listData.length && <EmptyData entity='kits' />}
    </>
  );
};
export default List;
