import BaseSvc from './base-service';

class CustomerService {

	constructor() {
		this.authConfig = {};
		if (!CustomerService.instance) {
			this.authConfig = {};
			CustomerService.instance = this;
		}
		return CustomerService.instance;
	}

	async search(filter){
		console.log("#### CustomerService#search")
		let response = await BaseSvc.secureGet("/customer", filter);
		return response;
	}

	async save(data){
		console.log("#### CustomerService#save", data)
		let response = await BaseSvc.securePut("/customer", data)
		return response.data;
	}

	async getCustomer(id){
		console.log("#### CustomerService#getCustomer", id)
		let response = await BaseSvc.secureGet("/customer/"+id);
		console.log("#### CustomerService#getCustomer", response, response.data);
		return response;
	}
}

const instance = new CustomerService();
Object.freeze(instance);
export default instance;
